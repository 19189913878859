import React from "react";
import "./Error.css";

export default function ServerError() {
  return (
    <div data-testid="server-error" id="server-error">
      <div className="text-center">
        <div className="triangle">
          <img width="70px" src={process.env.PUBLIC_URL + "favicon.ico"} />
        </div>

        <div className="error-found">
          <div className="notfound-404" style={{ backgroundImage: ` url("/favicon.ico")` }}></div>
          <h1>500 Internal Server Error</h1>
          <h4>Oopps. There was an error, please try again later</h4>
          <p>
            Try refreshing or navigating to <br></br> another page.
          </p>
        </div>
      </div>
    </div>
  );
}
