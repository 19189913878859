import React from "react";
import { Placeholder } from "react-bootstrap";

function Skeleton({ i }) {
  return (
    <Placeholder animation="wave" key={i} className="d-flex gap-3 pe-2">
      <Placeholder xs={10} bg="secondary" className="my-1" style={{ borderRadius: "2px" }} />
      <Placeholder
        xs={1}
        bg="secondary"
        className="my-1"
        style={{
          borderRadius: "2px",
          width: "16px",
          height: "16px",
          marignRight: "2px",
        }}
      />
    </Placeholder>
  );
}

export default Skeleton;
