import SensorList from "./SensorList";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SensorDetail from "./SensorDetail";
import SensorForm from "./Form/SensorForm";
import NoSelected from "@components/NoSelected";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import AddButton from "@components/Button/AddButton";
import { Container, Row, Col } from "react-bootstrap";
import { getAllSensors } from "../services/sensorCrud";
import ServerError from "@components/Error/ServerError";
import SensorMobileListView from "./SensorMobileListView";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import MobileBreadcrumb from "@components/Breadcrumb/MobileBreadcrumb";
import EmptyTree from "@modules/Trees/components/EmptyTree";

export default function SensorPage() {
  const { mobile } = useSelector((state) => state.mobile);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [detail, setDetail] = useState();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("selectedSensor");

  //retriving all sensorr list
  const {
    data: sensors,
    isError,
    isLoading,
  } = useQuery("sensors", getAllSensors, {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60 * 60,
  });

  //delete sensor function
  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError) return <ServerError />;

  return (
    <>
      {/* mobile */}

      {mobile ? (
        <>
          <MobileBreadcrumb
            title={`${t("sensors")}`}
            addButton={<AddButton label={"button.add_sensor"} action={() => setOpenForm(true)} />}
          />
          <Container>
            {sensors.length === 0 ? (
              <EmptyTree width={100} title={"sensor_details.empty_sensors"} />
            ) : (
              <SensorMobileListView
                sensors={sensors}
                sensorId={id}
                setIsUpdate={setIsUpdate}
                setDetail={setDetail}
                setOpenForm={setOpenForm}
              />
            )}
          </Container>
        </>
      ) : (
        <>
          <Breadcrumb
            title={`${t("sensors")}`}
            addButton={<AddButton label={"button.add_sensor"} action={() => setOpenForm(true)} />}
          />

          <Container>
            {sensors.length === 0 ? (
              <>{<EmptyTree width={100} title={"project_details.empty_sensors"} />}</>
            ) : (
              <Row>
                <Col lg="7">
                  <SensorList
                    sensors={sensors}
                    setDetail={setDetail}
                    setIsUpdate={setIsUpdate}
                    setOpenForm={setOpenForm}
                  />
                </Col>
                <Col lg="5">
                  {!id ? (
                    <NoSelected text="sensor_details.no_sensor_selected" />
                  ) : (
                    <SensorDetail setIsUpdate={setIsUpdate} setOpenForm={setOpenForm} />
                  )}
                </Col>
              </Row>
            )}
          </Container>
        </>
      )}
      <SensorForm
        isUpdate={isUpdate}
        detail={detail}
        setIsUpdate={setIsUpdate}
        show={openForm}
        setOpenForm={setOpenForm}
      />
    </>
  );
}
