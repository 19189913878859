import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import PageNotFoundInner from "@components/Error/PageNotFoundInner";
import ServerError from "@components/Error/ServerError";
import NoSelected from "@components/NoSelected";
import Spinner from "@components/Spinner/Spinner";
import { getUser } from "../services/userCrud";

export default function UserDetail() {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useQuery(["user", id], () => getUser(id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
  });

  if (user) {
    var { email, first_name, last_name, profile } = user;
  }

  if (!id)
    return (
      <Card role="no-selected-card">
        <Card.Body>
          <NoSelected text={"user_details.no_user_selected"} />
        </Card.Body>
      </Card>
    );
  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError && error.response?.status === 404) return <PageNotFoundInner />;
  if (isError) return <ServerError />;
  return (
    <Card style={{ border: "1px dashed #DDD" }} className="mt-5">
      <Card.Body>
        <div className="notes mb-4">
          <ul className="p-0 list-unstyled">
            <li className="pb-2">
              {t("first_name")}: <i>{first_name}</i>
            </li>
            <li className="pb-2">
              {t("last_name")}: <i>{last_name}</i>
            </li>
            <li className="pb-2">
              {t("email")}: <i>{email}</i>
            </li>
          </ul>
          <ul className="p-0 list-unstyled">
            <li className="pb-2">
              <b>{t("profile")}</b> <i></i>
            </li>
            <li className="pb-2">
              {t("phone_number")}: <i>{profile?.phoneNumber}</i>
            </li>
            <li className="pb-2">
              {t("account_type")}:{" "}
              <i>
                {profile?.accountType &&
                  {
                    0: `${t("superuser")}`,
                    1: `${t("admin")}`,
                    2: `${t("user")}`,
                  }[profile.accountType]}
              </i>
            </li>
          </ul>
          <ul className="p-0 list-unstyled">
            <li className="pb-2">
              <b>{t("address")}</b> <i></i>
            </li>
            <li className="pb-2">
              {t("city")}: {profile?.address?.city}
            </li>
            <li className="pb-2">
              {t("country")}: <i>{profile?.address?.country}</i>
            </li>
            <li className="pb-2">
              {t("street_name")}: {profile?.address?.streetName}
            </li>

            <li className="pb-2">
              {t("house_number")}: {profile?.address?.houseNumber}
            </li>
            <li className="pb-2">
              {t("zip_code")}:{profile?.address?.zipCode}
            </li>
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
}
