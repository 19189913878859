import React from "react";
import ReactEcharts from "echarts-for-react";

const powerFactor = 8;
export const logTransform = (value: number, min: number, max: number) => {
  // Adjust these parameters to fit the transformation and make sure there are no division by zero or log(0)
  // const adjustedValue = value - min + 1;
  // const adjustedMax = max - min + 1;
  // return Math.log(adjustedValue)/ Math.log(adjustedMax);
  // First, translate values to a positive range with +1 to avoid log(0)
  const offset = 1 - min; // Adjusting min to be 1
  const adjustedValue = value + offset;
  const adjustedMax = max + offset;

  // Apply a power transformation before logarithm to exaggerate differences
  const poweredValue = Math.pow(adjustedValue, powerFactor);
  const poweredMax = Math.pow(adjustedMax, powerFactor);

  // Return normalized logarithmic value
  // const transformedValue = Math.log(poweredValue) / Math.log(poweredMax);
  const transformedValue = (poweredValue - 1) / (poweredMax - 1);
  return transformedValue;
};

export const pFToKPa = (pF) => {
  return -(10 ** pF) / 10;
};

export const kPaToPF = (kpa) => {
  return Math.log10(-kpa * 10);
  // return kpa;
};

export const inverseTransform = (transformedValue: number, min: number, max: number) => {
  const adjustedMin = 1;
  const adjustedMax = max - min + adjustedMin;

  // Reverse the normalization
  const denormalizedValue = transformedValue * (Math.pow(adjustedMax, powerFactor) - 1) + 1;

  // Apply the inverse of the original transformation
  const originalValue = Math.pow(denormalizedValue, 1 / powerFactor) + min - adjustedMin;

  return originalValue;
};

const GaugeChart: React.FC = () => {
  const min = -2000;
  // const min = -199;
  const max = 0;
  const value = -100; // Example value

  const option = {
    series: [
      {
        type: "gauge",
        min: 0, // Since we're transforming values, the gauge works in a [0,1] range
        max: 1,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 30,
            color: [
              [logTransform(-1585, min, max), "#808080"], // grey
              [logTransform(-199, min, max), "#FF0000"], // red
              [logTransform(-32, min, max), "#FFFF00"], // yellow
              [logTransform(-6, min, max), "#008000"], // green
              [logTransform(0, min, max), "#0000FF"], // blue
            ],
          },
        },
        axisLabel: {
          // Customize axis labels to reflect the original scale
          formatter: function (value: number) {
            const realValue = inverseTransform(value, min, max);
            return realValue.toFixed(0);
          },
        },
        detail: {
          formatter: function (value: number) {
            // Convert back to the original value for display
            const realValue = inverseTransform(value, min, max);
            return `${realValue.toFixed(0)} kPa`;
          },
        },
        data: [{ value: logTransform(value, min, max), name: "Pressure" }],
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default GaugeChart;
