import { lazy, Suspense, useEffect, useState } from "react";
// import lazy from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Navigate, Route, Routes } from "react-router-dom";
// import Spinner from "@components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useMutation } from "react-query";
//protcted and unprotected routes
/*
import ProtectedRoute from "@routes/ProtectedRoute";
*/
// import RouteUnauthenticated from "@routes/UnauthenticatedRoute";
import { ReactQueryDevtools } from "react-query/devtools";
import CookieConsent from "react-cookie-consent";
import jwt_decode from "jwt-decode";
import Spinner from "@components/Spinner/Spinner";
import useWindowDimensions from "@hooks/useWindowDimension";
import { mobileView, desktopView } from "./redux/viewSlice";
// import Dashboard from "@modules/Dashboard";
import Header from "@components/Header/Header";
import PublicHeader from "@components/Header/PublicHeader";
import {
  PublicProjectDetailMapView,
  ProjectDetailMapView,
  ProjectDetailListView,
} from "@modules/Projects/pages/ProjectDetail";
import { ProjectGridView, ProjectMapView } from "@modules/Projects/components";
import IrrigationAreaPage from "@modules/IrrigationArea/components";
import ProfilePage from "@modules/Profile/component/ProfilePage";
import SensorPage from "@modules/Sensors/components";
import UserPage from "@modules/Users/components";
import TreePage, { TreeProfileDetail } from "@modules/Trees/components";
import DataScience from "@modules/DataScience";
import Billing from "@modules/Payment/components/Billing";
import PaymentSuccess from "@modules/Payment/components/PaymentSuccess";
import { useLocation } from "react-router-dom";

import { actions } from "@modules/Auth/_redux/authRedux";
import { refresh } from "@modules/Auth/_redux/authCrud";
import { logPageVisit } from "./modules/Users/services/userCrud";
// import { TourProvider } from "@reactour/tour";

const Login = lazy(() => import("@modules/Auth/components/Login"));
// import Login from "@modules/Auth/components/Login";
// import SettingPage from "@modules/Settings/components";
// import CustomerPage from "@modules/Customer/components";
// import PageNotFound from "@components/Error/PageNotFound";
// import jwt_decode from "jwt-decode";

const Signup = lazy(() => import("@modules/Auth/components/Signup"));
const CreateCustomer = lazy(() => import("@modules/Auth/components/CreateCustomer"));
const SignupSuccess = lazy(() => import("@modules/Auth/components/SignupSuccess"));
const ForgotPassword = lazy(() => import("@modules/Auth/components/ForgotPassword"));
const ResetPassword = lazy(() => import("@modules/Auth/components/ResetPassword"));
const SettingPage = lazy(() => import("@modules/Settings/components"));
const CustomerPage = lazy(() => import("@modules/Customer/components"));
const AdminCustomerPage = lazy(() => import("@modules/Admin/components"));
const TreeDetail = lazy(() => import("@modules/Trees/components/TreeDetail"));
const SensorDetail = lazy(() => import("@modules/Sensors/components/SensorDetail"));
const PageNotFound = lazy(() => import("@components/Error/PageNotFound"));

const queryClient = new QueryClient();

// const expiration = new Date(payload.exp);
// const now = new Date();
// const fiveMinutes = 1000 * 60 * 5;
//
// if (expiration.getTime() - now.getTime() < fiveMinutes) {
//   console.log("JWT has expired or will expire soon");
// } else {
//   console.log("JWT is valid for more than 5 minutes", payload);
// }

const PublicRoute = ({ children }) => {
  return (
    <div id="ts_wrapper">
      <PublicHeader />
      <main className="page-container">{children}</main>
    </div>
  );
};

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  // const [error, setError] = useState(false);
  console.log("LOADINGPAGE");
  const { isLoading: loadingRefresh, mutateAsync: refreshMutate } = useMutation(refresh, {
    onSuccess: async (data) => {
      const accessToken = data.access;
      const refreshExp = data.refreshExp;
      // const decoded = jwt_decode(accessToken);
      // await dispatch(actions.login(accessToken));
      const decoded = jwt_decode(accessToken);
      await dispatch(actions.login(accessToken));
      // await dispatch(actions.refreshToken(auth.refreshToken));
      await dispatch(actions.refreshExp(refreshExp));
      await dispatch(actions.userLoaded(decoded));
      //   "persist:root",
      //   JSON.stringify({
      //     auth: { authToken: accessToken, refreshToken: auth.refreshToken, user: auth.user },
      //   })
      // );
      // await dispatch(actions.userLoaded(decode));
    },
  });
  // useEffect(() => {
  //   setTimeout(() => {
  //     setError(false);
  //   }, 1000);
  // }, [error]);

  const { isAuthorized, isExpiredAccess, isExpiredRefresh, refreshExp } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken != null,
      isExpiredAccess: auth.authToken
        ? new Date(jwt_decode(auth.authToken).exp * 1000).getTime() < new Date().getTime()
        : false,
      isExpiredRefresh: auth.refreshExp
        ? new Date(auth.refreshExp * 1000).getTime() < new Date().getTime()
        : false,
      refreshExp: auth.refreshExp ? auth.refreshExp : false,
    }),
    shallowEqual
  );
  localStorage.setItem("refreshExp", refreshExp);
  // var isExpired = true;
  // if (isAuthorized) {
  //   isExpired = useSelector(
  //     ({ auth }) => ({
  //       isExpired: new Date(jwt_decode(auth.authToken).exp).getTime() < new Date().getTime(),
  //     }),
  //     shallowEqual
  //   ).isExpired;

  // var isExpired = true;
  // if (isAuthorized) {
  //   const { auth } = useSelector((state) => state);
  //   const token = auth.authToken;
  //   const payload = jwt_decode(token);
  //   const expDate = new Date(payload.exp);
  //   const now = new Date();
  //   isExpired = expDate.getTime() - now.getTime() < 0;
  //   localStorage.clear();
  // }
  // if (isExpiredRefresh && auth.refreshToken != null) {
  //   localStorage.clear();
  // }

  if (!isAuthorized || isExpiredRefresh) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isAuthorized && isExpiredAccess && !isExpiredRefresh) {
    // && auth.refreshToken != null) {
    // TODO: get new accessToken and set accessToken globally by using actions from authReducer
    if (loadingRefresh) {
      localStorage.setItem("loadingRefresh", "loading");
    } else {
      localStorage.setItem("loadingRefresh", "done");
    }
    {
      loadingRefresh ? (
        <Spinner animation="border" size="sm" />
      ) : (
        (async () =>
          await refreshMutate({
            refresh: auth.refreshToken,
          }))()
      );
    }
  }
  let pageNameList = location.pathname.split("/");
  pageNameList = pageNameList.slice(1);
  const pageName = pageNameList.join();
  useQuery(["page_visited", pageName], () => logPageVisit(pageName), {
    refetchOnWindowFocus: false,
    enabled: !!pageName,
  });
  // localStorage.setItem(

  return (
    <div id="ts_wrapper">
      <Header />
      <main className="page-container">{children}</main>
    </div>
  );
};

const UnprotectedRoute = ({ children }) => {
  // TODO: check if expired accessToken
  // if accessToken is expired, check whether rereshToken is expired as well
  // if refreshToken is not expired: get new accessToken
  // else: new Login
  const { isAuthorized, isExpiredAccess, isExpiredRefresh, refreshExp } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken != null,
      isExpiredAccess: auth.authToken
        ? new Date(jwt_decode(auth.authToken).exp * 1000).getTime() < new Date().getTime()
        : false,
      // isExpiredRefresh: auth.refreshToken
      //   ? new Date(jwt_decode(auth.refreshToken).exp * 1000).getTime() < new Date().getTime()
      //   : false,
      refreshExp: auth.refreshExp ? auth.refreshExp : false,
    }),
    shallowEqual
  );
  // var isExpired = true;
  // if (isAuthorized) {
  //   isExpired = useSelector(
  //     ({ auth }) => ({
  //       isExpired: new Date(jwt_decode(auth.authToken).exp).getTime() < new Date().getTime(),
  //     }),
  //     shallowEqual
  //   ).isExpired;
  //   // const token = auth.authToken;
  //   // const payload = jwt_decode(token);
  //   // const expDate = new Date(payload.exp);
  //   // const now = new Date();
  //   // isExpired = expDate.getTime() - now.getTime() < 0;
  //   // localStorage.clear();
  // }
  // if (isExpiredAccess) {
  //auth:"{"authToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg1ODA1ODQ2LCJpYXQiOjE2ODU4MDU4MzYsImp0aSI6IjU5ZDdmYzI2OTZlYjQ0YWFhMDUzYzMwMzMxNDU3N2I2IiwidXNlcl9pZCI6MSwiZW1haWwiOiJtLnNwaWVsdm9nZWxAdHJlZXNlbnNlLm5ldCIsInVzZXJuYW1lIjoibS5zcGllbHZvZ2VsQHRyZWVzZW5zZS5uZXQiLCJhY2NvdW50X3R5cGUiOjAsImlzX3N1cGVydXNlciI6dHJ1ZSwibGFuZ3VhZ2UiOjB9.fauMX9VLi0BBJqMWV2KDAK27o8KLwa7RZ3-uRrcEf7U","user":{"user_id":1,"username":"m.spielvogel@treesense.net","account_type":0,"email":"m.spielvogel@treesense.net","language":0,"is_superuser":true}}"
  //   localStorage.clear();
  // }

  const { state } = useLocation();
  localStorage.setItem("refreshExp", refreshExp);

  if (isAuthorized && !isExpiredAccess) {
    const previousPath = localStorage.getItem("previousPath");
    const previousSearch = localStorage.getItem("previousSearch");
    let { from } = state || { from: { pathname: "/projects", search: "" } };
    if (!state && previousPath) {
      from = { pathname: previousPath, search: previousSearch };
    }
    return <Navigate to={from.pathname + from.search} replace />;
  }

  return children;
};

const App = () => {
  const { i18n } = useTranslation();
  // const { isAuthorized } = useSelector(
  // 	({ auth }) => ({
  // 		isAuthorized: auth.authToken != null,
  // 	}),
  // 	shallowEqual
  // );
  // const steps = [
  // 	{
  // 		selector: "[data-tour='step-1']",
  // 		content: (
  // 			<p>
  // 				Hier siehst du dein <code>erstes Projekt</code>.
  // 			</p>
  // 		),
  // 	},
  // 	{
  // 		selector: "[data-tour='step-2']",
  // 		content: (
  // 			<p>
  // 				Hier siehst du die Bäume des Projekts als <code> Karte </code>.
  // 			</p>
  // 		),
  // 	},
  // 	{
  // 		selector: "[data-tour='step-3']",
  // 		content: (
  // 			<p>
  // 				Hier kannst du eine Zusammenfassung <code> verschiedener Statistiken </code>
  // 				sehen.
  // 			</p>
  // 		),
  // 	},
  // ];
  // let navigate = useNavigate();
  // const [step, setStep] = useState(0);
  //
  // const setCurrentStep = (step) => {
  // 	switch (step) {
  // 		case 0:
  // 			navigate("/projects", true);
  // 			break;
  // 		case 1:
  // 			navigate("/projects/mapview", true);
  // 			break;
  // 		case 2:
  // 			navigate("/dashboard", true);
  // 			break;
  // 		default:
  // 			break;
  // 	}
  // 	setStep(step);
  // };

  // const fallbackUri = `${isAuthorized ? "/projects" : "/login"}`;
  // const disableBody = () => {
  //   console.log("OPENED");
  // };
  // const enableBody = () => {
  //   console.log("CLOSED");
  // };

  const { auth } = useSelector((state) => state);
  // console.log("AUTH", auth);
  // console.log(mobile);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  // console.log(mobile);
  //language setting after login
  useEffect(() => {
    if (Object.keys(auth).length != 0 && auth.authToken != null && auth.user != null) {
      const { language } = auth.user;
      let lang = "";
      if (language === 0) lang = "de";
      if (language === 1) lang = "en";
      if (language === 2) lang = "it";
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage("de");
    }
  }, []);
  //check the width of device to display mobile or desktop view
  useEffect(() => {
    if (width < 768) {
      dispatch(mobileView());
    } else {
      dispatch(desktopView());
    }
  }, [width]);

  return (
    <QueryClientProvider client={queryClient}>
      {/* <TourProvider */}
      {/* 	steps={steps} */}
      {/* 	currentStep={step} */}
      {/* 	setCurrentStep={setCurrentStep} */}
      {/* 	afterOpen={disableBody} */}
      {/* 	beforeClose={enableBody} */}
      {/* > */}
      <Suspense fallback={<Spinner height={"80vh"} />}>
        <Routes>
          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <Login />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <UnprotectedRoute>
                <Signup />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/signup-successful"
            element={
              <UnprotectedRoute>
                <SignupSuccess />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <UnprotectedRoute>
                <ForgotPassword />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/password-reset/:type/:id"
            element={
              <UnprotectedRoute>
                <ResetPassword />
              </UnprotectedRoute>
            }
          />
          {/* <Route path="/authentication-mail-send" element = {<AuthenticationMailSend />}/> */}
          <Route path="/payment-successfull" element={<PaymentSuccess />} />
          <Route path="/create-customer" element={<CreateCustomer />} />
          <Route path="/choose-package" element={<Billing />} />
          {/* <Route */}
          {/* 	path="/dashboard" */}
          {/* 	element={ */}
          {/* 		<ProtectedRoute> */}
          {/* 			<Dashboard /> */}
          {/* 		</ProtectedRoute> */}
          {/* 	} */}
          {/* ></Route> */}
          <Route
            path="/tree-profile/:id"
            element={
              <PublicRoute>
                <TreeProfileDetail />
              </PublicRoute>
            }
          />
          <Route
            path="/trees"
            element={
              <ProtectedRoute>
                <TreePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/customers"
            element={
              <ProtectedRoute>
                <AdminCustomerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute>
                <CustomerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sensors"
            element={
              <ProtectedRoute>
                <SensorPage />
              </ProtectedRoute>
            }
          />
          {auth?.user?.account_type !== 2 && (
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <UserPage />
                </ProtectedRoute>
              }
            />
          )}
          {/* <Route */}
          {/*   path="/projects_public" */}
          {/*   element={ */}
          {/*       <ProjectMapView /> */}
          {/*   } */}
          {/* /> */}
          {/* <Route
            path="/projects/mapview"
            element={
              <ProtectedRoute>
                <ProjectMapView />
              </ProtectedRoute>
            }
          /> */}
          {/* <Route */}
          {/* 	end */}
          {/* 	path="/dashboard" */}
          {/* 	element={ */}
          {/* 		<ProtectedRoute> */}
          {/* 			<Dashboard /> */}
          {/* 			{/* <Dashboard setCurrentStep={setCurrentStep} /> */}
          {/* 		</ProtectedRoute> */}
          {/* 	} */}
          {/* /> */}
          {/*<Route path="/" redirectTo="/login" />*/}
          {auth?.user?.account_type !== 2 && (
            <Route
              path="/user/:id"
              element={
                <ProtectedRoute>
                  <UserPage />
                </ProtectedRoute>
              }
            />
          )}
          <Route
            path="/tree/:tid"
            element={
              <ProtectedRoute>
                <TreeDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sensor/:id"
            element={
              <ProtectedRoute>
                <SensorDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer/:id"
            element={
              <ProtectedRoute>
                <CustomerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/areas"
            element={
              <ProtectedRoute>
                <IrrigationAreaPage />
              </ProtectedRoute>
            }
          />
          <Route
            end
            path="/projects"
            element={
              <ProtectedRoute>
                <ProjectGridView />
                {/* <ProjectGridView setCurrentStep={setCurrentStep} /> */}
              </ProtectedRoute>
            }
          />
          <Route
            end
            path="/public/project/:pid/map"
            element={
              <PublicRoute>
                <PublicProjectDetailMapView />
              </PublicRoute>
            }
          />
          <Route
            end
            path="/project/:pid/list"
            element={
              <ProtectedRoute>
                <ProjectDetailListView />
              </ProtectedRoute>
            }
          />
          <Route // mobile view
            path="/project/:pid/list/tree/:tid"
            element={
              <ProtectedRoute>
                <TreeDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/project/:pid/map"
            element={
              <ProtectedRoute>
                <ProjectDetailMapView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/project/:pid/datascience"
            element={
              <ProtectedRoute>
                <DataScience />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SettingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/projects" replace />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
      {/* </TourProvider> */}
      <ReactQueryDevtools />
      <CookieConsent>
        Diese Website nutzt Cookies, um die User Experience zu verbessern.
      </CookieConsent>
    </QueryClientProvider>
  );
};
export default App;
