import React from "react";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { addTreeImage } from "@modules/Trees/services/treeCrud";
import ImageDropzone from "@components/ImageDropzone";
import { ImageAddIcon } from "@components/icons";

function TreeImage({ selectedTree, setOpenForm, setActiveStep }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation("addTreeeImage", addTreeImage, {
    onSuccess: () => {
      queryClient.invalidateQueries("");
      setOpenForm(false);
    },
  });

  const saveImage = async (values) => {
    const treeId = selectedTree.id;
    // eslint-disable-next-line no-undef
    const form = new FormData();
    form.append("image", values.image);
    form.append("timestamp", values.timestamp);
    await mutateAsync({ form, treeId });
  };
  return (
    <div>
      <Formik
        initialValues={{
          image: "",
          timestamp: "",
        }}
        onSubmit={async (values) => saveImage(values)}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <label className="mb-2" htmlFor="">
              {t("Change") + " " + t("image")}
            </label>

            <ImageDropzone img={<ImageAddIcon />} setFieldValue={setFieldValue} />
            {/* <div className="d-flex justify-content-between"> */}
            {/*   <Button type="submit" className="  mt-4" variant=" btn-outline-primary"> */}
            {/*     {!isLoading ? `${t("save")}` : "Saving..."} */}
            {/*   </Button> */}
            {/* </div> */}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default TreeImage;
