import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function NoSelected({ text }) {
  const { t } = useTranslation();
  return (
    <Card data-testid="no-selected-card" role="no-selected-card" style={{ height: "70vh" }}>
      <Card.Body>
        <div className="content">
          <div className=" bg-light text-center">
            <img name="empty_project" alt="" width="80" src="/empty_project.svg" />
            <h6 className="my-4">{t(`${text}`)}</h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default NoSelected;
