import React from "react";
import "./Spinner.css";

export default function Spinner({ height }) {
  return (
    <div data-testid="spinner-wrapper" className="spinner-wrapper" style={{ height: `${height}` }}>
      <img width="40px" src="/img/loader.gif" alt="" />
    </div>
  );
}
