const nameValidation = (value) => {
  let error;
  if (!value) {
    error = "validation.name_is_required";
  }
  return error;
};
const hardwareValidation = (value) => {
  let error;
  if (!value) {
    error = "validation.hardware_serial_is_required";
  }
  return error;
};

export const validation = {
  nameValidation,
  hardwareValidation,
};
