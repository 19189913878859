import { Formik, Field } from "formik";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Modal, Form, ModalBody, Button } from "react-bootstrap";
import { addTreeImage } from "../../../services/treeCrud";
import ImageDropzone from "@components/ImageDropzone";
import { ImageAddIcon } from "@components/icons";

export default function TreeImageForm({ show, setOpenForm }) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const tid = query.get("selectedTree");

  const { isLoading, mutateAsync } = useMutation("addTreeeImage", addTreeImage, {
    onSuccess: () => {
      queryClient.invalidateQueries("");
      setOpenForm(false);
    },
  });

  const saveImage = async (values) => {
    // eslint-isable-next-line no-undef
    const form = new FormData();
    form.append("tree_image", values.tree_image);
    form.append("leaf_image", values.leaf_image);
    form.append("timestamp", values.timestamp);
    await mutateAsync({ form, tid });
  };

  const handleClose = () => {
    setOpenForm(false);
  };
  const now = new Date();

  return (
    <>
      <Modal onHide={handleClose} show={show} centered>
        <ModalHeader closeButton>
          <h5 className="mb-0">{`${t("add_tree_img")}`}</h5>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              tree_image: "",
              leaf_image: "",
              timestamp: now.toISOString().slice(0, 16),
            }}
            onSubmit={async (values) => saveImage(values)}
          >
            {({ handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <label className="mb-2" htmlFor="">
                  {t(
                    "Um die Daten bestmöglich analysieren zu können, empfehlen wird den Upload von einem Bild des Baums und einem aussagekräftigen Blatt zum gleichen Zeitpunkt."
                  )}
                </label>
                <label className="mb-2" htmlFor="">
                  {t("datetime_photo")}
                </label>

                <Field className="form-control" type="datetime-local" name="timestamp" />
                <label className="mb-2" htmlFor="">
                  {t("upload_tree_img")}
                </label>
                <ImageDropzone
                  img={<ImageAddIcon />}
                  setFieldValue={setFieldValue}
                  name={"tree_image"}
                />
                <label className="mb-2" htmlFor="">
                  {t("upload_leaf_img")}
                </label>
                <ImageDropzone
                  img={<ImageAddIcon />}
                  setFieldValue={setFieldValue}
                  name={"leaf_image"}
                />
                <div className="d-flex justify-content-between">
                  <Button type="submit" className="  mt-4" variant=" btn-outline-primary">
                    {!isLoading ? `${t("save")}` : "Saving..."}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}
