import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import useChoices from "@hooks/useChoices";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "@components/icons";
import StatusButton from "./Button/StatusButton";

function filterTrees({ filterTerm, trees }) {
  if (!filterTerm) {
    return trees;
  }
  return trees.filter(
    (item) => item.name && item.name.toLowerCase()?.includes(filterTerm.toLowerCase())
  );
}

function TreeListMobileView({ trees }) {
  const { t } = useTranslation();
  const { pid } = useParams();
  const [filterTerm, setFilterTerm] = useState("");
  const [healthState] = useChoices("dry_state");

  const filteredTrees = filterTrees({ filterTerm, trees });

  function updateFilterHandler(event) {
    setFilterTerm(event.target.value);
  }

  return (
    <>
      <SearchForm>
        <input
          type="text"
          onChange={updateFilterHandler}
          className="form-control "
          placeholder={t("search_tree")}
          style={{ padding: "12px 1em 12px 2em" }}
        />
        <SearchIcon top={0} width="30px" />
      </SearchForm>
      <div role="mobileList">
        {filteredTrees
          ?.sort((a, b) => (a.id < b.id ? 1 : -1))
          ?.map((tree) => {
            const health_state = healthState?.filter((item) => item.key == tree.health_state);
            let treeHealth = health_state && health_state[0] && health_state[0].value;
            return (
              <Link
                to={
                  pid
                    ? `/project/${pid}/list/tree/${tree.id}/?selectedTree=${tree.id}`
                    : `/tree/${tree.id}?selectedTree=${tree.id}`
                }
                key={tree.id}
                className="list-unstyled py-2 d-flex justify-content-between"
              >
                <div className="w-50">
                  <h6 className="mb-1">
                    <b>{tree.name}</b>
                  </h6>
                  <i className="text-muted">{tree.species ? tree.species.name : ""}</i>
                </div>
                {/*
								<div>
									<span className="small text-muted">
										{tree?.next_irrigation}
										{tree?.next_irrigation_days &&
											tree?.next_irrigation_days + "days"}
									</span>
								</div>
									*/}
                <div>
                  <StatusButton status={tree?.health_state} padding=" 2px 5px">
                    {treeHealth ? `${t(treeHealth.replace(/\s/g, ""))}` : ""}
                  </StatusButton>
                </div>
              </Link>
            );
          })}
      </div>
    </>
  );
}

export default TreeListMobileView;

const SearchForm = styled.div`
  position: relative;
`;
