import axiosInstance from "../../../helper/interceptor";

export const getAllIrrigationAreas = async () => {
  let response = await axiosInstance.get("areas");
  return response.data;
};
export const getIrrigationArea = async (id) => {
  let response = await axiosInstance.get(`areas/${id}`);
  return response.data;
};
export const createIrrigationArea = async (formData) => {
  let response = await axiosInstance.post("areas/", formData);
  return response.data;
};

export const updateIrrigationArea = async (form) => {
  let data = form.formData;
  let response = await axiosInstance.patch(`areas/${form.id}/`, data);
  return response.data;
};
export const updateSingleIrrigationArea = async (form) => {
  let data = form.formData;
  let response = await axiosInstance.patch(`/areas-single-tree/${form.id}/`, data);
  return response.data;
};

export const deleteArea = async (id) => {
  const response = await axiosInstance.delete(`areas/${id}`);
  return response;
};
