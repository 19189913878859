export const constants = {
  SET_PROJECT_TREE_LIST: "SET_PROJECT_TREE_LIST",
  SET_HARDWARE_SERIAL: "SET_HARDWARE_SERIAL",
  SET_TREES_ID: "SET_TREES_ID",
  SET_CHECKED_TREES_ID: "SET_FILTERED_TREES_ID",
  SET_FILTERED_HARDWARE_SERIAL: "SET_FILTERED_HARDWARE_SERIAL",
  SET_SENSORS: "SET_SENSORS",
  SET_PROJECT_AREAS: "SET_PROJECT_AREAS",
  SET_CHECKED_AREAS_ID: "SET_CHECKED_AREAS_ID",
  SET_AREAS_ID: "SET_AREAS_ID",
};
