export const formInitialValues = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  phoneNumber: "",
  accountType: "1",
  streetName: "",
  houseNumber: "",
  zipCode: "",
  city: "",
  country: "",
  areas: [],
};

export function formUpdateValues(typeDetail) {
  return {
    email: typeDetail ? typeDetail?.email : "",
    password: "",
    first_name: typeDetail && typeDetail.first_name ? typeDetail.first_name : "",
    last_name: typeDetail && typeDetail.last_name ? typeDetail.last_name : "",
    phoneNumber:
      typeDetail && typeDetail.profile && typeDetail.profile.phoneNumber !== null
        ? typeDetail.profile.phoneNumber
        : "",
    accountType: typeDetail && (typeDetail.profile ? typeDetail?.profile?.accountType : ""),
    areas: typeDetail && (typeDetail.profile ? typeDetail?.profile?.areas : []),
    streetName:
      typeDetail && typeDetail.profile
        ? typeDetail.profile.address
          ? typeDetail.profile.address.streetName !== null
            ? typeDetail.profile.address.streetName
            : ""
          : ""
        : "",
    houseNumber:
      typeDetail && typeDetail.profile
        ? typeDetail.profile.address
          ? typeDetail.profile.address.houseNumber !== null
            ? typeDetail.profile.address.houseNumber
            : ""
          : ""
        : "",
    zipCode:
      typeDetail && typeDetail.profile
        ? typeDetail.profile.address
          ? typeDetail.profile.address.zipCode !== null
            ? typeDetail.profile.address.zipCode
            : ""
          : ""
        : "",
    city:
      typeDetail && typeDetail.profile
        ? typeDetail.profile.address
          ? typeDetail.profile.address.city !== null
            ? typeDetail.profile.address.city
            : ""
          : ""
        : "",
    country:
      typeDetail && typeDetail.profile
        ? typeDetail.profile.address
          ? typeDetail.profile.address.country !== null
            ? typeDetail.profile.address.country
            : ""
          : ""
        : "",
  };
}
