import Swal from "sweetalert2";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactTable from "@components/ReactTable";
import { deleteArea } from "../services/areaCrud";
import { EditIcon, TrashIcon } from "@components/icons";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

export default function IrrigationAreaList({ areas, setOpenForm, setIsUpdate, setDetail }) {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const id = query.get("selectedArea");
  const { t } = useTranslation();
  const { pid } = useParams();

  const queryClient = useQueryClient();

  const handleEdit = (areaDetail) => {
    setIsUpdate(true);
    setOpenForm(true);
    setDetail(areaDetail);
  };

  const { mutateAsync: areaDelete } = useMutation(deleteArea, {
    onSuccess: () => {
      queryClient.invalidateQueries("areas");
      queryClient.invalidateQueries(["areas", id]);
      queryClient.invalidateQueries(["project", pid]);
    },
  });

  const handleDelete = (id) => {
    Swal.fire({
      html: '<p className="text-center"> ' + "Are you sure you want to delete " + "?" + "</p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#388090",
      cancelButtonColor: "#EF3600",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        areaDelete(id);
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: `${t("area")}`,
        accessor: "name",
      },
      {
        Header: `${t("tree_count")}`,
        // accessor: "name",
        Cell: (props) => {
          return <>{props.row.original.tree_set.length}</>;
        },
      },
      {
        Header: `${t("actions")}`,
        accessor: "col4",
        style: { width: "80px", textAlign: "center" },
        disableSortBy: true,
        Cell: (props) => {
          return (
            <>
              <span title="Edit">
                <EditIcon
                  onClick={() => {
                    handleEdit(props.row.original);
                  }}
                />
              </span>
              <span title="Delete">
                <TrashIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    let id = props.row.original.id;
                    handleDelete(id);
                  }}
                />
              </span>
            </>
          );
        },
      },
    ],
    []
  );
  const onRowClicked = (row) => {
    const { id } = row.original;
    navigate(`/project/${pid}/list?selectedArea=${id}`);
  };

  return (
    <>
      <ReactTable
        columns={columns}
        data={areas}
        onRowClicked={onRowClicked}
        activeId={id}
        pagesize={10}
        size={[10, 20, 30, 40, 50]}
      />
    </>
  );
}
