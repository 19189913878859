import moment from "moment";
import Swal from "sweetalert2";
import Avatar from "react-avatar";
import { useMutation, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditIcon, TrashIcon } from "@components/icons";
import { deleteMaintenance } from "@modules/Trees/services/treeCrud";
import useChoices from "@hooks/useChoices";

export default function MaintenanceMessage({ tree, setIsUpdate, setOpenForm, setMaintenanceItem }) {
  return (
    <ul className="p-3">
      {tree?.maintenances
        ?.sort((a, b) => (a.date < b.date ? 1 : -1))
        ?.map((maintenance) => {
          return (
            <MessageItem
              maintenance={maintenance}
              setIsUpdate={setIsUpdate}
              setOpenForm={setOpenForm}
              setMainteanceItem={setMaintenanceItem}
              tid={tree.id}
              key={maintenance.id}
            />
          );
        })}
    </ul>
  );
}

const MessageItem = ({ maintenance, setIsUpdate, setOpenForm, setMainteanceItem, tid }) => {
  const { t } = useTranslation();
  const date = new Date(maintenance.date);
  const readableDate = moment(date).local().format("DD.MM.YYYY HH:mm");
  const queryClient = useQueryClient();

  const { mutateAsync: maintenanceDelete } = useMutation(deleteMaintenance, {
    onSuccess: () => {
      // queryClient.invalidateQueries(["trees", tid]);
      queryClient.invalidateQueries("tree", { tid: tid });
    },
  });
  const handleDelete = (id) => {
    Swal.fire({
      html:
        '<p className="text-center"> ' +
        "Are you sure you want to delete " +
        // ` <b>${project.name} </b>` +
        "?" +
        "</p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#388090",
      cancelButtonColor: "#EF3600",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        maintenanceDelete(id);
      }
    });
  };

  return (
    <li className="d-flex gap-2 py-2 mb-3">
      <div>
        <Avatar
          color={Avatar.getRandomColor("sitebase", ["red", "#8DC641"])}
          size="32"
          round="100px"
          name={
            maintenance.user && maintenance.user.first_name && maintenance.user.last_name
              ? maintenance.user.first_name + " " + maintenance.user.last_name
              : "Unknown"
          }
        />
      </div>
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between ">
          <h6 className="mb-0">
            {maintenance.user && maintenance.user.first_name && maintenance.user.last_name
              ? maintenance.user.first_name + " " + maintenance.user.last_name
              : "Unknown"}
          </h6>
          <p className="mb-0" style={{ fontSize: "12px" }}>
            {readableDate}
          </p>
          <span title="Edit">
            <EditIcon
              onClick={() => {
                setIsUpdate(true);
                setOpenForm(true);
                setMainteanceItem(maintenance);
              }}
            />
            <TrashIcon
              onClick={() => {
                handleDelete(maintenance.id);
              }}
            />
          </span>
        </div>
        <div className="pt-2">
          <div className="mb-1">
            {t("maintainance")}:{" "}
            {maintenance.tasks?.map((item, i) => (
              <MaintenanceList taskItem={item} key={i} maintenance={maintenance} />
            ))}
          </div>
          {maintenance.other && (
            <div className="mb-1">
              {t("other")}: {maintenance.other}
            </div>
          )}
          <div>
            <span>
              {t("time")}: <b> {maintenance.duration} hrs</b>
            </span>
          </div>
          <i>{maintenance.notes}</i>
        </div>
      </div>
    </li>
  );
};
const MaintenanceList = ({ taskItem, maintenance }) => {
  const [youngMaintenance, setYoungMaintenance] = useState();
  const [oldMaintenance, setOldMintenance] = useState();
  const [cmaintenance] = useChoices("maintenance");
  const [cyoungMaintenance] = useChoices("maintenance_young_tree");
  const [coldMaintenance] = useChoices("maintenance_old_tree");

  useEffect(() => {
    if (cmaintenance && cyoungMaintenance) {
      setYoungMaintenance([...cmaintenance, ...cyoungMaintenance]);
    }
    if (cmaintenance && coldMaintenance) {
      setOldMintenance([...cmaintenance, ...coldMaintenance]);
    }
  }, [cmaintenance, cyoungMaintenance]);

  let name;
  if (maintenance.tree_phase === null) {
    name = oldMaintenance?.filter((a) => a.key == taskItem);
  }
  if (maintenance.tree_phase === 1) {
    name = youngMaintenance?.filter((a) => a.key == taskItem);
  }
  if (maintenance.tree_phase === 2) {
    name = oldMaintenance?.filter((a) => a.key == taskItem);
  }

  return (
    <>
      <b className="text-primary " style={{ counterIncrement: 1 }}>
        <i>{name && name[0] ? name[0].value : ""}</i>
      </b>
    </>
  );
};
