import { useTranslation } from "react-i18next";

export default function EmptyTree({ width, title }) {
  const { t } = useTranslation();
  return (
    <div className="p-5 bg-light text-center">
      <img
        data-testid="empty-project-image"
        name="empty_project"
        alt=""
        width={width}
        src="/empty_project.svg"
      />
      <h5 className="my-4">{t(title)}</h5>
    </div>
  );
}
