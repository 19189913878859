import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactTable from "@components/ReactTable";
import { useNavigate, useParams } from "react-router-dom";
import { EditIcon, TrashIcon } from "@components/icons";
import { useMutation, useQueryClient } from "react-query";
import { deleteUser } from "@modules/Users/services/userCrud";

export default function UserList({ users, setIsUpdate, setTypeDetail, handleShow }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleEdit = (type) => {
    setTypeDetail(type);
    setIsUpdate(true);
    handleShow();
  };

  //query to delete user
  const { mutateAsync: userDelete } = useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries(["user", id]);
    },
  });

  const columns = useMemo(() => [
    {
      Header: `${t("username")}`,
      accessor: "username",
      Cell: (props) => {
        return (
          <>
            {props.row.original.first_name} {props.row.original.last_name}
          </>
        );
      },
    },
    {
      Header: `${t("email")}`,
      accessor: "email",
    },
    {
      Header: `${t("role")}`,
      accessor: "hardware_serial",
      Cell: (props) => {
        return (
          <span className="badge  bg-primary" style={{ padding: "6px 10px" }}>
            {
              {
                1: `${t("admin")}`,
                2: `${t("user")}`,
              }[props.row.original.profile?.accountType]
            }
          </span>
        );
      },
    },
    {
      Header: `${t("actions")}`,
      accessor: "col4",
      style: { width: "80px", textAlign: "center" },
      disableSortBy: true,
      Cell: (props) => {
        return (
          <>
            <span title="Edit">
              <EditIcon
                onClick={() => {
                  handleEdit(props.row.original);
                }}
              />
            </span>
            <span title="Delete">
              <TrashIcon
                onClick={(e) => {
                  e.isPropagationStopped();
                  let id = props.row.original.id;
                  userDelete(id);
                }}
              />
            </span>
          </>
        );
      },
    },
  ]);

  //table row click event to get row data
  const onRowClicked = (row) => {
    const { id } = row.original;
    navigate(`/user/${id}`);
  };

  return (
    <>
      <ReactTable
        columns={columns}
        data={users}
        onRowClicked={onRowClicked}
        pagesize={10}
        size={[10, 20, 30, 40, 50]}
      />
    </>
  );
}
