import React from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function AreaName({ validation }) {
  const { t } = useTranslation();
  const nameCharLimit = 100;

  return (
    <Form.Group>
      <Form.Label> {t("Area") + "  " + t("name")} *</Form.Label>
      <Field
        className="form-control"
        name="name"
        validate={validation.nameValidation}
        maxLength={nameCharLimit}
        placeholder={t("maximum_of") + ` ${nameCharLimit} ` + t("characters")}
      />
    </Form.Group>
  );
}

export default AreaName;
