import { useTranslation } from "react-i18next";
export default function getDate() {
  const { t, i18n } = useTranslation();
  const weekday = [
    `${t("day.sun")}`,
    `${t("day.mon")}`,
    `${t("day.tue")}`,
    `${t("day.wed")}`,
    `${t("day.thu")}`,
    `${t("day.fri")}`,
    `${t("day.sat")}`,
  ];
  const d = new Date();
  const language = i18n.language;
  let dowLength = 3;
  if (language == "de") {
    dowLength = 2;
  }
  let today = weekday[d.getDay()].substring(0, dowLength);
  let tomorrow = weekday[(d.getDay() + 1) % 7].substring(0, dowLength);
  let dayAfterTomorrow = weekday[(d.getDay() + 2) % 7].substring(0, dowLength);

  return { today, tomorrow, dayAfterTomorrow };
}
