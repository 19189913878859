import React from "react";
import SearchControl from "@modules/Projects/pages/SearchControl";
import { MapContainer, Marker, TileLayer, useMap, useMapEvents } from "react-leaflet";

function ChooseLocation({ showMap, setPosition, setShowMap, mapPosition }) {
  return (
    <div>
      {showMap && (
        <MapContainer
          center={[51.505, -0.09]}
          zoom={7}
          zoomControl={false}
          style={{ height: "250px", marginTop: "10px" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <SearchControl
            showMarker={true}
            showPopup={true}
            popupFormat={(result) => result.result.label}
            maxMarkers={3}
            retainZoomLevel={false}
            animateZoom={true}
            autoClose={false}
            searchLabel={"Enter address, please"}
            keepResult={true}
            position="topleft"
          />

          <LocationMarker
            setPosition={setPosition}
            setShowMap={setShowMap}
            mapPosition={mapPosition}
          />
        </MapContainer>
      )}
    </div>
  );
}

export default ChooseLocation;

function LocationMarker({ setPosition, mapPosition }) {
  const map = useMap();
  map.setView(mapPosition.latlng, 13);
  useMapEvents({
    click(e) {
      setPosition(e);
    },
  });
  return <>{mapPosition ? <Marker position={mapPosition.latlng} /> : <></>}</>;
}
