export const filterInitialState = {
  areas: [],
  trees: [],
  areasId: [],
  treesId: [],
  sensors: [],
  sensorId: [],
  items: [],
  tree_sensor_pairs: [],
  tree_sensor_pairs_tree: [],
};
