import { ProjectIcon, SensorsIcon, TreesIcon } from "../icons";

const navItemJson = [
  // {
  // 	id: 0,
  // 	link: "/dashboard",
  // 	title: "dashboard.title",
  // 	icon: <DashboardIcon size={"1.2em"} />,
  // 	activeClassName: "dashboard",
  // 	ariaLabel: "dashboard",
  // },
  {
    id: 1,
    link: "/projects",
    title: "projects",
    icon: <ProjectIcon />,
    ariaLabel: "projects",
  },
  {
    id: 2,
    link: "/sensors",
    title: "sensors",
    icon: <SensorsIcon size={"1.2em"} />,
    ariaLabel: "sensors",
  },
  {
    id: 3,
    link: "/trees",
    title: "trees",
    icon: <TreesIcon size="1.2em" />,
    ariaLabel: "trees",
  },
];

export default navItemJson;
