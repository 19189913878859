import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import useChoices from "@hooks/useChoices";
import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Modal, ModalBody } from "react-bootstrap";

import { addTreeMaintenance, updateMaintenance } from "@modules/Trees/services/treeCrud";
import { AngleDownIcon, AngleUpIcon } from "@components/icons";

export default function MaintenanceForm({
  show,
  tree,
  setOpenForm,
  isUpdate,
  setIsUpdate,
  maintenanceItem,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [options, setOptions] = useState();
  const [choicedata, setChoice] = useChoices();
  const [maintenance] = useChoices("maintenance");
  const [showOtherField, setShowOtherField] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const query = new URLSearchParams(useLocation().search);
  const tid = query.get("selectedTree");

  useEffect(() => {
    if (tree.phase === 1) {
      setChoice("maintenance_young_tree");
    }
    if (tree.phase === 2) {
      setChoice("maintenance_old_tree");
    }
  }, [tree]);

  const handleClose = () => {
    setOpenForm(false);
    setIsUpdate(false);
  };
  const { isLoading, mutateAsync } = useMutation(
    isUpdate ? updateMaintenance : addTreeMaintenance,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tree", { tid: tid });
        handleClose();
      },
    }
  );

  useEffect(() => {
    if (choicedata && maintenance) {
      const option = [...maintenance, ...choicedata];
      setOptions(option);
    }
  }, [choicedata, maintenance]);
  const now = new Date();
  var initialValues = isUpdate
    ? maintenanceItem
    : {
        duration: "0.5",
        tasks: [],
        notes: "",
        other: "",
        date: now.toISOString().slice(0, 16),
      };
  return (
    <>
      <Modal data-testid="tree-form-modal" onHide={handleClose} show={show} centered>
        <ModalHeader closeButton>
          <h5 className="mb-0">{isUpdate ? `${t("edit")} ${t("tree")}` : `${t("addTree")}`}</h5>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              const formdata = {
                duration: values.duration,
                notes: values.notes,
                other: values.other,
                tasks: values.tasks,
                user: { type: "User", id: user.user_id },
                date: values.date,
              };
              await mutateAsync({
                formdata: formdata,
                id: isUpdate ? maintenanceItem.id : tree.id,
              });
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <label htmlFor="">{t("time")}</label>
                <Field className="form-control" type="datetime-local" name="date" />
                <Row className="mb-1">
                  <Col lg={9}>
                    <label htmlFor="">{t("type_of_maintenance")}</label>
                    <ReactSelect
                      id={"sensor"}
                      type={"text"}
                      onChange={(option) => {
                        return setFieldValue(
                          "tasks",
                          option?.map((task) => task.value)
                        );
                      }}
                      options={options?.map((maintain) => ({
                        value: maintain.key,
                        label: t(`${maintain.value.toString().toLowerCase().replace(/ /g, "_")}`),
                      }))}
                      isMulti
                    />
                  </Col>
                  <Col lg={3}>
                    <label htmlFor="duration">{t("time")}</label>
                    <Field
                      component="select"
                      name="duration"
                      value={values.duration}
                      className="form-select"
                    >
                      <option value="0.5">0.5h</option>
                      <option value="1">1h</option>
                      <option value="1.5">1.5h</option>
                      <option value="2">2h</option>
                      <option value="2.5">2.5h</option>
                      <option value="3">3h</option>
                      <option value="4">4h</option>
                      <option value="5">5h</option>
                      <option value="6">6h</option>
                      <option value="7">7h</option>
                      <option value="8">8h</option>
                    </Field>
                  </Col>
                </Row>
                <a href="#" onClick={() => setShowOtherField(!showOtherField)}>
                  <small>
                    {t("custom_maintenance_type")}
                    {!showOtherField ? <AngleDownIcon /> : <AngleUpIcon />}
                  </small>
                </a>
                {showOtherField && (
                  <Field
                    className="form-control my-2 text-sm"
                    type="text"
                    name="other"
                    placeholder={t("add_your_own_maintenance")}
                  />
                )}
                <Field
                  component="textarea"
                  cols="8"
                  className="form-control my-2 text-sm"
                  type="text"
                  name="notes"
                  placeholder={t("write_some_notes_on_action_performed")}
                />
                <button type="submit" className="btn btn-outline-primary">
                  {isLoading ? t("saving") : t("save")}
                </button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}
