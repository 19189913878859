import { useEffect, useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button } from "react-bootstrap";

import { getAllSensors } from "../../../../../Sensors/services/sensorCrud";
import { CloseOutlineIcon } from "@components/icons";
import { ITree } from "../../../../../../interfaces";
import { InstallationForm, InstallationMessage } from "./Installation";
import AddButton from "@components/Button/AddButton";

export default function TreeSensor({ values, setFieldValue, formField, formValue }) {
  const [showInstallationForm, setOpenInstallationForm] = useState(false);
  const [installationItem, setInstallationItem] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const { data: sensors } = useQuery("sensors", getAllSensors, {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { t } = useTranslation();
  const addFields = () => {
    setOpenInstallationForm(true);
  };
  return (
    <>
      <InstallationMessage
        tree={values}
        setIsUpdate={setIsUpdate}
        setOpenForm={setOpenInstallationForm}
        setInstallationItem={setInstallationItem}
      />
      <AddButton
        label={t("installation")}
        action={() => {
          addFields();
        }}
      />
      <InstallationForm
        show={showInstallationForm}
        setOpenForm={setOpenInstallationForm}
        setIsUpdate={setIsUpdate}
        isUpdate={isUpdate}
        tId={formValue?.id}
        setFieldValue={setFieldValue}
        sensors={sensors}
        installationItem={installationItem}
        setInstallationItem={setInstallationItem}
      />
    </>
  );
}
export function TreeSensorOld({ values, setFieldValue, formField, formValue }) {
  const { t } = useTranslation();
  // to ininitails Values needs installations be added and sensor removed!!!
  const installations = values.installations;
  const [showInstallationForm, setOpenInstallationForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const initialInputFields = installations;
  const [inputFields, setInputFields] = useState(initialInputFields);
  const { data: sensors } = useQuery("sensors", getAllSensors, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    console.log("NEW INSTALLATIONS", inputFields);
    setFieldValue("installations", inputFields);
  }, [inputFields]);

  const handleFormChange = (index, event) => {
    console.log("EVENT", index, event);
    const installation = { sensor: event.id };
    const data = [...inputFields];
    if (event.target && event.target.name === "id") {
      data[index]["id"] = event.target.value;
    } else {
      data[index]["id"] = installation;
    }
    setInputFields(data);
  };

  const addFields = () => {
    setOpenInstallationForm(true);
  };

  const handleRemove = (index) => {
    console.log("REMOVIGN");
    const filtered = inputFields.filter((a, i) => i !== index);
    setInputFields(filtered);
  };
  console.log("VALUES", formValue);
  return (
    <div>
      <h6>{t("installed_sensors")}</h6>
      {inputFields?.map((field, index) => {
        return (
          <div
            key={index}
            style={{ display: "flex", background: "#FFFFFF", alignItems: "end-strech" }}
          >
            <h6>Name: {field.sensor.name} </h6>
            <p>Installed at: {field.installed_at}</p>
            <Button
              variant="outline-primary"
              className="border-0 px-2"
              style={{ height: "38px" }}
              onClick={() => handleRemove(index)}
            >
              <CloseOutlineIcon />
            </Button>
          </div>
        );
      })}
      <AddButton
        label={t("installation")}
        action={() => {
          addFields();
        }}
      />
      <InstallationForm
        show={showInstallationForm}
        setOpenForm={setOpenInstallationForm}
        setIsUpdate={setIsUpdate}
        isUpdate={isUpdate}
        tId={formValue.id}
        inputFields={inputFields}
        setFieldValue={setFieldValue}
        sensors={sensors}
      />
    </div>
  );
}
