export const TRANSLATIONS_EN = {
  email: "Email",
  password: "Password",
  forgotpassword: "Forgot password?",
  email_field_is_required: "Email field is required",
  reset_password: "Reset Password",
  password_field_is_required: "Password field is required",
  password_do_not_match: "Password do not match",
  invalid_email_address: "Invalid email address",
  enter_email_address: "Enter email address",
  create_account: "Create Account",
  dont_have_an_account: "Don't have an account ?",
  already_have_an_account: "Already have an account?",
  enter_the_email_address_associated_with_your_account_and_we_will_send_you_a_link_to_reset_your_password:
    "Enter the email address associated with your account and we will send you a link to reset your password.",
  back_to_login: "Back to login",
  incorrect_email_or_password: "Incorrect email or password",
  login: "Login",
  dashboard: {
    title: "Dashboard",
    view_trees: "View Trees",
    view_sensors: "View Sensors",
  },
  newproject: "New Project",
  logout: "Log Out",
  viewproject: "View Project",
  createproject: "Create Project",
  projectname: "Project name",
  selecttree: "Select Tree",
  users: "Users",
  username: "Username",
  treereference: "Tree Reference",
  sortoftree: "Sort of Tree",
  save: "Save",
  saving: "Saving",
  position: "Position",
  Notes: "Notes",
  addTree: "Add Tree",
  TreeType: "TreeType",
  old: "old",
  new: "New",
  name: "Name",
  image: "Image",
  abort: "Abort",
  next: "Next",
  back: "Back",
  update: "Update",
  updating: "Updating",
  signup: "Signup",
  address: "Address",
  deleting: "Deleting",
  confirm: "Confirm",
  supplies: "Supplies",
  no_connection: "No Connection",
  battery_status: "Battery Status",
  graph: "Graph",
  page: "Page",
  health_state: "Health State",
  last_send: "Last Send",
  start_date: "Start Date",
  end_date: "End Date",
  send: "Send",
  lightdry: "Light dry",
  project_details: {
    empty_project: "Empty Project, please start adding trees.",
    empty_trees: "Empty Trees, please start adding trees.",
    no_tree_selected: "Please select tree to view details",
    empty_location: "No location found, Please add location",
    no_area_selected: "Please select area to view details",
  },
  user_details: {
    empty_project: "Empty User, please start adding trees.",
    no_user_selected: "Please select user to view details",
  },
  sensor_details: {
    empty_sensors: "Empty Sensor, please start adding trees.",
    no_sensor_selected: "Please select sensor to view details",
    no_data_found: "No Sensor Data Found",
  },
  Set_the_report_of_the_project_with_the_help_of_the_pin:
    "Set the report of the project with the help of the pin",
  sensor: "Sensor",
  sensors: "Sensors",
  sensor_data: "Sensor Data",
  project: "Project",
  projects: "Projects",
  add: "Add",
  edit: "Edit",
  delete: "Delete",
  trees: "Trees",
  tree: "Tree",
  species: " Species",
  genus: "Genus",
  customers: "Customers",
  customer: "Customer",
  actions: "Actions",
  battery: "Battery",
  threshold: "Threshold",
  settings: "Settings",
  notifications: "Notifications",
  mark_all_as_seen: "Mark all as Seen.",
  showing: "Showing",
  hardware_serial: "DevEUI",
  imei: "IMEI",
  add_sensor: "Add Sensor",
  add_user: "Add User",
  edit_sensor: "Edit Sensor",
  date_of_planting: "Date of Planting",
  push_message_notification: "Push Message Notifications",
  email_notification: "E-Mail Notifications",
  sensor_status_change_to_no_connection: "Sensor status change to no connection",
  sensor_status_change_to_dry: "Sensor status change to dry",
  sensor_status_change_to_drying_out: "Sensor status change to drying out",
  treespecies: "Tree Species",
  treegenus: "Tree Genus",
  skip: "Skip",
  notes: "Notes",
  latitude: "Latitude",
  longitude: "Longitude",
  trunk_circumference: "Trunk Circumference",
  planting_year: "Planting Year",
  select_year: "Choose Year",
  crown_diameter: "Crown Diameter",
  represent_tree: "Represent Tree",
  planting_time: "Planting Time",
  altitude: "Altitude",
  height: "Height",
  location: "Location",
  reference: "Reference",
  invalid_date: "Invalid date",
  error: {
    this_field_is_required: "This field is required",
    name_field_is_required: "Name field is required",
    please_choose_species: "Please choose species",
    please_choose_genus: "Please choose genus",
    choose_location: "Choose Location",
    hardware_serial_no_is_required: "Hardware serial no is required",
    a_valid_threshold_is_required: "A valid threshold is required",
    form: "The values were entered incorrectly. Please correct them and try again",
  },
  first_name: "First Name",
  last_name: "Last Name",
  profile: "Profile",
  phone_number: "Phone number",
  account_type: "Account Type",
  city: "City",
  street_name: "Street Name",
  house_number: "House Number",
  country: "Country",
  zip_code: "Zip Code",
  day: {
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
  },
  end: "End Date",
  admin: "Admin",
  basic: "Basic",
  add_existing_tree: "Add Existing Tree",
  phase: "Phase",
  health_states: {
    healthy: "Healthy",
    medium: "Medium",
    critical: "Critical",
    dry: "Dry",
    frost: "Frost",
    disconnect: "Disconnect",
    drying_out: "Drying out",
    unknown: "Unknown",
  },
  tree_cadastre_no: "Tree Cadastre No",
  export: "Export",
  problem_in_creating_customer: "Problem in creating customer",
  problem_in_creating_sensor: "Problem in creating sensor",
  select_customer_type: "Select Customer Type",
  assign_to_tree: "Assign to tree",
  choose_tree: "Choose Tree",
  customer_type: "Customer Type",
  import_trees: "Import Trees",
  choose_file: "Choose File",
  upload: "Upload",
  choose_the_file_to_be_imported: "Choose the file to be imported",
  upload_tree: "Upload Tree",
  change_image: "Change Image",
  tree_added_successfully: "Tree added successfully",
  change_language: "Change language",
  total_trees: "Total no of trees",
  total_trees_species: "Total no of Tree Species",
  total_sensors: "Total no of sensors",
  critical_sensors: "Total no of critical sensors",
  number_of_tree_on_respective_area: "Number of tree on respective area",
  number_of_tree_based_on_health_status: "Number of tree based on health status",
  time: "Time",
  maintainance: "Maintainance",
  type_of_maintenance: "Type of maintenance",
  custom_maintenance_type: "Custom maintenance type",
  add_your_own_maintenance: "Add your own maintenance",
  disconnected_sensors: "Disconnected Sensors",
  write_something: "Write Something...",
  write_some_notes_on_action_performed: "Write some notes on action performed...",
  search_sensor: "Search Sensor",
  resistance: "Resistance",
  water_soil: "Soil Moisture",
  irrigation: "Irrigation",
  crown_care: "Crown Care",
  tree_control: "Tree Control",
  weed_removal_root: "Weed Removal Root",
  ligature_control: "Ligature Control",
  trunk_protection: "Trunk Protection",
  tree_stakes_control: "Tree Stakes Control",
  clearance_profile_pruning: "Clearance Profile Pruning",
  deadwood_removal: "Deadwood Removal",
  stem_cane_removal: "Stem Cane Removal",
  topiary: "Topiary",
  head_purning: "Head Pruning",
  either_fill_all_address_or_make_all_empty: "Either fill all address or make all empty",
  search_tree: "Search Tree",
  mark_as_Seen: "Mark as seen",
  tick_the_checkbox_to_download_data: "Tick the checkbox to download data  ",
  download_sensor_data: "Download sensor data",
  sensor_name: "Sensor name",
  temperature: "Temperature",
  rain: "Rain",
  loading_data: "Loading data ...",
  align_qr_code_within_the_frame: "Align QR code within the frame",
  gallery: "Gallery",
  scan_to_add_sensor: "Scan to add sensor",
  choose_qr_to_scan: "Choose QR to scan",
  pulse_resistance_in_kOhm: "Pulse Resistance in kOhm",
  loading: "Loading ...",
  irrigationArea: "Irrigation Area",
  button: {
    back: "Back",
    next: "Next",
    save: "Save",
    reset: "Reset",
    saving: "Saving ...",
    update: "Update",
    updating: "Updating ...",
    add_tree: "Add Tree",
    add_sensor: "Add Sensor",
    import_tree: "Import Tree",
    add_irrigation_area: "Add Irrigation Area",
    add_customer: "Add Customer",
    add_all: "Add Tree with Components",
  },
  choose_location: "Choose Location",
  tree_form: {
    sensors: "Sensors",
    position: "Position",
    more_info: "More Info",
    tree_profile: "Tree Profile",
    representTree: "Child Trees",
  },
  user_form: {
    profile: "Profile",
    address: "Address",
    basic_info: "Basic Info",
    login_credentials: "Login Credentials",
  },
  sensor_form: {
    name: "Name",
    threshold: "Threshold",
    hardware_serial: "Hardware Serial",
  },
  add_all_form: {
    title: "Add tree with sensor in area",
    sensor: {
      title: "Sensor",
      explanation:
        "Do you want to a Sensor add an existing Tree or do you want to create a new one?",
      new_sensor: "Add a new Sensor",
      existing_sensors: "Choose existing Sensors",
    },
    tree: {
      title: "Tree",
      explanation:
        "Do you want to add an existing Tree or do you want to create a new one?Hier kann ein neuer Sensor erstellt und dem Baum hinzugefügt werden. Oder aber ein bereits bestehender Sensor kann ausgewählt werden, um ihn dem hinzuzufügen.",
      new_tree: "Create a new Tree",
      existing_tree: "Choose an existing Tree",
    },
    area: {
      title: "Area",
      explanation:
        "Do you want to add the Tree to an existing Area or do you want to create a Area and add the Tree to it?",
      new_area: "Create a new Area",
      existing_area: "Choose an existing Area",
    },
  },
  create_new_tree: "Create new Tree",
  validation: {
    email_is_required: "Email is required",
    password_is_required: "Password is required",
    invalid_email_address: "Invalid email address",
    name_is_required: "Name is required",
    hardware_serial_is_required: "Hardware serial is required",
    user_with_this_email_already_exist: "User with this email already exist",
    all_address_field_are_required: "All address field are required",
    position_is_required: "Position is required",
    invalid_house_number: "Invalid house number",
    user_limit_exceeded_for_your_package: "User limit exceeded for your package",
    sensor_limit_exceeded_for_your_package: "Sensor limit exceeded for your package",
  },

  "Ensure this field has at least 16 characters.":
    "Ensure hardware serial field has at least 16 characters.",
  drag_the_below_variables_to_graph_area: "Drag the below variables to graph area",
  drop_left: "Drop Left",
  drop_right: "Drop Right",
  drop_the_variable_to_get_data: "Drop the variable. Go on!",
  no_data_available_please_check_data_available_to_this_date:
    "No data available, please check data available to this date",
  unable_to_upload_file: "Unable to upload file",
  file_upload_successfully: "File upload successfully",
  no_tree_is_selected: "No tree is selected",
  no_sensor_is_selected: "No sensor is selected",
  areaName: "Name",
  childTree: "Child Tree",
  add_child_tree: "Add child tree",
  parentTree: "Parent Tree",
  area: "Area",
  areas: "Areas",
  start_with_adding_new_project: "Start with adding new project",
  resis_temp_comp: "ResisTempComp",
  health_of_tree_is_good: "Health state of tree is good",
  health_of_tree_is_drying_out: "Health of tree is drying out",
  health_of_tree_is_poor: "Health of tree is poor",
  sensor_disconnect: "Sensor disconnect",
  battery_full: "Battery full",
  less_than_60_of_the_battery_remaining: "Less than 60% of the battery remaining",
  battery_is_getting_low: "Battery is getting low",
  size: "Size",
  type: "Type",
  map_view: "Map view",
  records: "Records",
  status: "Status",
  welcome_back: "Welcome Back!",
  looks_like_you_dont_have_selected_area_please_select_the_area_to_get_data:
    "Looks like you don't have selected  area. Please select an area to get data.",
  looks_like_you_dont_have_selected_tree_please_select_a_tree_to_get_data:
    "Looks like you don't have selected tree. Please select a tree to get data.",
  looks_like_you_dont_have_selected_sensor_please_select_a_sensor_to_get_data:
    "Looks like you don't have selected  sensor. Please select a sensor to get data.",
  please_drag_the_variable_to_display_data: "Please drag the variable to display data.",
  click_on_marker_to_select_tree: "Click on marker to select tree",
  next_irrigation: "Next Irrigation",
  mean: "Mean",
  sensor_state: "Sensor State",
  state: "State",
  moisture_content: "Moisture Content",
  card_number: "Card Numer",
  card_name: "Card Name",
  expiration_date: "Expiration Date",
  continue: "Continue",
  today: "Today",
  tomorrow: "Tomorrow",
  days_left: "days left",
  days: "Days",
  personal_data: "Personal data",
  appearance: "Appearance",
  saved: "Saved",
  payment: "Payment",
  benifits: "Benifits",
  change_logo: "Change logo",
  role: "Role",
  amount_nodes: "Amount sensor nodes",
  last_battery: "Last Battery",
  characters: "Characters",
  maximum_of: "Maximum",
};
