import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
// import "react-leaflet-fullscreen/dist/styles.css";
// import { getIrrigationArea } from "../../../../IrrigationArea/services/areaCrud";
// import PageNotFound from "@components/Error/PageNotFound";
import {
  FeatureGroup,
  MapContainer,
  Polygon,
  TileLayer,
  GeoJSON,
  Marker,
  ZoomControl,
  Popup,
  useMapEvents,
} from "react-leaflet";
// import { FullscreenControl } from "react-leaflet-fullscreen";
import { parentTreeIcon, childTreeIcon, noSensorTreeIcon } from "@helper/marker";
import useChoices from "@hooks/useChoices";

function IrrigationAreaDetail({ project, tid, lng, lat, selectedTree, mapCenter }) {
  let allTrees = [];
  project.areas.map((area) => {
    allTrees.push(...area.tree_set);
  });
  return (
    <Col lg="9">
      {mapCenter && (
        <MapContainer
          center={mapCenter}
          zoom={14}
          style={{ height: "calc(100vh - 160px", borderRadius: "3px" }}
          zoomControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <ZoomControl position="bottomright" />
          {/* <FullscreenControl position="bottomright" /> */}
          <FeatureGroup>
            <div>
              <GeoJSON color="purple" data={[]} />
              <Polygon key={project.id} positions={[]} />
            </div>
          </FeatureGroup>
          {allTrees?.map((tree, i) => {
            const health = tree.health_state;
            if (tree.position) {
              return (
                <div key={i}>
                  {tree.position && (
                    <LocationMarker
                      key={tree.id}
                      tree={tree}
                      icon={
                        tree.installations.length != 0 ? parentTreeIcon(health) : noSensorTreeIcon()
                      }
                      isActive={tree.id == tid ? true : false}
                    />
                  )}
                  {tree?.represented_trees.map((childTree, i) => {
                    const childHealth = childTree.health_state;
                    return (
                      <div key={i}>
                        {childTree.position && (
                          <LocationMarker
                            key={childTree.id}
                            tree={childTree}
                            icon={childTreeIcon(childHealth)}
                            isActive={childTree.id == tid ? true : false}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
          {lat && lng && selectedTree && <MovementMarker tree={selectedTree} lat={lat} lng={lng} />}
        </MapContainer>
      )}
    </Col>
  );
}

export default IrrigationAreaDetail;

const MovementMarker = ({ tree, lat, lng }) => {
  const health = tree.health_state;
  const popupRef = useRef();

  useEffect(() => {
    popupRef.current.openPopup();
  }, [popupRef, tree]);

  return (
    <Marker
      position={[lat, lng]}
      icon={tree.installations.length != 0 ? parentTreeIcon(health) : noSensorTreeIcon()}
      ref={popupRef}
    >
      <Popup minWidth={350} autoPan={false}>
        <div className="popup">
          <Row>
            <PopupContent tree={tree} />
          </Row>
        </div>
      </Popup>
    </Marker>
  );
};

// map marker position handle by external event
function LocationMarker({ isActive, tree, icon }) {
  const map = useMapEvents({});
  map.invalidateSize();

  const [refReady, setRefReady] = useState(false);
  let popupRef = useRef();

  useEffect(() => {
    if (refReady && isActive) {
      popupRef.openOn(map);
    }
  }, [isActive, refReady, map]);

  useEffect(() => {
    if (tree.position) map.flyTo(tree.position, map.getZoom("18"));
  }, [tree.position, tree]);

  return (
    <Marker position={[tree?.position?.lat, tree?.position?.lng]} icon={icon}>
      <Popup
        minWidth={350}
        ref={(r) => {
          popupRef = r;
          setRefReady(true);
        }}
      >
        <div className="popup">
          <Row>
            <PopupContent tree={tree} />
          </Row>
        </div>
      </Popup>
    </Marker>
  );
}

const PopupContent = ({ tree }) => {
  const { t } = useTranslation();
  const [phases] = useChoices("growth_phase");
  const [locations] = useChoices("growth_phase");
  const phase = phases && phases.filter((item) => item.key == tree?.phase);
  const location = locations && locations.filter((item) => item.key == tree?.location);

  return (
    <>
      <Col lg={6} className="px-1">
        <ul className="list-group">
          <li className="list-group-item">
            <b>{t("name")}:</b> {tree.name}
          </li>
          <li className="list-group-item">
            {/*
						<b>{t("document irrigation")}: </b>
						<Button
							variant="outline-primary d-flex align-items-center"
							onClick={() => console.log("Button clicked")}
						>
							<>
								{t("Irrigation")} &nbsp;
								{<Spinner animation="border" size="sm" />}
							</>
						</Button>
          */}

            {location && location[0] ? location[0].value : ""}
          </li>
          <li className="list-group-item">
            <b>{t("height")}: </b> {tree.height && tree.height + " m"}
          </li>
        </ul>
      </Col>
      <Col lg={6} className="px-1">
        <ul className="list-group">
          <li className="list-group-item">
            <b>{t("crown_diameter")}: </b>
            {tree.crown_diameter && tree.crown_diameter + " m"}
          </li>
          <li className="list-group-item">
            <b>{t("trunk_circumference")}: </b>
            {tree.trunk_circumference && tree.trunk_circumference + " cm"}
          </li>

          <li className="list-group-item">
            <b>{t("phase")}: </b>
            {phase && phase[0] ? phase[0].value : ""}
          </li>
        </ul>
      </Col>
    </>
  );
};
