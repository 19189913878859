import React from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../FormModel/userFormModel";

function LoginAccount({ formField, validation, errors, isUpdate }) {
  const { email, password } = formField;
  const { t } = useTranslation();
  return (
    <div className="d-flex align-item-center w-100 gap-2">
      <Form.Group className="w-50">
        <Form.Label> {t(email.label)}</Form.Label>
        <Field
          type="text"
          name={email.name}
          className={errors && errors.email ? "form-control is-invalid" : "form-control "}
          validate={validation.validateEmail}
        />
      </Form.Group>
      <Form.Group className="w-50">
        <Form.Label>{t(password.label)}</Form.Label>
        <Field
          type="password"
          name={password.name}
          className={errors && errors.password ? "form-control  is-invalid" : "form-control  "}
          validate={!isUpdate && validation.validatePassword}
        />
      </Form.Group>
    </div>
  );
}

export default LoginAccount;
