import { Field, ErrorMessage } from "formik";
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import ChooseLocation from "./ChooseLocation";
import { useTranslation } from "react-i18next";
import { MapIcon, CrosshairIcon } from "@components/icons";

function LocationComponent({ setPosition, setMapPosition }) {
  const [error, setError] = useState<string | null>(null);

  const findCurrentPosition = () => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setMapPosition({
          latlng: { lat: position.coords.latitude, lng: position.coords.longitude },
        });
      },
      () => {
        setError("Unable to retrieve your location");
      }
    );
  };

  return (
    <button
      onClick={findCurrentPosition}
      className="btn btn-primary btn-sm mt-auto"
      title="Get current Location"
      type="button"
    >
      <CrosshairIcon />
      {error && <p>{error}</p>}
    </button>
  );
}

export default function TreeLocation({ values, formField, validation, setFieldValue }) {
  const [showMap, setShowMap] = useState(true);
  const [mapPosition, setMapPosition] = useState(null);
  const { latitude, longitude, altitude } = formField;
  const { t } = useTranslation();
  const valid = validation(t);

  useEffect(() => {
    values
      ? setMapPosition({ latlng: { lat: values.latitude, lng: values.longitude } })
      : setMapPosition({ latlng: { lat: "48.1351", lng: "11.5820" } });
  }, [values]);

  useEffect(() => {
    if (mapPosition) {
      setFieldValue("latitude", mapPosition.latlng.lat);
      setFieldValue("longitude", mapPosition.latlng.lng);
    }
  }, [mapPosition]);

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <button
          type="button"
          className="btn btn-primary btn-sm mt-auto"
          title="Choose position on map"
          onClick={() => setShowMap(!showMap)}
        >
          <MapIcon />
        </button>

        <div className="d-flex flex-1 align-items-center gap-2">
          <Form.Group className="flex-fill">
            <Form.Label htmlFor="">{t(latitude.label)} </Form.Label>
            <Field
              type="number"
              name={latitude.name}
              validate={valid.positionValidation}
              className="form-control"
              placeholder={"e.g.: 12.34"}
            />
          </Form.Group>
          <Form.Group className="flex-fill">
            <Form.Label htmlFor="">{t(longitude.label)} </Form.Label>
            <Field
              type="number"
              name={longitude.name}
              validate={valid.positionValidation}
              className="form-control"
              placeholder={"e.g.: 12.34"}
            />
          </Form.Group>
          <Form.Group className="flex-fill">
            <Form.Label htmlFor="">{t(altitude.label)}</Form.Label>
            <Field type="number" name={altitude.name} className="form-control" />
          </Form.Group>
        </div>
        <LocationComponent setPosition={setFieldValue} setMapPosition={setMapPosition} />
      </div>
      {mapPosition && (
        <ChooseLocation
          showMap={showMap}
          setPosition={setMapPosition}
          setShowMap={setShowMap}
          mapPosition={mapPosition}
          values={values}
        />
      )}
    </>
  );
}
