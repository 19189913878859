import Select from "react-select";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { CloseOutlineIcon } from "@components/icons";
import { ITree } from "../../../../../interfaces";

export default function ParentChildTree({ values, setFieldValue, parentOption, childOption }) {
  const [inputFields, setInputFields] = useState();

  useEffect(() => {
    const b: Array<ITree> = [];
    values?.trees?.map((a: ITree) => {
      b.push({ id: a.id, name: a.name, represented_trees: a.represented_trees });
    });
    setInputFields(b);
  }, []);

  useEffect(() => {
    setFieldValue("trees", inputFields);
  }, [inputFields]);

  const handleFormChange = (index, event) => {
    const data = [...inputFields];
    if (event.target && event.target.name === "id") {
      data[index]["id"] = event.target.value;
    } else {
      data[index]["represented_trees"] = event;
    }
    setInputFields(data);
  };

  const addFields = () => {
    const newfield = {};
    setInputFields([...inputFields, newfield]);
  };

  const handleRemove = (index) => {
    const filtered = inputFields.filter((a, i) => i !== index);
    setInputFields(filtered);
  };
  const parentIds = parentOption.map((tree) => {
    return +tree.id;
  });
  const isParentTree = (tree: ITree) => (tree.id ? parentIds.includes(+tree.id) : true);
  const parentInputFields = inputFields?.filter(isParentTree);

  return (
    <div>
      <Row>
        <Col lg={5}>
          <h6>Parent Tree</h6>
        </Col>
        <Col lg={5}>
          <h6>Child Tree</h6>
        </Col>
      </Row>
      {parentInputFields?.map((field, index) => {
        const selectedTree = parentOption.filter((tree) => tree.id == field.id);
        return (
          <Row className="g-1 mb-2" key={index}>
            <Col lg={5}>
              <div>
                <Form.Select
                  name="id"
                  style={{ height: "38px" }}
                  onChange={(event) => handleFormChange(index, event)}
                  value={selectedTree?.id}
                  defaultValue={"Choose Tree"}
                >
                  {<option selected>{field.name}</option>}

                  {parentOption?.map((tree) => (
                    <option value={tree.id} key={tree.id}>
                      {tree.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Select
                  id={"trees"}
                  defaultValue={field.represented_trees?.map((representedTree) => ({
                    value: representedTree.id,
                    label: representedTree.name,
                  }))}
                  onChange={(option) => {
                    const opt = option?.map((tree) => ({
                      id: parseInt(tree.value),
                      type: "Tree",
                    }));
                    handleFormChange(index, opt);
                  }}
                  options={childOption?.map((tree) => ({
                    value: tree.id,
                    label: tree.name,
                  }))}
                  isMulti
                />
              </div>
            </Col>
            <Col>
              <Button
                variant="outline-primary"
                className="border-0 px-2"
                style={{ height: "38px" }}
                onClick={() => handleRemove(index)}
              >
                <CloseOutlineIcon />
              </Button>
            </Col>
          </Row>
        );
      })}
      <button type="button" className="btn btn-primary btn-sm" onClick={addFields}>
        Add More..
      </button>
    </div>
  );
}
