const TablePagination = (props) => {
  let totalPages = props.pageOptions.length;
  let pageIndex = props.pageIndex; //  + 1;

  const renderPageButtons = () => {
    const pageButtons = [];

    if (totalPages <= 5) {
      // render all pages
      for (let i = 0; i < totalPages; i++) {
        pageButtons.push(
          <button
            key={i}
            className="btn btn-sm btn-outline-primary"
            style={{ width: "30px", height: "30px" }}
            onClick={() => props.gotoPage(i)}
            disabled={i === pageIndex}
          >
            {i + 1}
          </button>
        );
      }
    } else {
      // more than 5 pages
      if (pageIndex <= 2) {
        // current page within the beginning
        for (let i = 0; i < 3; i++) {
          pageButtons.push(
            <button
              key={i}
              className="btn btn-sm btn-outline-primary"
              style={{ width: "30px", height: "30px" }}
              onClick={() => props.gotoPage(i)}
              disabled={i === pageIndex}
            >
              {i + 1}
            </button>
          );
        }
        pageButtons.push(<span key="ellipsis1">...</span>);
        pageButtons.push(
          <button
            key={totalPages - 1}
            className="btn btn-sm btn-outline-primary"
            style={{ width: "30px", height: "30px" }}
            onClick={() => props.gotoPage(totalPages - 1)}
            disabled={totalPages - 1 === pageIndex}
          >
            {totalPages}
          </button>
        );
      } else if (pageIndex >= totalPages - 3) {
        // last 3 pages
        pageButtons.push(
          <button
            key={0}
            className="btn btn-sm btn-outline-primary"
            style={{ width: "30px", height: "30px" }}
            onClick={() => props.gotoPage(0)}
            disabled={0 === pageIndex}
          >
            1
          </button>
        );
        pageButtons.push(<span key="ellipsis2">...</span>);
        for (let i = totalPages - 3; i < totalPages; i++) {
          pageButtons.push(
            <button
              key={i}
              className="btn btn-sm btn-outline-primary"
              style={{ width: "30px", height: "30px" }}
              onClick={() => props.gotoPage(i)}
              disabled={i === pageIndex}
            >
              {i + 1}
            </button>
          );
        }
      } else {
        // middle
        pageButtons.push(
          <button
            key={0}
            className="btn btn-sm btn-outline-primary"
            style={{ width: "30px", height: "30px" }}
            onClick={() => props.gotoPage(0)}
            disabled={0 === pageIndex}
          >
            1
          </button>
        );
        pageButtons.push(<span key="ellipsis3">...</span>);
        for (let i = pageIndex - 1; i <= pageIndex + 1; i++) {
          pageButtons.push(
            <button
              key={i}
              className="btn btn-sm btn-outline-primary"
              style={{ width: "30px", height: "30px" }}
              onClick={() => props.gotoPage(i)}
              disabled={i === pageIndex}
            >
              {i + 1}
            </button>
          );
        }
        pageButtons.push(<span key="ellipsis4">...</span>);
        pageButtons.push(
          <button
            key={totalPages - 1}
            className="btn btn-sm btn-outline-primary"
            style={{ width: "30px", height: "30px" }}
            onClick={() => props.gotoPage(totalPages - 1)}
            disabled={totalPages - 1 === pageIndex}
          >
            {totalPages}
          </button>
        );
      }
    }
    return pageButtons;
  };

  return (
    <div className="pagination d-flex align-items-center justify-content-between  py-2">
      <div className="table-pagesIndexing">
        {pageIndex + 1} out of {totalPages}
      </div>
      <div className="d-flex align-items-center gap-2">
        <button
          className="btn btn-sm btn-outline-primary"
          style={{ width: "30px", height: "30px" }}
          onClick={props.previousPage}
          disabled={!props.canPreviousPage}
        >
          {"<"}
        </button>
        <ul className="d-flex align-items-center gap-2 m-0 p-0">{renderPageButtons()}</ul>
        <button
          className="btn btn-sm btn-outline-primary"
          style={{ width: "30px", height: "30px" }}
          onClick={props.nextPage}
          disabled={!props.canNextPage}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default TablePagination;
