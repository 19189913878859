/* eslint-disable no-mixed-operators */
export const getTodayWeather = (weather) => {
  if (weather.rain.today === 0 && weather.sun.today >= 3) return 0;
  if (weather.rain.today === 0 && weather.sun.today > 0 < 3) return 1;
  if (weather.rain.today === 0 && weather.sun.today === 0) return 2;
  if (weather.rain.today > 1) return 3;
};

export const getTommorowWeather = (weather) => {
  if (weather.rain.tomorrow === 0 && weather.sun.tomorrow >= 3) return 0;
  if (weather.rain.tomorrow === 0 && weather.sun.tomorrow > 0 < 3) return 1;
  if (weather.rain.tomorrow === 0 && weather.sun.tomorrow === 0) return 2;
  if (weather.rain.tomorrow > 1) return 3;
};

export const getDayAfterTommorowWeather = (weather) => {
  if (weather.rain.day_after_tomorrow === 0 && weather.sun.day_after_tomorrow > 0 < 3) return 0;
  if (weather.rain.day_after_tomorrow === 0 && weather.sun.day_after_tomorrow >= 3) return 1;
  if (weather.rain.day_after_tomorrow === 0 && weather.sun.day_after_tomorrow === 0) return 2;
  if (weather.rain.day_after_tomorrow > 1) return 3;
};
