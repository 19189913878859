import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SensorStatus } from "./SensorList";
import { useTranslation } from "react-i18next";
import { BatteryFullIcon, BatteryHalfIcon, BatteryIcon, SearchIcon } from "@components/icons";

function filterProducts({ filterTerm, sensors }) {
  if (!filterTerm) {
    return sensors;
  }
  return sensors.filter(
    (product) => product.name && product.name.toLowerCase()?.includes(filterTerm.toLowerCase())
  );
}

function SensorMobileListView({ sensors }) {
  const [filterTerm, setFilterTerm] = useState("");
  const { t } = useTranslation();

  const filteredProducts = filterProducts({ filterTerm, sensors });

  function updateFilterHandler(event) {
    setFilterTerm(event.target.value);
  }

  return (
    <>
      <SearchForm>
        <input
          type="text"
          onChange={updateFilterHandler}
          className="form-control search-box"
          placeholder={t("search_sensor")}
          style={{ padding: "12px 1em 12px 2.5em" }}
        />
        <SearchIcon top={0} width="30px" left="5px" />
      </SearchForm>
      <div role="mobileList">
        {filteredProducts?.map((sensor) => (
          <Link
            to={`/sensor/${sensor.id}?selectedSensor=${sensor.id}`}
            key={sensor.id}
            className="list-unstyled py-2 d-flex justify-content-between clickable"
          >
            <div className="" style={{ width: "35%" }}>
              <h6 className="mb-1">
                <b>{sensor.name}</b>
              </h6>
              <i className="text-muted">{sensor.hardware_serial}</i>
            </div>
            <div>
              {
                {
                  0: <SensorStatus color="#CD3100" />,
                  1: <SensorStatus color="#FEC400" />,
                  2: <SensorStatus color="rgb(194 193 188)" />,
                }[sensor.health_state]
              }
            </div>
            <div>
              <span>{sensor.threshold}</span>
            </div>
            <div className="w-25">
              <small>
                {sensor.battery > 60 ? (
                  <BatteryFullIcon size={"1.3rem"} color="#19b7bf" />
                ) : sensor.battery < 30 ? (
                  <BatteryIcon size={"1.3rem"} color="#CD3100" />
                ) : sensor.battery >= 30 && sensor.battery <= 60 ? (
                  <BatteryHalfIcon size={"1.3rem"} color="#FEC400" />
                ) : (
                  ""
                )}
                &nbsp; {sensor.battery}%
              </small>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}

export default SensorMobileListView;

const SearchForm = styled.div`
  position: relative;
`;
