import React from "react";
import AreaList from "./AreaList";
import TreeList from "./TreeList";
import SensorList from "./SensorList";

function FilterBar() {
  return (
    <div className="filter">
      <AreaList />
      <TreeList />
      <SensorList />
    </div>
  );
}

export default FilterBar;
