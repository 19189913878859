import Select from "react-select";

export const SelectItem = ({ defaultValue, setFieldValue, items, values, type }) => {
  const labels = items ? items.filter((item) => item.id == values[`${type}Id`]) : [];
  const label = labels.length ? labels[0].name : "";
  return (
    <Select
      id={type}
      type="text"
      defaultValue={defaultValue}
      onChange={(option) => {
        setFieldValue(`${type}Id`, option.value);
      }}
      options={items?.map((item) => ({
        value: item.id,
        label: item.name,
      }))}
      isMulti={false}
      value={{
        value: values[`${type}Id`] || "",
        label: label,
      }}
    />
  );
};
