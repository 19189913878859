import { useEffect, useRef, useState } from "react";
import { Accordion, Dropdown, Modal, Form, Row, Col, Button } from "react-bootstrap";
import Avatar from "react-avatar";
import Select from "react-select";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import styled from "styled-components";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { Tree } from "@styled-icons/entypo/Tree";
import { styles } from "@components/styles";
import { CaretRightFillIcon, AddIcon } from "@components/icons";
import { getAllCustomers } from "@modules/Customer/services/customerCrud";
import { updateSingleIrrigationArea } from "@modules/IrrigationArea/services/areaCrud";
import SearchControl from "../../SearchControl";
import { noSensorTreeIcon, parentTreeIcon } from "@helper/marker";
import { GREEN, YELLOW, RED, GREY, BLUE } from "@helper/colors";
import {
  DropdownWrapper,
  ListPT,
  ListNST,
  ListCT,
  TreeIcon,
} from "../ProjectDetailMapView/MapViewTreeList";

export default function MapViewTreeList({
  trees,
  areas,
  setSelectedArea,
  selectedArea,
  setSelectedParentTree,
  selectedParentTree,
  setOpenChildModal,
  openChildModal,
  setSelectedTree,
  tid,
  aId,
}) {
  const { pid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mobile } = useSelector((state) => state.mobile);
  const [childOption, setChildOption] = useState([]);

  const handleTreeSelect = (tree, areaId) => {
    setSelectedParentTree(tree);

    if (tree.position) {
      navigate(
        `/public/project/${pid}/map?area=${areaId}&selectedTree=${tree.id}` +
          `&lat=${tree.position.lat}` +
          `&lngs=${tree.position.lng}`
      );
      setSelectedTree(tree);
    }
  };

  return (
    <>
      {mobile ? (
        <DropdownWrapper>
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              className="btn btn-primary text-white"
            >
              <TreeIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ zIndex: "9999" }}>
              <ListMenu trees={trees} handleTreeSelect={handleTreeSelect} />
            </Dropdown.Menu>
          </Dropdown>
        </DropdownWrapper>
      ) : (
        <>
          <div>
            <h5>{t("areas")}</h5>
            <ul className="p-0 m-0 projectMapViewList dropdown" style={{ maxHeight: "77vh" }}>
              <AreaList
                areas={areas}
                handleTreeSelect={handleTreeSelect}
                setOpenChildModal={setOpenChildModal}
                setSelectedArea={setSelectedArea}
                selectedArea={selectedArea}
                tid={tid}
                aId={aId}
              />
            </ul>
          </div>
        </>
      )}
    </>
  );
}

const AreaList = ({
  areas,
  selectedArea,
  setSelectedArea,
  handleTreeSelect,
  setOpenChildModal,
  tid,
  aId,
}) => {
  const { t } = useTranslation();
  const [selectedTreeNoSensor, setSelectedTreeNoSensor] = useState([]);
  const [selectedTree, setSelectedTree] = useState();
  //default to open selected area
  const aIndex = areas.findIndex((area) => {
    return area.id == aId;
  });

  return (
    <>
      {areas?.map((area, index) => {
        return (
          <Accordion key={index} className="mb-2" defaultActiveKey={[`${aIndex}`]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div
                  className="d-flex justify-content-between  align-items-center"
                  style={{ flex: 1 }}
                >
                  <div className="flex-column">
                    <div className="d-flex align-items-center">
                      <CaretRightFillIcon className="expand-caret" />
                      <h6 className="my-2">{area.name} </h6>
                    </div>
                    <span className="ms-2 text-muted tree-count" style={{ fontSize: "12px" }}>
                      {area.tree_set.length} {t("trees")}
                    </span>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ListMenu
                  trees={area.tree_set}
                  setOpenChildModal={setOpenChildModal}
                  handleTreeSelect={handleTreeSelect}
                  areaId={area.id}
                  setSelectedArea={setSelectedArea}
                  tid={tid}
                  selectedTree={selectedTree}
                  setSelectedTree={setSelectedTree}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
    </>
  );
};

const ListMenu = ({
  trees,
  handleTreeSelect,
  setOpenChildModal,
  areaId,
  setSelectedArea,
  tid,
  selectedTree,
  setSelectedTree,
}) => {
  useEffect(() => {
    tid && setSelectedTree(parseInt(tid));
  }, [tid]);

  const handleClickAdd = (areaId) => {
    setOpenChildModal(true);
    setSelectedArea(areaId);
  };
  const [toggle, setToggle] = useState(false);

  return (
    <>
      {trees?.map((tree) => (
        <div key={tree.id}>
          {tree.installations.length === 0 ? (
            <ListNST
              key={"child" + tree.id}
              className={
                // "accord"
                selectedTree === tree.id
                  ? "d-flex align-items-center "
                  : "d-flex align-items-center clickable"
              }
              style={{ background: selectedTree === tree.id ? "#deebff" : "" }}
              onClick={() => handleTreeSelect(tree, areaId)}
              status={tree.health_state}
            >
              <Avatar
                name={tree.name}
                textMarginRatio={0.25}
                color={
                  tree.health_state === 0
                    ? GREEN
                    : tree.health_state === 1
                    ? YELLOW
                    : tree.health_state === 2
                    ? RED
                    : tree.health_state === 4
                    ? BLUE
                    : GREY
                }
                size="32"
                round="100px"
              />
              <div className="ms-2">
                <h6 className="mb-0">{tree.name} </h6>
                <span className={selectedTree === tree.id ? " small" : "text-muted small"}>
                  {tree.species ? tree.species.name : ""}
                </span>
              </div>
            </ListNST>
          ) : (
            <ListPT
              className={
                selectedTree === tree.id
                  ? "d-flex align-items-center justify-content-between parent-tree"
                  : "d-flex align-items-center justify-content-between clickable parent-tree"
              }
              style={{ background: selectedTree === tree.id ? "#deebff" : "" }}
              onClick={() => {
                handleTreeSelect(tree, areaId);
                // collapseList();
              }}
              status={tree.health_state}
            >
              <div className="d-flex align-items-center">
                <Avatar
                  name={tree.name}
                  textMarginRatio={0.25}
                  color={
                    tree.health_state === 0
                      ? GREEN
                      : tree.health_state === 1
                      ? YELLOW
                      : tree.health_state === 2
                      ? RED
                      : tree.health_state === 4
                      ? BLUE
                      : GREY
                  }
                  size="32"
                  round="100px"
                />
                <div className="ms-2">
                  <h6 className="mb-0">{tree.name} </h6>
                  <span className={selectedTree === tree.id ? " small" : "text-muted small"}>
                    {tree.species ? tree.species.name : ""}
                  </span>
                </div>
              </div>
              {tree.represented_trees.length > 0 && (
                <Button onClick={() => setToggle(!toggle)}>
                  <FontAwesomeIcon
                    icon={(!toggle && faChevronDown) || (toggle && faChevronUp)}
                    style={{ color: "#deddda" }}
                  />
                </Button>
              )}
              <AddIcon onClick={() => handleClickAdd(areaId)} />
            </ListPT>
          )}
          {toggle &&
            tree.represented_trees.map((tree) => (
              <ListCT
                key={"child" + tree.id}
                className={
                  // "accord"
                  selectedTree === tree.id
                    ? "d-flex align-items-center "
                    : "d-flex align-items-center clickable"
                }
                style={{ background: selectedTree === tree.id ? "#deebff" : "" }}
                onClick={() => handleTreeSelect(tree, areaId)}
                status={tree.health_state}
              >
                <Avatar
                  name={tree.name}
                  textMarginRatio={0.25}
                  color={
                    tree.health_state === 0
                      ? GREEN
                      : tree.health_state === 1
                      ? YELLOW
                      : tree.health_state === 2
                      ? RED
                      : tree.health_state === 4
                      ? BLUE
                      : GREY
                  }
                  size="32"
                  round="100px"
                />
                <div className="ms-2">
                  <h6 className="mb-0">{tree.name} </h6>
                  <span className={selectedTree === tree.id ? " small" : "text-muted small"}>
                    {tree.species ? tree.species.name : ""}
                  </span>
                </div>
              </ListCT>
            ))}
        </div>
      ))}
    </>
  );
};
