import { MouseEventHandler } from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Button({
  label,
  action,
  disabled = false,
}: {
  label: string;
  action: MouseEventHandler;
  disabled: boolean;
}) {
  const { t } = useTranslation();
  return (
    <BootstrapButton
      variant="outline-primary"
      className="col-auto "
      onClick={action}
      disabled={disabled}
    >
      {t(label)}
    </BootstrapButton>
  );
}

export default Button;
