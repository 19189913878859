import navItemJson from "./NavItemsList";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { LogoutIcon, SettingIcon, MenuAlt1Icon } from "../icons";

export default function NavItem({ mobileView, handleLogout }) {
  // console.log("HandleLogout", handleLogout);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const { t } = useTranslation(``);
  const showDropdown = () => {
    setShow(!show);
  };
  const hideDropdown = () => {
    setShow(false);
  };
  let { pathname } = useLocation();
  // console.log(pathname);

  //change title based on the nav item select
  useEffect(() => {
    if (pathname.includes("dashboard")) {
      setTitle("dashboard.title");
    } else if (pathname.includes("project")) {
      setTitle("projects");
    } else if (pathname.includes("sensors")) {
      setTitle("sensors");
    } else if (pathname.includes("trees")) {
      setTitle("trees");
    } else {
      setTitle("menu");
    }
  }, [pathname]);

  return (
    <NavDropdown
      className="text-uppercase"
      show={show}
      title={
        <div className="nav-link__title">
          <MenuAlt1Icon className="me-2" />
          <span style={{ marginTop: "3px" }}>{!mobileView && `${t(title)}`}</span>
        </div>
      }
      onMouseEnter={showDropdown}
      onClick={() => setShow(!show)}
      onMouseLeave={hideDropdown}
      aria-label="dropdown"
    >
      {/* desktop-view for navbar dropdown items */}
      {navItemJson.map((item) => (
        <DropdownItem
          key={item.id}
          link={item.link}
          title={item.title}
          icon={item.icon}
          // activeClassName={item.className}
          aria-label={item.ariaLabel}
        />
      ))}
      {/* {paid && ( */}
      {/*   <DropdownItem link="/profile" title="Purchase" icon={<SettingIcon size={"1.2em"} />} /> */}
      {/* )} */}
      {/* mobile-view for navbar dropdown items */}
      {mobileView && (
        <>
          <DropdownItem link="/profile" title="Profile" icon={<SettingIcon size={"1.2em"} />} />
          <DropdownItem link="/settings" title="Settings" icon={<SettingIcon size={"1.2em"} />} />
          <DropdownItem
            link="#"
            title="logout"
            icon={<LogoutIcon size={"1.2em"} />}
            onClick={handleLogout}
          />
        </>
      )}
    </NavDropdown>
  );
}

export const DropdownItem = ({ title, link, icon, onClick }) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  return (
    <NavLink
      to={link}
      className={`dropdown-link ${hover ? "text-black" : "text-white"}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      // activeClassName="active"
    >
      <div style={{ width: "15px" }}>{icon}</div>
      {onClick && (
        <span onClick={onClick} style={{ marginTop: "3px" }}>
          {t(`${title}`)}
        </span>
      )}
      {!onClick && <span style={{ marginTop: "3px" }}>{t(`${title}`)}</span>}
    </NavLink>
  );
};
