import React from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ProjectProfile({ validation }) {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-item-center w-100 gap-2">
      <Form.Group>
        <Form.Label> {t("Project") + "  " + t("name")} </Form.Label>
        <Field className="form-control" name="name" validate={validation.nameValidation} />
      </Form.Group>
      {/* <Form.Group> */}
      {/*   <Form.Label> {t("Project") + "  " + t("start")} </Form.Label> */}
      {/*   <Field */}
      {/*     className="form-control" */}
      {/*     type="date" */}
      {/*     name="start" */}
      {/*     validate={validation.startValidation} */}
      {/*   /> */}
      {/* </Form.Group> */}
      {/* <Form.Group> */}
      {/*   <Form.Label> */}
      {/*     {" "} */}
      {/*     {t("Project") + "  " + t("duration") + " " + t("in") + " " + t("months")}{" "} */}
      {/*   </Form.Label> */}
      {/*   <Field */}
      {/*     className="form-control" */}
      {/*     type="number" */}
      {/*     name="duration_month" */}
      {/*     // validate={validation.durationValidation} */}
      {/*   /> */}
      {/* </Form.Group> */}
    </div>
  );
}

export default ProjectProfile;
