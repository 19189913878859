export default {
  formId: "userForm",
  formField: {
    firstName: {
      name: "first_name",
      label: "first_name",
    },
    lastName: {
      name: "last_name",
      label: "last_name",
    },
    email: {
      name: "email",
      label: "email",
    },
    password: {
      name: "password",
      label: "password",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "phone_number",
    },
    accountType: {
      name: "accountType",
      label: "account_type",
    },
    areas: {
      name: "areas",
      label: "areas",
    },
    zipCode: {
      name: "zipCode",
      label: "zip_code",
    },
    streetName: {
      name: "streetName",
      label: "street_name",
    },
    houseNumber: {
      name: "houseNumber",
      label: "house_number",
    },
    city: {
      name: "city",
      label: "city",
    },
    country: {
      name: "country",
      label: "country",
    },
  },
};
