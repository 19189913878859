import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import AddButton from "@components/Button/AddButton";
import Button from "@components/Button/Button";
import { getProject } from "@modules/Projects/services/projectCrud";
import { SelectItem } from "./utils";

function Area({ values, setFieldValue, setOpenAreaForm, setArea, addedArea }) {
  const { t } = useTranslation();
  const { pid } = useParams();
  const { data: project, refetch } = useQuery(["project", pid], () => getProject(pid), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { areas } = project || {};
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    if (addedArea) {
      refetch(); //  project needs to get refetched since it is not handled with invalidate area queries
      console.log("addedArea", addedArea);
      setDefaultValue({
        value: addedArea.id,
        label: addedArea.name,
        isFixed: true,
        color: "#00875A",
      });
      setFieldValue("areaId", addedArea.id);
    }
  }, [addedArea, setFieldValue]);

  const [selectItem, setSelectItem] = useState(null);

  useEffect(() => {
    setSelectItem(
      <SelectItem
        defaultValue={defaultValue}
        setFieldValue={setFieldValue}
        items={areas}
        values={values}
        type={"area"}
      />
    );
  }, [defaultValue, areas, values.areaId, setFieldValue]);

  return (
    <>
      <Form.Group className="flex-fill" controlId="formBasicEmail">
        <div>
          <h4>{t("explanation")}</h4>
          <p>{t("add_all_form.area.explanation")}</p>
        </div>
        <div>
          <h4>{t("add_all_form.area.new_area")}</h4>
          <AddButton
            label={t("area")}
            action={() => {
              setOpenAreaForm(true);
              setArea(true);
            }}
            disabled={"areaId" in values && values.areaId !== ""}
          />
        </div>
        <h4>{t("add_all_form.area.existing_area")}</h4>
        <div>
          {selectItem}
          <Button
            label={t("reset")}
            action={() => {
              setFieldValue("areaId", "");
            }}
            disabled={!("areaId" in values && values.areaId !== "")}
          />
        </div>
      </Form.Group>
    </>
  );
}

export default Area;
