function validateEmail(value) {
  let error;
  if (!value) {
    error = "validation.email_is_required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "validation.invalid_email_address";
  }
  return error;
}
function validatePassword(value) {
  let error;
  if (!value) {
    error = "validation.password_is_required";
  }
  return error;
}

export const validation = {
  validateEmail,
  validatePassword,
};
