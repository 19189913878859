import axiosInstance, { onRequestError } from "@helper/interceptor";

import { store } from "../../../redux/store";

export const getPublicTree = async (tid) => {
  const response = await axiosInstance.get(`/public/trees/${tid}`);
  return response.data;
};
export const getTree = async (tid) => {
  const response = await axiosInstance.get(`trees/${tid}`);
  return response.data;
};
export const getTrees = async () => {
  let response = await axiosInstance.get("trees");
  return response.data;
};
export const addTreeImage = async ({ form, tid }) => {
  axiosInstance.interceptors.request.handlers = [];
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  let response = await axiosInstance.post(`trees/${tid}/upload-image`, form);
  return response.data;
};
export const fetchTreeSpecies = async () => {
  const res = await axiosInstance.get(`tree-species/`);
  return res.data;
};
export const createTree = async (formData) => {
  let response = await axiosInstance.post("trees/", formData);
  return response.data;
};
export const addExistingTrees = async (formData) => {
  try {
    let response = await axiosInstance.patch(`add-trees-project/${formData.id}/`, formData);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateTree = async ({ formData, id }) => {
  let response = await axiosInstance.patch(`trees/${id}/`, formData);
  return response.data;
};
export const deleteTree = async (id) => {
  let response = await axiosInstance.delete(`trees/${id}`);
  return response.data;
};
// export const updateTreeAddSensors = async ({ formData, id }) => {
//   let response = await axiosInstance.patch(`trees-add-sensors/${id}/`, formData);
//   return response.data;
// };

const onRequest = (config) => {
  const states = store.getState();

  const accessToken = states.auth.authToken;
  config.headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "JWT " + accessToken,
  };
  return config;
};

export const uploadTree = async ({ formdata }) => {
  const onRequestOriginal = axiosInstance.interceptors.request.handlers[0].fulfilled;
  const onRequestErrorOriginal = axiosInstance.interceptors.request.handlers[0].rejected;
  axiosInstance.interceptors.request.handlers = [];
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  const res = await axiosInstance.post(`upload-csv`, formdata);
  axiosInstance.interceptors.request.handlers = [];
  axiosInstance.interceptors.request.use(onRequestOriginal, onRequestErrorOriginal);
  return res.data;
};
export const addTreeMaintenance = async ({ formdata, id }) => {
  const res = await axiosInstance.post(`trees/${id}/add-maintenance`, formdata);
  return res.data;
};

export const updateMaintenance = async ({ formdata, id }) => {
  const res = await axiosInstance.patch(`maintenances/${id}/`, formdata);
  return res.data;
};

export const deleteMaintenance = async (id) => {
  let response = await axiosInstance.delete(`maintenances/${id}`);
  return response.data;
};
export const addInstallation = async ({ formdata }) => {
  const res = await axiosInstance.post(`installations/`, formdata);
  return res.data;
};
export const deleteInstallation = async (id) => {
  const res = await axiosInstance.delete(`installations/${id}`);
  return res.data;
};

export const updateInstallation = async ({ formdata, id }) => {
  const res = await axiosInstance.patch(`installations/${id}/`, formdata);
  return res.data;
};

//fetch download data for download
export const fetchDownloadData = async ({ sensors, parameterQuery }) => {
  const res = await axiosInstance.get(`ts_data/${sensors}-${parameterQuery}/download`);
  return res.data;
};
//fetch download data for download
export const fetchTSDataByDate = async (props) => {
  const res = await axiosInstance.get(
    `influx-data/?start=${props.startDate}&stop=${props.endDate}
	  &interval=${props.interval}&treeSensors=${props.tsps}&field=${props.field}`
  );
  return res.data;
};
export const fetchTSDataMeanByDate = async (props) => {
  // const res = await axiosInstance.get(
  //   `data/${props.tsps}-${props.dragItemLeft}/${props.startDate}:${props.endDate}/mean`
  // );
  const res = await axiosInstance.get(
    `influx-data/?start=${props.startDate}&stop=${props.endDate}
	  &interval=1d&treeSensors=${props.tsps}&field=${props.field}`
  );
  return res.data;
};

// fetch sensor-data by date
export const fetchRainDataByDate = async (props) => {
  const res = await axiosInstance.get(
    `weather/${props.id}/rain/${props.startDate}:${props.endDate}`
  );
  return res.data;
};

export const fetchTreeHealthByDate = async (props) => {
  const res = await axiosInstance.get(
    `health_sensor/${props.id}/${props.startDate}:${props.endDate}`
    // `health_sensor/${props.id}/${props.startDate}:${props.endDate}`
  );
  return res.data;
};

export const getTreeImages = async (treeId) => {
  let response = await axiosInstance.get(`tree-images/${treeId}`);
  return response.data;
};
