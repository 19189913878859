import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ExportData } from "./ExportSensorData";
import { Button, Form, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { DownloadIcon, FileExportIcon, CloseOutlineIcon } from "@components/icons";

function ExportSensor({ name, sensors }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // const [checkedState, setCheckedState] = useState(-1);
  //
  let noPulse = sensors.every((sensor) => sensor.pulse_sensor == null);
  let noSoilMoisture = sensors.every((sensor) => sensor.soil_moisture_sensor == null);
  let noSoilWaterTension = sensors.every((sensor) => sensor.soil_water_tension_sensor == null);
  // const sensorType

  //reset all state on model close
  const handleClose = () => {
    // setCheckedState(-1);
    setSuccessMessage("");
    setShow(false);
  };

  function toggleParameter(id, checked) {
    setParameters((prevParams) => {
      // Calculate the updated parameters
      const updatedParams = prevParams.map((param) =>
        param.id === +id ? { ...param, checked: checked } : param
      );

      // Calculate numChecked based on updatedParams
      // const numChecked = updatedParams.filter((param) => param.checked).length;

      // Update the other state based on numChecked
      // if (numChecked > 0) {
      //  setCheckedState(numChecked);
      // } else {
      //  setCheckedState(-1);
      // }

      return updatedParams; // return the updated parameters to update the state
    });
    // setParameters((prevParams) =>
    //   prevParams.map((param) => (param.id === +id ? { ...param, checked: checked } : param))
    // );
    // const numChecked = parameters.filter((param) => param.checked).length;
    // if (numChecked > 0) {
    //   setCheckedState(numChecked);
    // } else {
    //   setCheckedState(-1);
    // }
  }
  //this function is used for setting the state for download variable
  const downloadParameter = (e) => {
    const { id, value, checked } = e.target;
    // console.log("E", value, checked);
    toggleParameter(id, checked);
  };
  const checkedAll = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      for (let i = 0; i < parameters.length; i++) {
        console.log(i, parameters[i]);
        toggleParameter(parameters[i].id, true);
      }
      // setCheckedState(eval(value));
    }
    if (checked == false) {
      for (let i = 0; i < parameters.length; i++) {
        console.log(i, parameters[i]);
        toggleParameter(parameters[i].id, false);
      }
      // setCheckedState(-1);
    }
  };

  const [parameters, setParameters] = useState([
    { id: 1, name: "resistance", checked: false, active: noPulse ? false : true },
    { id: 2, name: "temperature", checked: false, active: true },
    { id: 3, name: "resis_temp_comp", checked: false, active: noPulse ? false : true },
    { id: 4, name: "moisture_content", checked: false, active: noPulse ? false : true },
    { id: 5, name: "water_soil", checked: false, active: noSoilMoisture ? false : true },
    { id: 6, name: "dendrometer", checked: false, active: false },
    {
      id: 7,
      name: "soil_water_tension",
      checked: false,
      active: noSoilWaterTension ? false : true,
    },
  ]);

  let noneChecked = parameters.every((param) => param.checked == false);
  let allChecked = parameters.every((param) => param.checked == true);

  return (
    <>
      <Button variant="outline-primary" className="clickable" onClick={() => setShow(true)}>
        {t("export")}
        <FileExportIcon />
      </Button>

      <Modal onHide={handleClose} show={show} centered>
        <ModalHeader closeButton>{t("download_sensor_data")}</ModalHeader>
        <ModalBody>
          {successMessage && (
            <div className="popup-success">
              <span>{successMessage}</span>
              <CloseOutlineIcon onClick={() => setSuccessMessage("")} />
            </div>
          )}
          <label htmlFor="">{"Tick (✔️) the checkbox to download data."} &nbsp; </label>
          <Form className="my-3">
            <div className="d-flex align-items-center gap-2">
              <input
                type="checkbox"
                id="custom-switch"
                value={14}
                onChange={checkedAll}
                checked={allChecked}
              />
              <label>{t("All")}</label>
            </div>
            {parameters.map((param, index) => {
              if (param.active) {
                return (
                  <div className="d-flex align-items-center gap-2" key={index}>
                    <input
                      type="checkbox"
                      id={param.id}
                      value={param.name}
                      onChange={downloadParameter}
                      checked={param.checked}
                    />
                    <label>{t(param.name)}</label>
                  </div>
                );
              }
            })}
          </Form>
          {noneChecked ? (
            <DisabledButton />
          ) : (
            <ExportData
              // sensors={serials}
              sensors={sensors}
              fileName={name}
              parameters={parameters}
              setSuccessMessage={setSuccessMessage}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

const DisabledButton = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex gap-3 align-items-center ">
      <Button variant="outline-primary d-flex align-items-center" className="disabled">
        <>
          <DownloadIcon />
          {t("Download")}
        </>
      </Button>
    </div>
  );
};

export default ExportSensor;
