import React from "react";
import PropTypes from "prop-types";
import getDate from "@helper/getDate";
import { useTranslation } from "react-i18next";
import { getDayAfterTommorowWeather, getTodayWeather, getTommorowWeather } from "@helper/weather";
import { SunIcon, CloudIcon, CloudRainIcon } from "@components/icons";

const PartlySunnyIcon = () => {
  return (
    <>
      <svg
        viewBox="0 0 512 512"
        width="1.2rem"
        aria-hidden="true"
        focusable="false"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className="StyledIconBase-ea9ulj-0 lbJwfL sc-eKBdFk gDNKlX"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth="32"
          d="M90.61 306.85A16.07 16.07 0 00104 293.6C116.09 220.17 169.63 176 232 176c57.93 0 96.62 37.75 112.2 77.74a15.84 15.84 0 0012.2 9.87c50 8.15 91.6 41.54 91.6 99.59 0 59.4-48.6 100.8-108 100.8H106c-49.5 0-90-24.7-90-79.2 0-48.47 38.67-72.22 74.61-77.95z"
        ></path>
        <path
          stroke="#fbc450"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="32"
          d="M384.8 271.4a80 80 0 10-123.55-92M464 208h32M336 48v32M222.86 94.86l22.63 22.63m203.65-22.63l-22.63 22.63"
          style={{ fill: "#fbc450" }}
          fill="#fff"
        ></path>
      </svg>
    </>
  );
};

export default function Weather({ weather, iconWidth }) {
  const { t } = useTranslation();
  const day = getDate();
  if (weather) {
    return (
      <div className="projectWeather d-flex align-items-center">
        <h6 className="mb-0 text-uppercase page-title fw-bold">{t("weather")} </h6>
        <div className="projectStatus statusCloud ">
          <WeatherToday weather={weather} iconWidth={iconWidth} />
          <div>
            <p className="mb-0 ">{day.today}</p>
            <p className="mb-0 ">
              {weather && weather.temperature ? weather.temperature.today : ""}
              &#8451;
            </p>
          </div>
        </div>
        <div className="projectStatus statusRain ">
          <WeatherTommorow weather={weather} iconWidth={iconWidth} />
          <div>
            <p className="mb-0 ">{day.tomorrow}</p>
            <p className="mb-0 ">
              {weather && weather.temperature ? weather.temperature.tomorrow : ""} &#8451;
            </p>
          </div>
        </div>
        <div className="projectStatus statusSun">
          <WeatherDayAfterTommorow weather={weather} iconWidth={iconWidth} />
          <div>
            <p className="mb-0 ">{day.dayAfterTomorrow}</p>
            <p className="mb-0 ">
              {weather && weather.temperature ? weather.temperature.day_after_tomorrow : ""}
              &#8451;
            </p>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
export const WeatherToday = ({ weather, iconWidth }) => {
  let weatherStatus = getTodayWeather(weather);
  return (
    <>
      {
        {
          0: <SunIcon width={iconWidth} />,
          1: <PartlySunnyIcon />,
          2: <CloudIcon width={iconWidth} />,
          3: <CloudRainIcon width={iconWidth} />,
        }[weatherStatus]
      }
    </>
  );
};
export const WeatherTommorow = ({ weather, iconWidth }) => {
  let weatherStatus = getTommorowWeather(weather);

  return (
    <>
      {
        {
          0: <SunIcon width={iconWidth} />,
          1: <PartlySunnyIcon />,
          2: <CloudIcon width={iconWidth} />,
          3: <CloudRainIcon width={iconWidth} />,
        }[weatherStatus]
      }
    </>
  );
};
export const WeatherDayAfterTommorow = ({ weather, iconWidth }) => {
  let weatherStatus = getDayAfterTommorowWeather(weather);

  return (
    <>
      {
        {
          0: <SunIcon width={iconWidth} />,
          1: <PartlySunnyIcon />,
          2: <CloudIcon width={iconWidth} />,
          3: <CloudRainIcon width={iconWidth} />,
        }[weatherStatus]
      }
    </>
  );
};

Weather.propTypes = {
  weather: PropTypes.shape({
    dow: PropTypes.number,
    lat: PropTypes.string,
    lng: PropTypes.string,
    rain: PropTypes.shape({
      day_after_tomorrow: PropTypes.number,
      today: PropTypes.number,
      tomorrow: PropTypes.number,
    }),
    sun: PropTypes.shape({
      day_after_tomorrow: PropTypes.number,
      today: PropTypes.number,
      tomorrow: PropTypes.number,
    }),
    temperature: PropTypes.shape({
      day_after_tomorrow: PropTypes.number,
      today: PropTypes.number,
      tomorrow: PropTypes.number,
    }),
  }),
  iconWidth: PropTypes.string,
};
