import FilterBar from "./filters";
import { useQuery } from "react-query";
import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { Resizable } from "re-resizable";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Weather } from "@modules/Weather";
import GraphContainer from "./GraphContainer";
import Variables from "./variables/Variables";
import Filter from "./variables/Filter";
import { Container, Row, Col } from "react-bootstrap";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { getProject } from "@modules/Projects/services/projectCrud";
import { ViewTypeButton } from "@modules/Projects/pages/ProjectDetail/Button";
import DataScienceProvider from "./context/DataScienceContext";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";

function DataScience() {
  const { pid } = useParams();
  const [height, setHeight] = useState(550);
  const [width, setWidth] = useState("100%");
  const { mobile } = useSelector((state) => state.mobile);

  const { data: project } = useQuery(["ds_project", pid], () => getProject(pid), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!pid,
  });

  return (
    <DataScienceProvider>
      <Breadcrumb
        title={project && project.name}
        weather={<Weather weather={project?.weather} iconWidth={"1.2rem"} />}
        viewType={<ViewTypeButton id={pid} />}
      />
      <Container className="ds">
        <DndProvider backend={mobile ? TouchBackend : HTML5Backend}>
          <Row className="g-0">
            <Col
              lg={2}
              style={{
                borderRight: "1px solid #eee",
                height: !mobile && "calc(100vh - 150px)",
                overflowY: "scroll",
              }}
            >
              <FilterBar />
            </Col>
            <Col lg={10}>
              <Variables />
              <Row className={"g-0"}>
                <Col lg={12}>
                  <Resizable
                    size={{ width, height }}
                    onResizeStop={(e, direction, ref, d) => {
                      setWidth(width + d.width);
                      setHeight(height + d.height);
                    }}
                    minHeight={300}
                    enable={{
                      top: false,
                      right: false,
                      bottom: true,
                      left: false,
                      topRight: false,
                      bottomRight: false,
                      bottomLeft: false,
                      topLeft: false,
                    }}
                    maxHeight={"70vh"}
                    handleClasses="handlebar"
                    handleComponent={<>ok</>}
                    handleStyles={{
                      bottom: {
                        marginTop: -7,
                        marginLeft: -5,
                        top: "0%",
                        left: "0%",
                        width: 0,
                        height: 0,
                        boxSizing: "border-box",
                        zIndex: 1,
                        background: "#ccc",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    <GraphContainer />
                    <Filter />
                  </Resizable>
                </Col>
              </Row>
            </Col>
          </Row>
        </DndProvider>
      </Container>
    </DataScienceProvider>
  );
}

export default DataScience;
