const nameValidation = (value) => {
  let error;
  if (!value) {
    error = "Project name is required";
  }
  return error;
};
const startValidation = (value) => {
  let error;
  if (!value) {
    error = "Project start is required";
  }
  return error;
};
const polygonValidation = (value) => {
  let error;
  if (!value) {
    error = "Area is required";
  }
  return error;
};

export const validation = {
  nameValidation,
  polygonValidation,
  startValidation,
};
