import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import IrrigationAreaDetail from "./IrrigationAreaDetail";
import { ViewTypeButton } from "../Button";
import { getProject } from "@modules/Projects/services/projectCrud";
import Spinner from "@components/Spinner/Spinner";
import ServerError from "@components/Error/ServerError";
import { Breadcrumb } from "@components/Breadcrumb";
import MapViewTreeList from "./MapViewTreeList";
import { Weather } from "@modules/Weather";
import AddButton from "@components/Button/AddButton";
import AddAllForm from "@modules/Projects/components/Form/AddAllForm";
import TreeDetail from "@modules/Trees/components/TreeDetail";
import { AngleRightIcon } from "@components/icons";

function IrrigationAreaPage() {
  const { mobile } = useSelector((state) => state.mobile);
  const { pid } = useParams();
  const [trees, setTrees] = useState([]);
  const [openChildModal, setOpenChildModal] = useState(false);
  const [selectedParentTree, setSelectedParentTree] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [selectedTree, setSelectedTree] = useState();
  const [openAllForm, setOpenAllForm] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openTreePopup, setOpenTreePopup] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const tid = query.get("selectedTree");
  const lng = query.get("lng");
  const lat = query.get("lat");
  const aId = query.get("area");
  const { t } = useTranslation();
  const [map, setMap] = useState();
  //retrieving all sensors list
  const {
    data: project,
    isLoading,
    isError,
  } = useQuery(["project", pid], () => getProject(pid), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
    refetchOnMount: false,
    onSuccess: (project) => {
      const { areas } = project;
      let a = areas
        .map((area) =>
          area.tree_set.map((tree) => {
            tree["area"] = area.name;
            return tree;
          })
        )
        .flat();
      setTrees(a);
    },
  });

  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError) return <ServerError />;
  const { centroid } = project;
  const mapCenter = centroid ? [centroid.lat, centroid.lng] : [48.1558269, 11.5421791];
  return (
    <>
      {mobile ? (
        <Breadcrumb
          title={project.name}
          weather={<Weather weather={project.weather} iconWidth={"1.2rem"} />}
          addButton={<AddButton label={"button.add_all"} action={() => setOpenAllForm(true)} />}
          viewType={<ViewTypeButton id={pid} />}
        />
      ) : (
        <Breadcrumb
          title={project.name}
          weather={<Weather weather={project.weather} iconWidth={"1.2rem"} />}
          addButton={<AddButton label={"button.add_all"} action={() => setOpenAllForm(true)} />}
          viewType={<ViewTypeButton id={pid} />}
        />
      )}
      <Container>
        <Row>
          {openList && (
            <Col lg="3">
              <MapViewTreeList
                trees={trees}
                areas={project.areas}
                selectedArea={selectedArea}
                openChildModal={openChildModal}
                setSelectedArea={setSelectedArea}
                setOpenChildModal={setOpenChildModal}
                selectedParentTree={selectedParentTree}
                setSelectedParentTree={setSelectedParentTree}
                setSelectedTree={setSelectedTree}
                selectedTree={selectedTree}
                tid={tid}
                aId={aId}
                setOpenTreePopup={setOpenTreePopup}
                setOpenList={setOpenList}
                centroid={centroid}
                map={map}
              />
            </Col>
          )}
          {!openList && (
            <Col lg="1">
              <h5>
                <Button
                  onClick={() => {
                    setOpenList(true);
                    if (centroid) map.flyTo([centroid.lat, centroid.lng]);
                  }}
                >
                  {t("areas")} <AngleRightIcon />
                </Button>
              </h5>
            </Col>
          )}
          <IrrigationAreaDetail
            setTrees={setTrees}
            selectedTree={selectedTree}
            mapCenter={mapCenter}
            setMap={setMap}
            project={project}
            tid={tid}
            lat={lat}
            lng={lng}
            aId={aId}
            setOpenTreePopup={setOpenTreePopup}
            openTreePopup={openTreePopup}
            setSelectedTree={setSelectedTree}
            openList={openList}
            setSelectedParentTree={setSelectedParentTree}
          />
          <Col lg="5">
            {openTreePopup && map && (
              <div>
                <TreeDetail
                  treeId={openTreePopup.id}
                  pid={pid}
                  aid={aId}
                  type={"map"}
                  setOpenTreeDetail={setOpenTreePopup}
                  map={map}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <AddAllForm
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        show={openAllForm}
        setOpenForm={setOpenAllForm}
        pid={pid}
      />
    </>
  );
}
export default IrrigationAreaPage;
