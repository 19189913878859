import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Button, Form, Modal, ModalBody, ModalHeader } from "react-bootstrap";

import { createIrrigationArea, updateIrrigationArea } from "../../services/areaCrud";
import { validation } from "./FormModel/validationSchema";
import { AreaName, ParentChildTree, TreeNoSensor } from "./steps";
import { styles } from "@components/styles";
import { getAllCustomers } from "@modules/Customer/services/customerCrud";

const stepAdd = ["areaName"];
const stepEdit = ["areaName", "parentChildTree", "treeNoSensor"];

const renderStepAdd = (steps) => {
  switch (steps) {
    case 0:
      return <AreaName validation={validation} />;
    case 1:
      return <>Not Found</>;
  }
};
const renderStepEdit = (steps, values, setFieldValue, parentOption, childOption) => {
  switch (steps) {
    case 0:
      return <AreaName validation={validation} />;

    case 1:
      return (
        <ParentChildTree
          values={values}
          setFieldValue={setFieldValue}
          parentOption={parentOption}
          childOption={childOption}
        />
      );

    case 2:
      return (
        <TreeNoSensor values={values} setFieldValue={setFieldValue} childOption={childOption} />
      );

    case 3:
      return <>Not Found</>;
  }
};

function IrrigationAreaForm({
  show,
  isUpdate,
  setIsUpdate,
  setOpenForm,
  selectedArea,
  detail,
  pid,
  setAreaValues,
}) {
  const [childOption, setChildOption] = useState([]);
  const [parentOption, setParentOption] = useState([]);
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = isUpdate
    ? activeStep === stepEdit.length - 1
    : activeStep === stepAdd.length - 1;
  const queryClient = useQueryClient();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("selectedArea");
  useQuery("customers", getAllCustomers, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setChildOption(data[0].possible_child_trees);
      setParentOption(data[0].possible_parent_trees);
    },
  });

  const handleClose = () => {
    setIsUpdate && setIsUpdate(false);
    setOpenForm(false);
    setActiveStep(0);
  };

  const { isLoading, mutateAsync } = useMutation(
    isUpdate ? "updateArea" : "createArea",
    isUpdate ? updateIrrigationArea : createIrrigationArea,
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries("areas");
        id && queryClient.invalidateQueries(["areas", id]);
        setAreaValues ? setAreaValues(data) : [];
        handleClose();
      },
    }
  );

  const handleSubmit = (values) => {
    !isLastStep && setActiveStep(activeStep + 1);
    if (isLastStep) {
      isUpdate ? mutateAsync({ formData: values, id: selectedArea.id }) : mutateAsync(values);
    }
  };

  return (
    <Modal onHide={handleClose} show={show} centered>
      <ModalHeader closeButton>
        <h5 className="mb-0">{isUpdate ? `Update ${selectedArea.name}` : `${t("add_area")} `}</h5>
      </ModalHeader>
      <ModalBody>
        <div className={styles.wizardList}>
          {isUpdate
            ? stepEdit.map((step, i) => (
                <span key={step} className={activeStep == i ? "wizard-active" : ""}>
                  {t(step)}
                </span>
              ))
            : stepAdd.map((step, i) => (
                <span key={step} className={activeStep == i ? "wizard-active" : ""}>
                  {t(step)}
                </span>
              ))}
        </div>
        <Formik
          initialValues={
            isUpdate
              ? {
                  name: detail.name,
                  project: { id: detail.project.id },
                  trees: detail && detail.trees ? detail.trees : [],
                }
              : { name: "", project: {}, trees: [] }
          }
          onSubmit={(values) => {
            let createData = {
              name: values.name,
              project: { type: "Project", id: pid },
            };
            let updateData = {
              name: values.name,
              project: { type: "Project", id: pid },
              trees: values.trees,
            };

            isUpdate ? handleSubmit(updateData) : handleSubmit(createData);
          }}
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {
            return (
              <>
                <span className="text-danger">{errors && errors.name}</span>
                <Form onSubmit={handleSubmit}>
                  <div
                    className="form-container"
                    style={{
                      maxHeight: "360px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {isUpdate
                      ? renderStepEdit(activeStep, values, setFieldValue, parentOption, childOption)
                      : renderStepAdd(activeStep, values, setFieldValue)}
                  </div>
                  <div className={styles.formBody}>
                    {activeStep !== 0 && (
                      <Button
                        type="button"
                        variant=" btn-outline-primary"
                        onClick={() => setActiveStep((step) => step - 1)}
                      >
                        {t("button.back")}
                      </Button>
                    )}

                    <button type="submit" className="btn btn-outline-primary ms-auto">
                      {isLastStep
                        ? isUpdate
                          ? isLoading
                            ? t("button.updating")
                            : t("button.update")
                          : isLoading
                          ? t("button.saving")
                          : t("button.save")
                        : t("button.next")}
                    </button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
}

export default IrrigationAreaForm;
