export const colors = [
  [0, "#f11d28"],
  [0.166667, "#fd3a2d"],
  [0.333333, "#fe612c"],
  // [0.3, "#ff782c"],
  [0.5, "#ff922c"],
  // [0.45, "#ffa62c"],
  [0.666667, "#ffc42c"],
  //  [0.55, "#ffd92f"],
  // [0.6, "#ffee2c"],
  // [0.45, "#feff2c"],
  // [0.5, "#f7ff2c"],
  // [0.55, "#faff2c"],
  // [0.6, "#f9ff2c"],
  // [0.65, "#f8ff2c"],
  // [0.7, "#ecff2c"],
  [0.833333, "#ccff2c"],
  // [0.8, "#a9ff2c"],
  // [0.85, "#93ff2c"],
  // [0.9, "#93ff2c"],
  // [0.9, "#59ff43"],
  [1, "#2cff32"],
];

export const colorsPulse = (blue_threshold = 100, green_threshold = 70, yellow_threshold = 50) => {
  const colors = [
    [yellow_threshold / (2 * 100), "#f11d28"],
    // [0.3, "#fd3a2d"],
    [yellow_threshold / 100, "#fe612c"],
    // [0.3, "#ff782c"],
    [(yellow_threshold + (green_threshold - yellow_threshold) / 2) / 100, "#ff922c"],
    // [0.45, "#ffa62c"],
    [green_threshold / 100, "#ffc42c"],
    //  [0.55, "#ffd92f"],
    // [0.6, "#ffee2c"],
    // [0.45, "#feff2c"],
    // [0.5, "#f7ff2c"],
    // [0.55, "#faff2c"],
    // [0.6, "#f9ff2c"],
    // [0.65, "#f8ff2c"],
    // [0.7, "#ecff2c"],
    [(green_threshold + (blue_threshold - green_threshold) / 2) / 100, "#ccff2c"],
    // [0.8, "#a9ff2c"],
    // [0.85, "#93ff2c"],
    // [0.9, "#93ff2c"],
    // [0.9, "#59ff43"],
    [blue_threshold / 100, "#2cff32"],
  ];
  if (blue_threshold !== 100) {
    colors.push([1, "#0055FF"]);
  }
  return colors;
};

export const colorsSoil = [
  [0, "#f11d28"],
  [0.1, "#fd3a2d"],
  [0.2, "#fe612c"],
  // [0.3, "#ff782c"],
  [0.3, "#ff922c"],
  [0.4, "#ffa62c"],
  // [0.5, "#ffc42c"],
  // [0.55, "#ffd92f"],
  // [0.6, "#ffee2c"],
  // [0.45, "#feff2c"],
  // [0.5, "#f7ff2c"],
  // [0.55, "#faff2c"],
  // [0.6, "#f9ff2c"],
  // [0.65, "#f8ff2c"],
  // [0.7, "#ecff2c"],
  // [0.5, "#ccff2c"],
  // [0.6, "#a9ff2c"],
  [0.5, "#93ff2c"],
  // [0.9, "#93ff2c"],
  [0.6, "#59ff43"],
  [0.7, "#2cff32"],
  [0.8, "#0099FF"],
  [0.9, "#0055FF"],
  [1, "#0000FF"],
];

export const colorsSoilMoisture = (pwp, max_nf, max_f) => {
  pwp = pwp ? pwp / 100 : 0.1;
  max_nf = max_nf ? max_nf / 100 : 0.25;
  max_f = max_f ? max_f / 100 : 0.45;
  const first = pwp;
  const second = pwp + ((max_nf / 6) * (max_nf - pwp)) / max_nf;
  const third = pwp + (((2 * max_nf) / 6) * (max_nf - pwp)) / max_nf;
  const fourth = pwp + (((3 * max_nf) / 6) * (max_nf - pwp)) / max_nf;
  const fifth = pwp + (((4 * max_nf) / 6) * (max_nf - pwp)) / max_nf;
  const sixth = pwp + (((5 * max_nf) / 6) * (max_nf - pwp)) / max_nf;
  const seventh = max_nf;
  const eigth = max_nf + ((max_f / 2) * (max_f - max_nf)) / max_f;
  const nineth = max_f;

  return [
    [0, "#f11d28"],
    [first, "#fd3a2d"],
    [second, "#fe612c"],
    // [0.3, "#ff782c"],
    [third, "#ff922c"],
    [fourth, "#ffa62c"],
    // [0.5, "#ffc42c"],
    // [0.55, "#ffd92f"],
    // [0.6, "#ffee2c"],
    // [0.45, "#feff2c"],
    // [0.5, "#f7ff2c"],
    // [0.55, "#faff2c"],
    // [0.6, "#f9ff2c"],
    // [0.65, "#f8ff2c"],
    // [0.7, "#ecff2c"],
    // [0.5, "#ccff2c"],
    // [0.6, "#a9ff2c"],
    [fifth, "#93ff2c"],
    // [0.9, "#93ff2c"],
    [sixth, "#59ff43"],
    [seventh, "#2cff32"],
    [eigth, "#0099FF"],
    [nineth, "#0055FF"],
    [1, "#000000"],
  ];
};

export const colorsSoilWaterTension = () => {
  // pwp = -1585
  // max_nf = -6
  // pwp = pwp ? pwp / 100 : 0.1;
  // max_nf = max_nf ? max_nf / 100 : 0.25;
  // max_f = max_f ? max_f / 100 : 0.45;
  // const first = pwp;
  // const second = pwp + ((max_nf / 6) * (max_nf - pwp)) / max_nf;
  // const third = pwp + (((2 * max_nf) / 6) * (max_nf - pwp)) / max_nf;
  // const fourth = pwp + (((3 * max_nf) / 6) * (max_nf - pwp)) / max_nf;
  // const fifth = pwp + (((4 * max_nf) / 6) * (max_nf - pwp)) / max_nf;
  // const sixth = pwp + (((5 * max_nf) / 6) * (max_nf - pwp)) / max_nf;
  // const seventh = max_nf;
  // const eigth = max_nf + ((max_f / 2) * (max_f - max_nf)) / max_f;
  // const nineth = max_f;

  return [
    // [-2000, "#"],
    [-1585, "#f11d28"],
    [-199, "#fd3a2d"],
    [-120, "#fe612c"],
    // [0.3, "#ff782c"],
    // [-32, "#ff922c"],
    [-60, "#ffa62c"],
    // [0.5, "#ffc42c"],
    // [0.55, "#ffd92f"],
    // [0.6, "#ffee2c"],
    // [0.45, "#feff2c"],
    // [0.5, "#f7ff2c"],
    // [0.55, "#faff2c"],
    // [0.6, "#f9ff2c"],
    // [0.65, "#f8ff2c"],
    // [0.7, "#ecff2c"],
    [-32, "#ccff2c"],
    // [0.6, "#a9ff2c"],
    // [, "#93ff2c"],
    // [0.9, "#93ff2c"],
    // [sixth, "#59ff43"],
    [-6, "#2cff32"],
    [-3, "#0099FF"],
    [0, "#0055FF"],
  ];
  // [20y00, "#000000"],
  // return [
  //   [2000, "#f11d28"],
  //   [1585, "#fd3a2d"],
  //   [199, "#fe612c"],
  //   // [0.3, "#ff782c"],
  //   // [-32, "#ff922c"],
  //   [100, "#ffa62c"],
  //   // [0.5, "#ffc42c"],
  //   // [0.55, "#ffd92f"],
  //   // [0.6, "#ffee2c"],
  //   // [0.45, "#feff2c"],
  //   // [0.5, "#f7ff2c"],
  //   // [0.55, "#faff2c"],
  //   // [0.6, "#f9ff2c"],
  //   // [0.65, "#f8ff2c"],
  //   // [0.7, "#ecff2c"],
  //   [32, "#ccff2c"],
  //   // [0.6, "#a9ff2c"],
  //   // [, "#93ff2c"],
  //   // [0.9, "#93ff2c"],
  //   // [sixth, "#59ff43"],
  //   [6, "#2cff32"],
  //   [3, "#0099FF"],
  //   [0, "#0055FF"],
  //   // [20y00, "#000000"],
  // ];
  // return [
  //   [-1585 / -2000, "#808080"], // grey
  //   [-199 / -2000, "#FF0000"], // red
  //   [-32 / -2000, "#FFFF00"], // yellow
  //   [-6 / -2000, "#008000"], // green
  //   [0, "#0000FF"], // blue
  // ];
};

export const colorsBiodiversity = [
  [0, "#f7ff2c"],
  [0.1, "#faff2c"],
  [0.2, "#f9ff2c"],
  [0.3, "#f8ff2c"],
  [0.4, "#ecff2c"],
  [0.5, "#ccff2c"],
  [0.6, "#a9ff2c"],
  [0.7, "#93ff2c"],
  [0.8, "#93ff2c"],
  [0.9, "#59ff43"],
  [1, "#2cff32"],
];
export const findClosest = function (x, arr) {
  // NOTE: it acually searches for the idx which is closest but higher
  const indexArr = arr.map(function (k) {
    return Math.abs(k[0] - x);
  });
  const min = Math.min.apply(Math, indexArr);
  // return arr[indexArr.indexOf(min)];
  let idx = indexArr.indexOf(min);
  if (arr[idx][0] < x && idx < arr.length - 1) {
    idx = idx + 1;
  }
  const idx_value = arr[idx];
  return idx_value;
};

export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export const WHITE = "#EAEAEA";
export const GREY = "#808080";
export const GREEN = "#A3CF6C";
export const DARKGREEN = "#006400";
export const YELLOW = "#FFBA4A";
export const RED = "#EF5C5C";
export const BLUE = "#008CDB";
