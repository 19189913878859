import ReactEcharts from "echarts-for-react";
import moment from "moment";

const SensorChart = ({ plot, style, dataZoom, type, plotTitle }) => {
  console.log(plot, style, dataZoom, type);
  const option = {
    grid: {
      bottom: 10,
      left: 0,
      right: 40,
      top: 40,
      containLabel: true,
    },

    tooltip: {
      trigger: "axis",
      backgroundColor: "#000",
      padding: 15,
      borderWidth: 0,
      textStyle: {
        color: "#fff",
        fontSize: 13,
      },
    },
    dataZoom: dataZoom,
    xAxis: [
      {
        type: "time",
        boundaryGap: false,
        axisPointer: {
          show: true,
          type: "line",
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          hideOverlap: true,
          formatter: function (value) {
            return moment.utc(value).format("DD.MM.YYYY");
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: plotTitle,
        nameTextStyle: {
          verticalAlign: "middle",
          padding: [0, 0, 40, 50],
        },
      },
    ],
    series: plot,
  };
  return <ReactEcharts option={option} style={style} className="line-chart" />;
};

export default SensorChart;
