import TreeList from "./TreeList.jsx";
import { useQuery } from "react-query";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Weather } from "@modules/Weather";
import { ViewTypeButton } from "../Button";
import { EmptyProject } from "./EmptyProject";
import SensorList from "./SensorList.jsx";
import { useTranslation } from "react-i18next";
import NoSelected from "@components/NoSelected";
import Spinner from "@components/Spinner/Spinner";
import "@modules/Projects/components/Project.css";
import { Breadcrumb } from "@components/Breadcrumb";
import AddButton from "@components/Button/AddButton";
import { useLocation, useParams } from "react-router-dom";
import ServerError from "@components/Error/ServerError";
import PageNotFound from "@components/Error/PageNotFound";
import SensorDetail from "@modules/Sensors/components/SensorDetail";
import TreeDetail from "@modules/Trees/components/TreeDetail";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { getProject } from "@modules/Projects/services/projectCrud";
import TreeListMobileView from "@modules/Trees/components/TreeListMobileView";
import IrrigationAreaList from "@modules/IrrigationArea/components/IrrigationAreaList";
import IrrigationAreaDetail from "@modules/IrrigationArea/components/IrrigationAreaDetail";
import IrrigationAreaForm from "@modules/IrrigationArea/components/Form/IrrigationAreaForm";
import AddAllForm from "@modules/Projects/components/Form/AddAllForm";

export default function ProjectDetailListView() {
  const { pid } = useParams();
  const [formValue, setFormValue] = useState();
  const [activeTab, setActiveTab] = useState("areas");
  const [isUpdate, setIsUpdate] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openImageForm, setOpenImageForm] = useState(false);
  const [openAllForm, setOpenAllForm] = useState(false);
  const [areas, setAreas] = useState();
  const [trees, setTrees] = useState();
  const [sensors, setSensors] = useState();
  const [detail, setDetail] = useState();
  const { t } = useTranslation();
  const { mobile } = useSelector((state) => state.mobile);
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("selectedArea");
  const tid = query.get("selectedTree");
  const sid = query.get("selectedSensor");
  const [aid, setAid] = useState();

  //fetch single project
  const {
    isLoading,
    data: project,
    isError,
    error,
  } = useQuery(["project", pid], () => getProject(pid), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      console.log("Data successfully fetched:", data);
      const { areas } = data;
      let trees = areas
        .map((area) =>
          area.tree_set.map((tree) => {
            tree["area"] = area.name;
            tree["areaId"] = area.id;
            return tree;
          })
        )
        .flat();
      setTrees(trees);
      setAreas(areas);
      const areasensors = areas
        .map((area) =>
          area.tree_set.map((tree) =>
            tree.installations.map((installation) => {
              const sensor = installation.sensor;
              sensor["tree"] = tree.name;
              return sensor;
            })
          )
        )
        .flat();
      setSensors(areasensors.flat());
    },
  });

  if (isLoading) return <Spinner height={"70vh"} />;
  if (isError && error.response && error.response.status === 404) return <PageNotFound />;
  if (isError) return <ServerError />;

  return (
    <>
      <Breadcrumb
        title={project?.name}
        weather={<Weather weather={project?.weather} iconWidth={"1.2rem"} />}
        addButton={<AddButton label={"button.add_all"} action={() => setOpenAllForm(true)} />}
        viewType={<ViewTypeButton id={pid} />}
      />

      <Container className="project-container">
        {/* {mobile ? ( */}
        {/*   <> */}
        {/*     {areas?.length === 0 ? ( */}
        {/*       <>{<EmptyProject width={150} />}</> */}
        {/*     ) : ( */}
        {/*       <TreeListMobileView trees={trees} /> */}
        {/*     )} */}
        {/*   </> */}
        {/* ) : ( */}
        <>
          <>
            <Tabs
              data-testid="tabs"
              defaultActiveKey="trees"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(e) => setActiveTab(e)}
            >
              <Tab eventKey="areas" title={t("areas")}>
                <Row>
                  <Col lg={7}>
                    {areas && (
                      <IrrigationAreaList
                        areas={areas}
                        setIsUpdate={setIsUpdate}
                        setDetail={setDetail}
                        setOpenForm={setOpenForm}
                      />
                    )}
                  </Col>
                  <Col lg={5}>
                    {!id ? (
                      <NoSelected text={"project_details.no_area_selected"} />
                    ) : (
                      <IrrigationAreaDetail />
                    )}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="trees" title={t("trees")}>
                <Row>
                  <Col lg={7}>
                    {trees && (
                      <TreeList
                        trees={trees}
                        setAid={setAid}
                        setOpenImageForm={setOpenImageForm}
                        openImageForm={openImageForm}
                      />
                    )}
                  </Col>
                  <Col lg={5}>
                    {!tid ? (
                      <NoSelected text={"project_details.no_tree_selected"} />
                    ) : (
                      <TreeDetail pid={pid} aid={aid} />
                    )}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="sensors" title={t("sensors")}>
                <Row>
                  <Col lg={7}>{sensors && <SensorList sensors={sensors} />}</Col>
                  <Col lg={5}>
                    {!sid ? (
                      <NoSelected text={"project_details.no_sensor_selected"} />
                    ) : (
                      <SensorDetail />
                    )}
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </>
        </>
        {/* )} */}
      </Container>
      <IrrigationAreaForm
        isUpdate={isUpdate}
        detail={detail}
        setIsUpdate={setIsUpdate}
        show={openForm}
        setOpenForm={setOpenForm}
        selectedArea={detail}
        pid={pid}
      />
      <AddAllForm
        isUpdate={isUpdate}
        detail={detail}
        setIsUpdate={setIsUpdate}
        show={openAllForm}
        setOpenForm={setOpenAllForm}
        pid={pid}
      />
    </>
  );
}
