import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";
import Swal from "sweetalert2";

import {
  BatteryFullIcon,
  BatteryHalfIcon,
  BatteryIcon,
  EditIcon,
  TrashIcon,
  NoWifi,
  SensorsOffIcon,
  BatteryWarningIcon,
  QuestionMarkIcon,
} from "@components/icons";
import { deleteSensor } from "../services/sensorCrud";
import ReactTable from "@components/ReactTable";
import StatusButton from "../../Trees/components/Button/StatusButton";
import { GREEN, YELLOW, RED, GREY } from "@helper/colors";
import useChoices from "@hooks/useChoices";

export function healthHeader(t) {
  return {
    Header: `${t("health_state")}`,
    accessor: "health_state",
    style: { width: "30px", textAlign: "center", paddingRight: "0" },
    Cell: (props) => {
      const [healthState] = useChoices("dry_state");

      const health_state = healthState?.filter(
        (item) => item.key == props.row.original.pulse_sensor?.health_state
      );
      let treeHealth = health_state && health_state[0] && health_state[0].value;
      return (
        <>
          {/* {props.row.original.health_state && ( */}
          <StatusButton
            status={props.row.original.pulse_sensor?.health_state}
            width="fit-content"
            padding="1px 10px"
          >
            {treeHealth ? `${t(treeHealth.replace(/\s/g, ""))}` : ""}
          </StatusButton>
          {/* )} */}
        </>
      );
    },
  };
}
export function statusHeader(t) {
  return {
    Header: `${t("state")}`,
    accessor: "sensor_state",
    style: { width: "30px", textAlign: "center", paddingRight: "0" },
    Cell: (props) => {
      const hardwareSerial = props.row.original.hardware_serial;
      const isSending = localStorage.getItem("isSending");
      const isSendingSensor = isSending ? isSending[hardwareSerial] : false;
      return (
        <>
          {
            {
              0: <SensorStatus color={GREEN} width="15px" title={t("working")} />, //working
              1: <BatteryWarningIcon color={RED} width="15px" title={t("battery_empty")} />, //Battery Empty
              2: <SensorsOffIcon color={YELLOW} width="15px" title={t("maintenance_needed")} />, //Maintenace Needed
              3: isSendingSensor ? (
                <SensorStatus color={GREEN} width="15px" title={t("working")} />
              ) : (
                <NoWifi color={GREY} width="15px" title={t("not_sending")} />
              ), //Not Sending
              4: <QuestionMarkIcon color={GREY} width="15px" title={t("unknown")} />,
            }[props.row.original.sensor_state]
          }
        </>
      );
    },
  };
}

export function batteryHeader(t) {
  return {
    Header: `${t("last_battery")}`,
    accessor: "battery",
    Cell: (props) => {
      const battery = props.row.original.battery;
      return (
        <small>
          {battery == undefined ? (
            <BatteryHalfIcon color="#888888" size={"1.3em"} />
          ) : battery > 60 ? (
            <BatteryFullIcon color="#19b7bf" size={"1.3em"} />
          ) : battery < 30 ? (
            <BatteryIcon color="#CD3100" size={"1.3em"} />
          ) : battery >= 30 && props.row.original.battery <= 60 ? (
            <BatteryHalfIcon color="#FEC400" size={"1.3em"} />
          ) : (
            <BatteryFullIcon color="#888888" size={"1.3em"} />
          )}
          &nbsp; {battery ? `${battery} %` : "?"}
        </small>
      );
    },
  };
}

export default function SensorList({ sensors, setOpenForm, setIsUpdate, setDetail }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const query = new URLSearchParams(location.search);
  const id = query.get("selectedSensor");

  const handleEdit = (sensorDetail) => {
    setIsUpdate(true);
    setOpenForm(true);
    setDetail(sensorDetail);
  };
  const { mutateAsync: sensorDelete } = useMutation(deleteSensor, {
    onSuccess: () => {
      queryClient.invalidateQueries("sensors");
      queryClient.invalidateQueries(["sensordetail", id]);
    },
  });

  const handleDelete = (id) => {
    Swal.fire({
      html: '<p className="text-center"> ' + "Are you sure you want to delete " + "?" + "</p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#388090",
      cancelButtonColor: "#EF3600",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        sensorDelete(id);
      }
    });
  };

  const columns = useMemo(
    () => [
      statusHeader(t),
      {
        Header: `${t("hardware_serial")}`,
        accessor: "hardware_serial",
      },

      {
        Header: `${t("name")}`,
        accessor: "name",
      },
      // healthHeader(t),
      // {
      //   Header: `${t("threshold")}`,
      //   accessor: "threshold",
      // },
      batteryHeader(t),
      {
        Header: `${t("actions")}`,
        accessor: "actions",
        style: { width: "80px", textAlign: "center" },
        disableSortBy: true,
        Cell: (props) => {
          return (
            <div className="text-center">
              <span title="Edit">
                <EditIcon
                  onClick={() => {
                    handleEdit(props.row.original);
                  }}
                />
              </span>
              <span title="Delete">
                <TrashIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    let id = props.row.original.id;
                    handleDelete(id);
                  }}
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );
  const onRowClicked = (row) => {
    const { id } = row.original;
    navigate(`/sensors?selectedSensor=${id}`);
  };

  return (
    <>
      <ReactTable
        columns={columns}
        data={sensors}
        onRowClicked={onRowClicked}
        activeId={id}
        pagesize={10}
        size={[10, 20, 30, 40, 50]}
      />
    </>
  );
}

export const SensorStatus = ({ color, title }) => {
  return (
    <span
      className={"badge"}
      style={{ padding: "5px", borderRadius: "2px", background: color }}
      title={`${title}`}
    >
      <div
        className="rounded-circle"
        style={{
          width: "6px",
          height: "6px",
          background: "#fff",
        }}
      ></div>
    </span>
  );
};
