import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mobile: false,
};

export const viewSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: {
    mobileView: (state) => {
      state.mobile = true;
    },
    desktopView: (state) => {
      state.mobile = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { mobileView, desktopView } = viewSlice.actions;

export default viewSlice.reducer;
