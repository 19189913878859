export default function useFilter({ filterTerm, filterItem }) {
  if (!filterTerm) {
    return filterItem;
  }
  var rtv = filterItem.filter(
    (item) => item.name && item.name.toLowerCase()?.includes(filterTerm.toLowerCase())
  );

  console.log("FILTWER", rtv);
  return rtv;
}
