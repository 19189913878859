import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import AddButton from "@components/Button/AddButton";
import Button from "@components/Button/Button";
import { useQuery } from "react-query";
import { getTrees } from "@modules/Trees/services/treeCrud";
import { SelectItem } from "./utils";

function Tree({ values, setFieldValue, setOpenTreeForm, setTree, addedTree }) {
  const { t } = useTranslation();
  const { data: trees } = useQuery("trees", getTrees, {
    refetchOnWindowFocus: false,
    retry: false,
  });
  let [defaultValue, setDefaultValue] = useState(null);
  useEffect(() => {
    if (addedTree) {
      setDefaultValue({
        value: addedTree.id,
        label: addedTree.name,
        isFixed: true,
        color: "#00875A",
      });
      setFieldValue("treeId", addedTree.id);
    }
  }, [addedTree, setFieldValue]);
  const [selectItem, setSelectItem] = useState(null);
  useEffect(() => {
    setSelectItem(
      <SelectItem
        defaultValue={defaultValue}
        setFieldValue={setFieldValue}
        items={trees}
        values={values}
        type={"tree"}
      />
    );
  }, [defaultValue, trees, values.treeId, setFieldValue]);

  return (
    <>
      <div>
        <h4> {t("explanation")} </h4>
        <p> {t("add_all_form.tree.explanation")} </p>
        <p></p>
      </div>
      <Form.Group className="flex-fill" controlId="formBasicEmail">
        <Form.Label>{t("add_all_form.tree.new_tree")}</Form.Label>
        <div>
          <AddButton
            label={t("tree")}
            action={() => {
              setOpenTreeForm(true), setTree(true);
            }}
            disabled={"treeId" in values && values.treeId != ""}
          />
        </div>
        <Form.Label>{t("add_all_form.tree.existing_tree")}</Form.Label>
        <div>
          {selectItem}
          <Button
            label={t("reset")}
            action={() => {
              setFieldValue("treeId", "");
            }}
            disabled={!("treeId" in values && values.treeId != "")}
          />
        </div>
      </Form.Group>
    </>
  );
}

export default Tree;
