import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import ReactEcharts from "echarts-for-react";
import { DataScienceContext } from "../context/DataScienceContext";
import { useTranslation } from "react-i18next";
import { LineHorizontal1Icon, MoreHorizontalOutlineIcon } from "@components/icons";
import { Zoom } from "styled-icons/boxicons-logos";

// Chart style
const style = {
  height: "70vh",
  width: "100%",
};

// Chart options
function GraphArea({ checkLoading, rainData }) {
  const {
    filterState,
    lYaxis,
    rYaxis,
    lYaxisMean,
    rYaxisMean,
    healthData,
    dragItemLeft,
    dragItemRight,
    activeRain,
    initialStartDate,
    initialEndDate,
    activeMean,
    activeHealthData,
    series,
    setSeries,
    // startDate,
    // endDate,
    setStartDate,
    setEndDate,
  } = useContext(DataScienceContext);
  let { sensorId, tree_sensor_pairs_tree, tree_sensor_pairs, areasId } = filterState;
  const chartInstance = useRef(null);
  // const [zoomLevels, setZoomLevels] = useState({ start: 0, end: 100 });
  const { t } = useTranslation();
  const [showHideLeftData, setHideLeftData] = useState(false);
  const [showHideRightData, setHideRightData] = useState(false);
  const getOption = () => {
    return {
      toolbox: {
        show: true,
        bottom: 50,
        right: 5,
        itemSize: 16,
        zlevel: 10,
        orient: "vertical",
        feature: {
          dataZoom: {
            // yAxisIndex: "none",
            title: {
              zoom: "Zoom In",
              back: "RESET ZOOM",
            },
          },
          restore: { title: "RESTORE" },
          saveAsImage: {},
        },
      },
      grid: {
        bottom: 50,
        left: 40,
        right: activeRain ? "12%" : "8%",
        top: 10,
        containLabel: true,
      },
      xAxis: [
        {
          type: "time",
          axisPointer: {
            show: true,
            type: "line",
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            interval: "0",
            lineStyle: {
              color: ["#F5F5F5"],
            },
          },
          axisLabel: {
            hideOverlap: true,
            formatter: function (value) {
              return moment.utc(value).format("DD.MM.YYYY HH:mm");
            },
          },
          offset: 10,
        },
      ],
      yAxis: [
        {
          show: !!dragItemLeft,
          type: "value",
          name:
            dragItemLeft === "resistance"
              ? t(dragItemLeft) + " in kΩ"
              : dragItemLeft === "temperature"
              ? t(dragItemLeft) + " in °C"
              : dragItemLeft === "water_soil"
              ? t(dragItemLeft) + " in %"
              : dragItemLeft === "soil_water_tension"
              ? t(dragItemLeft) + " in kPa"
              : dragItemLeft === "dendrometer"
              ? t(dragItemLeft) + " in µm"
              : t(dragItemLeft),
          axisTick: {
            length: 5,
          },
          nameRotate: 90,
          nameGap: 45,
          offset: 8,
          nameLocation: "center",
          nameTextStyle: {
            verticalAlign: "middle",
            padding: [0, 0, 20, 30],
          },
        },
        {
          show: !!dragItemRight,
          type: "value",
          name:
            dragItemRight === "resistance"
              ? t(dragItemRight) + " in KΩ"
              : dragItemRight === "temperature"
              ? t(dragItemRight) + " in °C"
              : dragItemRight === "water_soil"
              ? t(dragItemRight) + " in %"
              : dragItemRight === "soil_water_tension"
              ? t(dragItemRight) + " in kPa"
              : dragItemRight === "dendrometer"
              ? t(dragItemRight) + " in µm"
              : t(dragItemRight),
          axisTick: {
            length: 5,
          },
          nameRotate: 90,
          nameGap: 45,
          nameLocation: "center",
          nameTextStyle: {
            verticalAlign: "middle",
            padding: [0, 0, 20, 30],
          },
        },
        {
          boundaryGap: ["0%", "5%"],
          show: false,
          type: "value",
          name: "health",
        },
        {
          show: activeRain,
          name: "Rainfall(mm)",
          alignTicks: true,
          type: "value",
          offset: dragItemRight ? 80 : 0,
          nameRotate: 90,
          nameGap: 45,
          nameLocation: "center",
          nameTextStyle: {
            verticalAlign: "middle",
            padding: [0, 0, 20, 30],
          },
        },
      ],
      tooltip: {
        trigger: "item",
        backgroundColor: "#000",
        padding: 15,
        borderWidth: 0,
        textStyle: {
          color: "#fff",
          fontSize: 13,
        },
        formatter: function (params) {
          let labels = [];
          if (Array.isArray(params)) {
            labels = params.map((item) => {
              let type = item ? item.data.type : "";
              if (type === "dendrometerdiff") {
                type += ` ${item.data.tsp.hardware_serial.slice(-1)} - 1`;
              }
              return (
                `<tr>` +
                `<td class="px-2" style='color:${item?.data.color}'>${item?.axisValueLabel}</td>` +
                `<td class="px-2" style='color:${item?.data.color}'>${item?.seriesName}</td>` +
                `<td class="px-2" style='color:${item?.data.color}'>${type}</td>` +
                `<td class="px-2" style='color:${item?.data.color}'>${item?.value[1]}</td>` +
                `</tr>`
              );
            });
          }
          return (
            `<table class="w-100">` +
            `<thead>` +
            `<tr>` +
            `<td class="px-2">Date</td>` +
            `<td class="px-2">Tree & Sensor</td>` +
            `<td class="px-2">Type</td>` +
            `<td class="px-2">Value</td>` +
            `</tr>` +
            `<thead>` +
            `<tbody>${labels}</tbody>` +
            `</table>`
          );
        },
      },
      dataZoom: [
        {
          type: "inside",
          xAxisIndex: 0,
          // start: startZoom ? startZoom : zoomLevels.start,
          //   startValue: endZoom ? endZoom : zoomLevels.startValue,
          // end: endZoom ? endZoom : zoomLevels.end,
          start: 0,
          end: 100,
          //   endValue: zoomLevels.endValue,
        },
      ],
      series: series,
    };
  };
  const [option, setOption] = useState(getOption());

  // adding data to the series => necessary if only start_data / end_date change
  useEffect(() => {
    // setOption(getOption());
    // setSeries([]);
    // setOption({ series: [] });
    // resetChartData();
    setOption({ series: series });
  }, [series]);

  // resetting the series
  useEffect(() => {
    // setOption({ notMerge: true });
    resetChartData();
    // const option = getOption();
    // setOption(option);

    // setSeries([]);
  }, [
    tree_sensor_pairs.toString(),
    dragItemLeft,
    dragItemRight,
    activeRain,
    activeMean,
    activeHealthData,
  ]);

  const chartRef = useRef(null);

  const resetChartData = () => {
    const chartInstance = chartRef.current.getEchartsInstance();
    const newOptions = getOption();
    newOptions["series"] = { data: [] };

    // Update chart with new options
    chartInstance.setOption(newOptions, {
      notMerge: true,
    });
  };

  useEffect(() => {
    const leftDataMean = lYaxisMean?.map((data) => ({
      name: data.name,
      yAxisIndex: 0,
      smooth: true,
      type: "line",
      showSymbol: false,
      lineStyle: {
        width: 2,
        color: data ? data.color : "transparent",
        type: "solid",
      },
      data: data.data,
    }));
    const rightDataMean = rYaxisMean?.map((data) => ({
      name: data.name,
      yAxisIndex: 1,
      smooth: true,
      type: "line",
      showSymbol: false,
      lineStyle: {
        width: 2,
        color: data ? data.color : "transparent",
        type: "dotted",
      },
      data: data.data,
    }));
    const leftData = lYaxis?.map((data) => ({
      name: data.name,
      smooth: true,
      type: "line",
      yAxisIndex: 0,
      showSymbol: false,
      lineStyle: {
        width: 2,
        color: data.color,
        opacity: showHideLeftData ? 0 : 1,
        type: "solid",
      },
      data: data.data,
    }));
    const rightData = rYaxis?.map((data) => ({
      name: data.name,
      yAxisIndex: 1,
      smooth: true,
      type: "line",
      showSymbol: false,
      lineStyle: {
        width: 2,
        color: data.color,
        opacity: showHideRightData ? 0 : 1,
        type: "dotted",
      },
      data: data.data,
    }));
    const seriesNew = [
      leftData,
      leftDataMean,
      rightData,
      rightDataMean,
      healthData,
      rainData,
    ].flat();
    setSeries(seriesNew);
  }, [
    lYaxisMean,
    rYaxisMean,
    lYaxis,
    rYaxis,
    healthData,
    rainData,
    showHideRightData,
    showHideLeftData,
  ]);

  function startEndToStartEndValue(newStart, newEnd) {
    const totalStart = new Date(initialStartDate).getTime();
    const totalEnd = new Date(initialEndDate).getTime();
    const totalRange = totalEnd - totalStart;
    const newStartDateRaw = new Date(totalStart + (totalRange * newStart) / 100);
    const newEndDateRaw = new Date(totalStart + (totalRange * newEnd) / 100);
    const newStartValue = newStartDateRaw.getTime();
    const newEndValue = newEndDateRaw.getTime();
    const newStartDate = newStartDateRaw.toISOString();
    const newEndDate = newEndDateRaw.toISOString();
    return { newStartValue, newEndValue, newStartDate, newEndDate };
  }

  function startEndValueToStartEnd(newStartValue, newEndValue) {
    const newStartDate = new Date(newStartValue).toISOString();
    const newEndDate = new Date(newEndValue).toISOString();

    const totalStart = new Date(initialStartDate).getTime();
    const totalEnd = new Date(initialEndDate).getTime();
    const totalRange = totalEnd - totalStart;

    const zoomStart = new Date(newStartValue).getTime();
    const zoomEnd = new Date(newEndValue).getTime();

    const newStart = ((zoomStart - totalStart) / totalRange) * 100;
    const newEnd = ((zoomEnd - totalStart) / totalRange) * 100;
    return { newStartDate, newEndDate, newStart, newEnd };
  }
  const hideLeftData = () => {
    setHideLeftData(!showHideLeftData);
  };
  const hideRightData = () => {
    setHideRightData(!showHideRightData);
  };
  // const [prevStartEnd, setPrevStartEnd] = useState({ start: 0, end: 100 });
  // const [prevPrevStartEnd, setPrevPrevStartEnd] = useState({ start: 0, end: 100 });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (chartInstance.current) {
  //       const instance = chartInstance.current;
  //       const option = instance.getOption();
  //       const dataZoom = option.dataZoom[0] ? option.dataZoom[0] : { start: 0, end: 100 };
  //       // console.log("DZ", dataZoom);
  //       const { start, startValue, end, endValue } = dataZoom;
  //       // if (start == 0 && end == 100 && start == zoomLevels.start && end == zoomLevels.end) return;
  //       // setZoomLevels({ start: 0, end: 100 });
  //       // if (start == zoomLevels.start && end == zoomLevels.end) return;
  //       console.log(start, prevPrevStartEnd.start, end, prevPrevStartEnd.end);
  //       if (start == prevPrevStartEnd.start && end == prevPrevStartEnd.end) return;
  //       setPrevPrevStartEnd(prevStartEnd);
  //       setPrevStartEnd({ start, end });
  //       // setZoomLevels({ start: 0, end: 100 });
  //       console.log("START", start, zoomLevels.start);
  //       console.log("END", end, zoomLevels.end);
  //       // setZoomLevels({ start, startValue, end, endValue });
  //       const { newStartDate, newEndDate } = startEndToStartEndValue(start, end);
  //       console.log("DATES", startDate, endDate, newStartDate, newEndDate);
  //       if (startDate != newStartDate || endDate != newEndDate) {
  //         console.log("settingStartDATE", startDate, endDate, newStartDate, newEndDate);
  //         // setZoomLevels({ start, end });
  //         //   setZoomLevels({ start: 0, end: 100 });
  //         setStartDate(newStartDate);
  //         setEndDate(newEndDate);
  //       }
  //     }
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [zoomLevels, startDate, endDate, prevStartEnd, prevPrevStartEnd]);

  // const zoomStateRef = useRef(null);

  const onChartReady = (chart) => {
    chartInstance.current = chart;
    chart.on("dataZoom", (params) => {
      // Capture the zoom state
      // zoomStateRef.current = params.batch[0];
      const dataZoom = params.batch[0];
      const { start, startValue, end, endValue } = dataZoom;
      // console.log(start, startValue, end, endValue);
      if (start === undefined) {
        const { newStartDate, newEndDate } = startEndValueToStartEnd(startValue, endValue);
        // console.log(newStartDate, newEndDate);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      } else {
        const { newStartDate, newEndDate } = startEndToStartEndValue(start, end);
        // console.log(newStartDate, newEndDate);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      }
    });
    // chart.on("restore", () => {
    //   console.log("RESETTING series");
    //   setSeries([]);
    // });
  };

  // const onChartReady = (chart) => {
  //   chart.on('dataZoom', (params) => {
  //     // Capture the zoom state
  //     zoomStateRef.current = params.batch[0];
  //   });
  // };
  return (
    <>
      <div className=" d-flex justify-content-center  gap-2">
        {dragItemLeft && (
          <div
            className="text-uppercase"
            style={{
              cursor: "pointer",
              zIndex: 99,
              fontSize: "12px",
              background: "rgba(90, 208, 210, 0.098039216)",
              padding: "5px 10px",
              borderRadius: "3px",
            }}
            onClick={hideLeftData}
          >
            <LineHorizontal1Icon showHideLeftData={showHideLeftData} /> {t(dragItemLeft)}
          </div>
        )}
        {dragItemRight && (
          <div
            className="text-uppercase"
            onClick={hideRightData}
            style={{
              cursor: "pointer",
              zIndex: 99,
              fontSize: "12px",
              background: "rgba(90, 208, 210, 0.098039216)",
              padding: "5px 10px",
              borderRadius: "3px",
            }}
          >
            <MoreHorizontalOutlineIcon showHideRightData={showHideRightData} />
            {t(dragItemRight)}
          </div>
        )}
      </div>

      <ReactEcharts option={option} style={style} onChartReady={onChartReady} ref={chartRef} />
    </>
  );
}

export default GraphArea;
