import axiosInstance from "@helper/interceptor";

export const getAllUsers = async () => {
  let response = await axiosInstance.get("users/");
  return response.data;
};
export const getUser = async (id) => {
  let response = await axiosInstance.get(`/users/${id}`);
  return response.data;
};
export const createUser = async (formData) => {
  let response = await axiosInstance.post("users/", formData);
  return response.data;
};

export const updateUser = async (formData) => {
  let response = await axiosInstance.patch(`/users/${formData.id}/`, formData);
  return response.data;
};

export const deleteUser = async (id) => {
  let response = await axiosInstance.delete(`/users/${id}/`);
  if (response.status === 200) return response.data;
};

export const logPageVisit = async (pageName) => {
  let response = await axiosInstance.get(`/page-visited/${pageName}/`);
  return response.data;
};
