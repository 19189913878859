import { useQuery } from "react-query";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "react-bootstrap";
// import "react-leaflet-fullscreen/dist/styles.css";
import { getIrrigationArea } from "../services/areaCrud";
import Spinner from "@components/Spinner/Spinner";
import ServerError from "@components/Error/ServerError";
import PageNotFound from "@components/Error/PageNotFound";
import {
  FeatureGroup,
  MapContainer,
  Polygon,
  TileLayer,
  GeoJSON,
  Marker,
  ZoomControl,
  Popup,
  useMapEvents,
} from "react-leaflet";
import useChoices from "@hooks/useChoices";
import { getTree } from "@modules/Trees/services/treeCrud";
import { Link } from "react-router-dom";
import { MenuAlt1Icon, LineChartIcon } from "@components/icons";

// import { FullscreenControl } from "react-leaflet-fullscreen";
import { parentTreeIcon, childTreeIcon, noSensorTreeIcon } from "@helper/marker";
import { handleTreeSelect } from "@modules/Projects/pages/ProjectDetail/ProjectDetailMapView/MapViewTreeList";

function IrrigationAreaDetail() {
  const [mapCenter, setMapCenter] = useState();
  const [polygon, setPolygon] = useState([]);
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("selectedArea");

  //get sensor detail
  const {
    data: area,
    isLoading,
    isError,
    error,
  } = useQuery(["areas", id], () => getIrrigationArea(id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    onSuccess: (data) => {
      if (data.polygon) {
        const { centroid, positions, vertices } = data.polygon;
        let filterPosition = vertices?.map((a) => positions[a]);
        setMapCenter(centroid);
        if (positions.length > 0) {
          setPolygon(filterPosition);
        }
      } else {
        setPolygon([]);
        setMapCenter([6.1755, 20.540498]);
      }
    },
  });

  if (isLoading) return <Spinner height={"500px"} />;
  if (isError && error.response.status === 404) return <PageNotFound />;
  if (isError) return <ServerError />;
  return (
    <Card style={{ border: "1px dashed #DDD" }}>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="mb-0">{area.name}</h5>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {mapCenter && (
          <MapContainer
            center={mapCenter}
            zoom={14}
            style={{ height: "calc(100vh - 400px)" }}
            zoomControl={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <ZoomControl position="bottomright" />
            {/* <FullscreenControl position="bottomright" /> */}

            <FeatureGroup>
              <div>
                <GeoJSON color="purple" data={polygon} />
                <Polygon key={area.id} positions={polygon} />
              </div>
            </FeatureGroup>

            {area?.trees?.map((tree, i) => {
              const health = tree.health_state;
              if (tree.position) {
                return (
                  <div key={i}>
                    {tree.position && (
                      <Marker
                        position={[tree.position.lat, tree.position.lng]}
                        icon={
                          tree.installations.length != 0
                            ? parentTreeIcon(health)
                            : noSensorTreeIcon(health)
                        }
                      >
                        <Popup minWidth={350} autoPan={false}>
                          <div className="popup">
                            <Row>
                              <PopupContent treePart={tree} area={area.id} />
                            </Row>
                          </div>
                        </Popup>
                      </Marker>
                    )}
                    {tree?.represented_trees.map((childTree, i) => {
                      const childHealth = childTree.health_state;
                      return (
                        <div key={i}>
                          {childTree.position && (
                            <Marker
                              position={[childTree.position.lat, childTree.position.lng]}
                              icon={childTreeIcon(childHealth)}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          </MapContainer>
        )}
      </Card.Body>
    </Card>
  );
}

export default IrrigationAreaDetail;

export const PopupContent = ({
  treePart,
  project,
  aId,
  selectedTree,
  setSelectedTree,
  setOpenTreePopup,
  setSelectedParentTree,
}) => {
  const { t } = useTranslation();
  const map = useMapEvents({});
  const navigate = useNavigate();
  const [phases] = useChoices("growth_phase");
  const [locations] = useChoices("growth_phase");
  const tid = treePart.id;
  const {
    data: tree,
    isLoading,
    isError,
    error,
  } = useQuery(["tree", tid], () => getTree(tid), {
    refetchOnWindowFocus: false,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // delay between retries in milliseconds
    staleTime: 1000 * 60 * 60,
    //onSuccess: (data) => {
    //  data?.sensors.length !== 0 && setSeraials(data.sensors?.map((a) => a.hardware_serial));
    //},
  });

  if (isLoading) return <Spinner height={"73vh"} />;
  let phase = "";
  phase = phases && phases.filter((item) => item.key == tree?.phase);
  let location = "";
  location = locations && locations.filter((item) => item.key == tree?.location);
  // if (isError && error.response && error.response.status === 404) return <PageNotFound />;
  // if (isError) return <ServerError />;
  return (
    <>
      <Col lg={6} className="px-1">
        {project && (
          <>
            <Link
              to={`/project/${project.id}/list?selectedTree=${tree?.id}`}
              target="blank"
              style={{
                width: "35px",
                background: "#eee",
                display: "flex",
                justifyContent: "center",
                borderRadius: "3px",
                padding: "4px",
              }}
              title="View on map"
            >
              <MenuAlt1Icon width={"20px"} />
            </Link>
            {/* {t("go_to_list_view")} */}
          </>
        )}
        {project && (
          <Button
            onClick={() =>
              handleTreeSelect(
                tree,
                aId,
                project.id,
                navigate,
                selectedTree,
                setSelectedTree,
                setOpenTreePopup,
                setSelectedParentTree,
                map
              )
            }
          >
            <TreeIcon width={"20px"} />
          </Button>
        )}

        <ul className="list-group">
          <li className="list-group-item">
            <b>{t("name")}:</b> {tree.name}
          </li>
          {locations && location.length == 1 && (
            <li className="list-group-item">
              {/*
						<b>{t("document irrigation")}: </b>
						<Button
							variant="outline-primary d-flex align-items-center"
							onClick={() => console.log("Button clicked")}
						>
							<>
								{t("Irrigation")} &nbsp;
								{<Spinner animation="border" size="sm" />}
							</>
						</Button>
          */}
              <b>{t("location")}: </b>
              {location[0].value}
            </li>
          )}
          {tree.height && (
            <li className="list-group-item">
              <b>{t("height")}: </b> {tree.height + " m"}
            </li>
          )}
        </ul>
      </Col>
      <Col lg={6} className="px-1">
        {aId && tree?.sensors?.length != 0 && project && (
          <Link
            to={`/project/${project.id}/datascience?areas=${aId}&trees=${tree?.id}`}
            target="blank"
            style={{
              width: "35px",
              background: "#eee	",
              display: "flex",
              justifyContent: "center",
              borderRadius: "3px",

              padding: "4px",
            }}
            title="View on chart"
          >
            <LineChartIcon width={"25px"} />
          </Link>
        )}

        <ul className="list-group">
          {tree.crown_diameter && (
            <li className="list-group-item">
              <b>{t("crown_diameter")}: </b>
              {tree.crown_diameter + " m"}
            </li>
          )}
          {tree.trunk_cirumference && (
            <li className="list-group-item">
              <b>{t("trunk_circumference")}: </b>
              {tree.trunk_circumference + " cm"}
            </li>
          )}
          {phases && phase.length > 0 && (
            <li className="list-group-item">
              <b>{t("phase")}: </b>
              {t(phase[0].value)}
            </li>
          )}
        </ul>
      </Col>
    </>
  );
};

import { Tree } from "@styled-icons/entypo/Tree";
import styled from "styled-components";
export const TreeIcon = styled(Tree)`
  width: 14px;
`;
