import Swal from "sweetalert2";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { deleteInstallation } from "../../../../../services/treeCrud";

import { EditIcon, TrashIcon } from "@components/icons";

export default function InstallationMessage({
  tree,
  setIsUpdate,
  setOpenForm,
  setInstallationItem,
}) {
  return (
    <ul className="p-3">
      <MessageHeader />
      {tree?.installations
        ?.sort((a, b) => (a.date > b.date ? 1 : -1))
        ?.map((installation) => {
          return (
            <MessageItem
              installation={installation}
              key={installation.id}
              setIsUpdate={setIsUpdate}
              setOpenForm={setOpenForm}
              setInstallationItem={setInstallationItem}
              tid={tree.id}
            />
          );
        })}
    </ul>
  );
}

const MessageItem = ({ installation, setIsUpdate, setOpenForm, setInstallationItem, tid }) => {
  // const { t } = useTranslation();
  const queryClient = useQueryClient();
  const installedDate = new Date(installation.installed_at);
  const removedDate = new Date(installation.removed_at);
  const readableInstalled = moment(installedDate).format("DD.MM.YYYY");
  const readableRemoved = moment(removedDate).format("DD.MM.YYYY");
  const { mutateAsync: installationDelete } = useMutation(deleteInstallation, {
    onSuccess: () => {
      // queryClient.invalidateQueries(["trees", tid]);
      queryClient.invalidateQueries("tree", { tid: tid });
    },
  });
  const handleDelete = (id) => {
    Swal.fire({
      html:
        '<p className="text-center"> ' +
        "Are you sure you want to delete " +
        // ` <b>${project.name} </b>` +
        "?" +
        "</p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#388090",
      cancelButtonColor: "#EF3600",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        installationDelete(id);
      }
    });
  };
  return (
    <li className="d-flex gap-2 py-2 mb-3">
      <div style={{ width: "200px", fontWeight: "bold" }}>{installation.sensor.name}</div>
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between ">
          <p className="mb-0" style={{ fontSize: "12px" }}>
            {readableInstalled}
          </p>
          <p className="mb-0" style={{ fontSize: "12px" }}>
            {readableRemoved}
          </p>
          <span title="Edit">
            <EditIcon
              onClick={() => {
                setIsUpdate(true);
                setOpenForm(true);
                setInstallationItem(installation);
              }}
            />
            <TrashIcon
              onClick={() => {
                console.log("INSTA", installation);
                handleDelete(installation.id);
              }}
            />
          </span>
        </div>
        {/* <div className="pt-2"> */}
        {/*   <div className="mb-1"> */}
        {/*     {t("maintainance")}:{" "} */}
        {/*     {maintenance.tasks?.map((item, i) => ( */}
        {/*       <MaintenanceList taskItem={item} key={i} maintenance={maintenance} /> */}
        {/*     ))} */}
        {/*   </div> */}
        {/*   {maintenance.other && ( */}
        {/*     <div className="mb-1"> */}
        {/*       {t("other")}: {maintenance.other} */}
        {/*     </div> */}
        {/*   )} */}
        {/*   <div> */}
        {/*     <span> */}
        {/*       {t("time")}: <b> {maintenance.duration} hrs</b> */}
        {/*     </span> */}
        {/*   </div> */}
        {/*   <i>{maintenance.notes}</i> */}
        {/* </div> */}
      </div>
    </li>
  );
};

const MessageHeader = () => {
  const { t } = useTranslation();
  return (
    <li className="d-flex gap-2 py-2 mb-3">
      <div style={{ width: "200px", fontWeight: "bold" }}>{t("name")}</div>
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between ">
          <p className="mb-0" style={{ fontSize: "14.5px" }}>
            {t("installed_at")}
          </p>
          <p className="mb-0" style={{ fontSize: "14.5px" }}>
            {t("removed_at")}
          </p>
          <p className="mb-0" style={{ fontSize: "14.5px" }}>
            {t("actions")}
          </p>
        </div>
        {/* <div className="pt-2"> */}
        {/*   <div className="mb-1"> */}
        {/*     {t("maintainance")}:{" "} */}
        {/*     {maintenance.tasks?.map((item, i) => ( */}
        {/*       <MaintenanceList taskItem={item} key={i} maintenance={maintenance} /> */}
        {/*     ))} */}
        {/*   </div> */}
        {/*   {maintenance.other && ( */}
        {/*     <div className="mb-1"> */}
        {/*       {t("other")}: {maintenance.other} */}
        {/*     </div> */}
        {/*   )} */}
        {/*   <div> */}
        {/*     <span> */}
        {/*       {t("time")}: <b> {maintenance.duration} hrs</b> */}
        {/*     </span> */}
        {/*   </div> */}
        {/*   <i>{maintenance.notes}</i> */}
        {/* </div> */}
      </div>
    </li>
  );
};
