import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

const SearchControl = (props) => {
  const map = useMap();
  const provider = new OpenStreetMapProvider();
  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider: provider,
      ...props,
    });

    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, [props]);

  return null;
};
export default SearchControl;
