// import Select from "react-select";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CloseOutlineIcon } from "@components/icons";
import { ITree } from "../../../../../interfaces";
// import { ITree } from "@interfaces";

interface ITreeField extends ITree {
  noSensorTree: string;
}

export default function TreeNoSensor({ values, setFieldValue, childOption }) {
  const [inputFields, setInputFields] = useState([]);
  // const [childOption, setChildOption] = useState([]);
  // const [parentOption, setParentOption] = useState([]);
  // const [treeNoSensorOption, setTreeNoSensorOption] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const b = [];
    values?.trees?.map((a: ITree) => {
      b.push({ id: a.id, name: a.name, represented_trees: a.represented_trees });
    });
    setInputFields(b);
  }, []);

  useEffect(() => {
    setFieldValue("trees", inputFields);
  }, [inputFields]);

  const handleFormChange = (index: number, event) => {
    const data = [...inputFields];
    if (event.target && event.target.name === "id") {
      data[index]["id"] = event.target.value;
    } else {
      data[index]["represented_trees"] = event;
    }
    setInputFields(data);
  };

  const addFields = () => {
    const newfield = {};
    setInputFields([...inputFields, newfield]);
  };

  const handleRemove = (index: number) => {
    const filtered = inputFields.filter((a: ITree, i) => i !== index);
    setInputFields(filtered);
  };
  // const isParentTree = (tree) => tree.id in Object.keys(parentOption);
  const childIds = childOption.map((tree: ITree) => {
    return +tree.id;
  });
  const isChildTree = (tree: ITreeField) => (tree.id ? childIds.includes(+tree.id) : true);
  // const parentInputFields = inputFields?.filter(isParentTree);
  const [treeNoSensorInputFields, setTreeNoSensorInputFields] = useState([]);
  useEffect(() => {
    setTreeNoSensorInputFields(inputFields?.filter(isChildTree));
  }, [inputFields]);

  return (
    <div>
      <Row>
        <Col lg={5}>
          <h6>{t("tree_no_sensor")}</h6>
        </Col>
      </Row>
      {treeNoSensorInputFields?.map((field: ITree, index) => {
        const selectedTree = childOption.filter((tree: ITree) => tree.id == field.id);
        return (
          <Row className="g-1 mb-2" key={index}>
            <Col lg={5}>
              <div>
                <Form.Select
                  name="id"
                  style={{ height: "38px" }}
                  onChange={(event) => handleFormChange(index, event)}
                  value={selectedTree?.id}
                  defaultValue={"Choose Tree"}
                >
                  {<option selected>{field.name}</option>}

                  {childOption?.map((tree: ITree) => (
                    <option value={tree.id} key={tree.id}>
                      {tree.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col>
              <Button
                variant="outline-primary"
                className="border-0 px-2"
                style={{ height: "38px" }}
                onClick={() => handleRemove(index)}
              >
                <CloseOutlineIcon />
              </Button>
            </Col>
          </Row>
        );
      })}
      <button type="button" className="btn btn-primary btn-sm" onClick={addFields}>
        Add More..
      </button>
      ;
    </div>
  );
}
