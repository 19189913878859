import React, { useMemo } from "react";
// import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import ReactTable from "@components/ReactTable";
import { statusHeader, healthHeader, batteryHeader } from "@modules/Sensors/components/SensorList";
// import { getSensorsProject } from "@modules/Sensors/services/sensorCrud";

export default function SensorList({ sensors }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { pid } = useParams();
  const query = new URLSearchParams(location.search);
  const id = query.get("selectedSensor");

  // const {
  //   data: sensorsAll,
  //   isLoading,
  //   error,
  // } = useQuery(`sensors-${pid}`, () => getSensorsProject(pid), {
  //   refetchOnWindowFocus: false,
  //   retry: false,
  //   staleTime: 1000 * 60 * 60,
  // });
  // if (!isLoading) {
  //   sensors = sensors
  //     .map((s) => {
  //       const matchedSensor = sensorsAll.find((t) => t.id === +s.id);
  //       if (matchedSensor) {
  //         return {
  //           ...matchedSensor,
  //           tree: s.tree,
  //         };
  //       }
  //       return null; // or return s if you want to keep the original sensor when there's no match
  //     })
  //     .filter(Boolean);
  //   // sensors = sensors.map((s) => sensorsAll.find((t) => t.id === +s.id)).filter(Boolean);
  // }
  // sensors = sensors.sort((a, b) => a.id - b.id);
  // if (error) {
  //   console.error("Error fetching sensors:", error);
  // }

  const columns = useMemo(
    () => [
      statusHeader(t),
      {
        Header: `${t("hardware_serial")}`,
        accessor: "hardware_serial",
      },

      {
        Header: `${t("name")}`,
        accessor: "name",
      },
      {
        Header: `${t("tree")}`,
        accessor: "tree",
      },
      healthHeader(t),
      {
        Header: `${t("threshold")}`,
        accessor: "threshold",
      },
      batteryHeader(t),
    ],
    []
  );
  const onRowClicked = (row) => {
    const { id } = row.original;
    navigate(`/project/${pid}/list?selectedSensor=${id}`);
  };

  return (
    <ReactTable
      columns={columns}
      data={sensors}
      onRowClicked={onRowClicked}
      activeId={id}
      pagesize={10}
      size={[10, 20, 30, 40, 50]}
    />
  );
}

export const SensorStatus = ({ color, title }) => {
  return (
    <span
      className={"badge"}
      style={{ padding: "5px", borderRadius: "2px", background: color }}
      title={`${title}`}
    >
      <div
        className="rounded-circle"
        style={{
          width: "6px",
          height: "6px",
          background: "#fff",
        }}
      ></div>
    </span>
  );
};
