import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import IrrigationAreaDetail from "./IrrigationAreaDetail";
import { ViewTypeButton } from "../Button";
import { getPublicProject } from "@modules/Projects/services/projectCrud";
import Spinner from "@components/Spinner/Spinner";
import ServerError from "@components/Error/ServerError";
import { Breadcrumb } from "@components/Breadcrumb";
import MapViewTreeList from "./MapViewTreeList";
import { Weather } from "@modules/Weather";
import AddButton from "@components/Button/AddButton";
import AddAllForm from "@modules/Projects/components/Form/AddAllForm";

function IrrigationAreaPage() {
  /**
   * Renders the Irrigation Area Page component.
   *
   * @return {JSX.Element} The Irrigation Area Page component.
   */
  const { mobile } = useSelector((state) => state.mobile);
  const { pid } = useParams();
  const [trees, setTrees] = useState([]);
  const [openChildModal, setOpenChildModal] = useState(false);
  const [selectedParentTree, setSelectedParentTree] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [mapCenter, setMapCenter] = useState([48.1558269, 11.5421791]);
  const [selectedTree, setSelectedTree] = useState();
  const query = new URLSearchParams(useLocation().search);
  const tid = query.get("selectedTree");
  const lng = query.get("lng");
  const lat = query.get("lat");
  const aId = query.get("area");
  //retrieving all sensors list
  const {
    data: project,
    isLoading,
    isError,
  } = useQuery(["project", pid], () => getPublicProject(pid), {
    onSuccess: (project) => {
      const { areas, centroid } = project;
      let a = areas
        .map((area) =>
          area.tree_set.map((tree) => {
            tree["area"] = area.name;
            return tree;
          })
        )
        .flat();
      setTrees(a);

      if (centroid) {
        setMapCenter([centroid.lat, centroid.lng]);
      }
    },
  });

  //delete sensor function
  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError) return <ServerError />;
  return (
    <>
      {mobile ? (
        <Breadcrumb
          title={project.name}
          weather={<Weather weather={project.weather} iconWidth={"1.2rem"} />}
        />
      ) : (
        <Breadcrumb
          title={project.name}
          weather={<Weather weather={project.weather} iconWidth={"1.2rem"} />}
        />
      )}
      <Container>
        <Row>
          <Col lg="3">
            <MapViewTreeList
              trees={trees}
              areas={project.areas}
              selectedArea={selectedArea}
              openChildModal={openChildModal}
              setSelectedArea={setSelectedArea}
              setOpenChildModal={setOpenChildModal}
              selectedParentTree={selectedParentTree}
              setSelectedParentTree={setSelectedParentTree}
              setSelectedTree={setSelectedTree}
              tid={tid}
              aId={aId}
            />
          </Col>
          <IrrigationAreaDetail
            setTrees={setTrees}
            selectedTree={selectedTree}
            mapCenter={mapCenter}
            project={project}
            tid={tid}
            lat={lat}
            lng={lng}
          />
        </Row>
      </Container>
    </>
  );
}

export default IrrigationAreaPage;
