import React from "react";
import { Link } from "react-router-dom";
import "./billing.css";
import { ArrowLeftIcon } from "@components/icons";

export default function PaymentSuccess() {
  return (
    <div className="payment-success">
      <div>
        <div className="mb-4">
          <img width="250px" src={process.env.PUBLIC_URL + "/coloredlogo.png"} alt="" />
        </div>
        <div className="ps-4">
          <h2>Your Payment is Successfull</h2>
          <h6>
            Your subscription for basic package has been successful. Check your email for invoice.
          </h6>
          <p>
            <ArrowLeftIcon />
            <Link to="/login">Go back to login</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
