import React from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Threshold() {
  const { t } = useTranslation();
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t("threshold")}</Form.Label>
        <Field type="number" name="threshold" min={0} className={"form-control"} />
      </Form.Group>
    </>
  );
}

export default Threshold;
