import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { authReducer } from "@modules/Auth";
import viewReducer from "./viewSlice";

export const appReducer = combineReducers({
  auth: authReducer,
  mobile: viewReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "[Logout] Action") {
    storage.removeItem("persist:root");
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

export const persistReducers = persistReducer(persistConfig, rootReducer);
