import UserForm from "./Form";
import UserList from "./UserList";
import UserDetail from "./UserDetail";
import { useQuery } from "react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllUsers } from "../services/userCrud";
import { Col, Container, Row } from "react-bootstrap";
import Spinner from "@components/Spinner/Spinner";
import ServerError from "@components/Error/ServerError";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import AddButton from "@components/Button/AddButton";

export default function UserPage() {
  const { t } = useTranslation();
  const [isUpdate, setIsUpdate] = useState(false);
  const [typeDetail, setTypeDetail] = useState();
  const [openForm, setOpenForm] = useState(false);

  const {
    isLoading,
    isError,
    data: users,
  } = useQuery("users", getAllUsers, {
    refetchOnWindowFocus: false,
  });

  const handleClose = () => {
    setOpenForm(false);
    setIsUpdate(false);
  };

  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError) return <ServerError />;

  return (
    <>
      <Breadcrumb
        title={t("users")}
        addButton={<AddButton label={"add_user"} action={() => setOpenForm(true)} />}
      />
      <Container>
        <Row>
          <Col lg={7}>
            <UserList
              users={users}
              setIsUpdate={setIsUpdate}
              setTypeDetail={setTypeDetail}
              handleShow={() => setOpenForm(true)}
            />
          </Col>
          <Col lg={5}>
            <UserDetail />
          </Col>
        </Row>
        {openForm && (
          <UserForm
            show={openForm}
            isUpdate={isUpdate}
            typeDetail={typeDetail}
            handleClose={handleClose}
          />
        )}
      </Container>
    </>
  );
}
