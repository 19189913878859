import { Badge, Card, Dropdown, NavDropdown, Spinner } from "react-bootstrap";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { Users as usr } from "@styled-icons/fa-solid/Users";
import { Users } from "@styled-icons/icomoon/Users";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { Gumtree } from "@styled-icons/simple-icons/Gumtree";
import { WifiOff } from "@styled-icons/bootstrap/WifiOff";
import { DropdownItem } from "./NavItem";
import axiosInstance, { baseURL } from "@helper/interceptor";
import {
  BellIcon,
  SensorsOffIcon,
  SettingIcon,
  BatteryWarningIcon,
  LogoutIcon,
} from "@components/icons";

export default function Topbarglobal({ logout }) {
  const [notifications, setNotifications] = useState([]);
  const [show, setShow] = useState(false);
  const { mobile } = useSelector((state) => state.mobile);
  const { auth } = useSelector((state) => state);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  /*===== query notification every hour ====*/
  const { isLoading, isError: isNotificationError } = useQuery(
    "notifications",
    async () => {
      const res = await axiosInstance.get("/notifications/");
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 60 * 10,
      onSuccess: (data) => {
        const newData = data.filter((notify) => notify.is_seen !== true);
        setNotifications(newData);
      },
    }
  );

  const { isError, mutateAsync } = useMutation(
    "notification",
    async (formData) => {
      await axiosInstance.patch(`${baseURL}notifications/${formData.id}/`, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notifications");
      },
    }
  );
  const showDropdown = () => {
    setShow(!show);
  };
  const hideDropdown = () => {
    setShow(false);
  };

  const NotificationDropdown = () => {
    return (
      <li className="header__end__nav-list__nav-item">
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            aria-label="search"
            className="nav-link bg-transparent bell-btn"
            title="Notifications"
          >
            <BellIcon width="18px" />
            {notifications.length !== 0 && (
              <Badge pill bg="danger" className="rounded-lg  bell-btn__badge position-absolute">
                {notifications.length}
              </Badge>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu className="p-0 notifiaction-list shadow border-0" align="end">
            <Card className="border-0">
              <Card.Header style={{ background: "#fff" }}>
                <div className="d-flex justify-content-between">
                  <h5 className="my-2">{t("notifications")}</h5>
                </div>
              </Card.Header>
              <Card.Body className="notificationBody p-0">
                <>
                  {notifications.length > 0 && (
                    <div className="d-flex justify-content-between p-3">
                      <span>{t("mark_all_as_seen")}</span>
                      <div className="d-flex">
                        <Formik
                          initialValues={{
                            is_seen: "",
                          }}
                          onSubmit={(values) => {
                            notifications?.map((noti) => {
                              const formData = {
                                id: noti.id,
                                is_seen: values.is_seen,
                              };
                              mutateAsync(formData);
                            });
                          }}
                        >
                          {({ handleSubmit, submitForm, handleChange }) => (
                            <form
                              onSubmit={handleSubmit}
                              className="d-flex justify-content-between gap-5  "
                            >
                              <div className="flex-fill d-flex justify-content-between">
                                <div className=" mb-0 ">
                                  <Field
                                    type="checkbox"
                                    name="is_seen"
                                    title="Mark as seen"
                                    onChange={(e) => {
                                      handleChange(e);
                                      submitForm();
                                    }}
                                  />
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  )}
                  {isLoading ? (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <ul className="p-0 notificationMenu px-3">
                      {notifications?.map((noti) => {
                        return (
                          <li key={noti.id} className="d-flex justify-content-between py-2">
                            <>
                              {
                                {
                                  1: (
                                    <NotificationList
                                      title={noti.tree?.name}
                                      description={noti.description}
                                      icon={<GumtreeIcon color="#EF5C5C" />}
                                    />
                                  ),
                                  2: (
                                    <NotificationList
                                      title={noti.tree?.name}
                                      description={noti.description}
                                      icon={<GumtreeIcon color="#FFBA4A" />}
                                    />
                                  ),
                                  3: (
                                    <NotificationList
                                      title={noti.sensor?.hardware_serial}
                                      description={noti.description}
                                      icon={<NoWifi color="#EF5C5C" width={"18px"} />}
                                    />
                                  ),
                                  4: (
                                    <NotificationList
                                      title={noti.sensor?.hardware_serial}
                                      description={noti.description}
                                      icon={<BatteryWarningIcon color="#EF5C5C" width={"18px"} />}
                                    />
                                  ),
                                  5: (
                                    <NotificationList
                                      title={noti.sensor?.hardware_serial}
                                      description={noti.description}
                                      icon={<SensorsOffIcon color="#EF5C5C" width={"18px"} />}
                                    />
                                  ),
                                }[noti.notificationType]
                              }
                            </>
                            <div className="d-flex">
                              <Formik
                                initialValues={{
                                  is_seen: "",
                                }}
                                onSubmit={(values) => {
                                  const formData = {
                                    id: noti.id,
                                    is_seen: values.is_seen,
                                  };
                                  mutateAsync(formData);
                                }}
                              >
                                {({ handleSubmit, submitForm, handleChange }) => (
                                  <form
                                    onSubmit={handleSubmit}
                                    className="d-flex justify-content-between gap-5  "
                                  >
                                    <div className="flex-fill d-flex justify-content-between">
                                      <div className=" mb-0 ">
                                        <Field
                                          type="checkbox"
                                          name="is_seen"
                                          title="Mark as seen"
                                          onChange={(e) => {
                                            handleChange(e);
                                            submitForm();
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </>
              </Card.Body>
            </Card>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    );
  };
  return (
    <>
      {/* {isError || (isNotificationError && "")} */}
      <ul
        className="header__end__nav-list"
        style={{
          listStyleType: "none",
        }}
      >
        {/* {NotificationDropdown()} */}
        {!mobile && (
          <>
            <li className="header__end__nav-list__nav-item">
              <NavDropdown
                className="text-uppercase bg-transparent"
                show={show}
                title={<SettingIcon size="20px" color="#fff" />}
                onMouseEnter={showDropdown}
                onClick={() => setShow(!show)}
                onMouseLeave={hideDropdown}
              >
                <DropdownItem
                  link="/profile"
                  title="Profile"
                  icon={<UsersIcon />}
                  aria-label="users"
                />
                {auth?.user?.account_type !== 2 && (
                  <>
                    <DropdownItem
                      link="/users"
                      title="users"
                      icon={<UsersIcon />}
                      aria-label="users"
                    />
                    <DropdownItem
                      link="/customers"
                      title="customer"
                      icon={<CustomerIcon />}
                      aria-label="customers"
                    />
                  </>
                )}

                <DropdownItem
                  link="/settings"
                  title="Settings"
                  icon={<SettingIcon size="16px" />}
                  aria-label="settings"
                />
              </NavDropdown>
            </li>
            <li className="header__end__nav-list__nav-item">
              <span onClick={logout} className="nav-link pe-0 clickable">
                <LogoutIcon width="20px" />
                {t("logout")}
              </span>
            </li>
          </>
        )}
      </ul>
    </>
  );
}

export const NotificationList = ({ description, title, icon }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex gap-2">
      <div
        style={{
          minWidth: "30px",
          height: "30px",
          border: " 1px solid #ccc",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </div>
      <div>
        <small className="text-muted">{title}</small>
        <h6>{t(description)}</h6>
      </div>
    </div>
  );
};

const UsersIcon = styled(Users)`
  width: 14px;
  margin-right: 3px;
`;
const CustomerIcon = styled(usr)`
  width: 14px;
  margin-right: 3px;
`;

const GumtreeIcon = styled(Gumtree)`
  width: 18px;
  color: ${(props) => props.color};
`;
export const NoWifi = styled(WifiOff)`
  width: 18px;
  color: gray;
`;
