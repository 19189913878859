export default {
  formId: "userForm",
  formField: {
    treeCadastreNo: {
      name: "tree_cadastre_no",
      label: "tree_cadastre_no",
    },
    name: {
      name: "name",
      label: "name",
    },
    longitude: {
      name: "longitude",
      label: "longitude",
    },
    latitude: {
      name: "latitude",
      label: "latitude",
    },
    altitude: {
      name: "altitude",
      label: "altitude",
    },
    sensor: {
      name: "sensors",
      label: "sensors",
    },
    phase: {
      name: "phase",
      label: "phase",
    },
    specie: {
      name: "species",
      label: "tree_species",
    },
    plantingYear: {
      name: "planting_year",
      label: "planting_year",
    },
    trunkCircumference: {
      name: "trunk_circumference",
      label: "trunk_circumference",
    },
    height: {
      name: "height",
      label: "height",
    },
    crownDiameter: {
      name: "crown_diameter",
      label: "crown_diameter",
    },
    representTree: {
      name: "represented_trees",
      label: "Represent Tree",
    },
  },
};
