import { useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getAllCustomers } from "../../../../../Customer/services/customerCrud";

export default function ChildTree({ values, setFieldValue, formField }) {
  const [childOption, setChildOption] = useState();
  const { representTree } = formField;
  const { t } = useTranslation();

  useQuery("customers", getAllCustomers, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setChildOption(data[0].possible_child_trees);
    },
  });

  return (
    <div>
      <h6>{t(representTree.label)}</h6>
      <div>
        <Select
          id={"representTree"}
          type={"text"}
          defaultValue={
            values.represented_trees
              ? values.represented_trees?.map((representedTree) => ({
                  value: representedTree.id,
                  label: representedTree.name,
                }))
              : ""
          }
          onChange={(option) => {
            return setFieldValue(
              "represented_trees",
              option?.map((filteredTree) => ({
                id: parseInt(filteredTree.value),
                type: "Tree",
              }))
            );
          }}
          options={childOption?.map((filteredTree) => ({
            value: filteredTree.id,
            label: filteredTree.name,
          }))}
          isMulti
        />
      </div>
    </div>
  );
}
