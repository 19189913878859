import React from "react";
import "./Error.css";

export default function PageNotFoundInner() {
  return (
    <div id="notfound" className="se">
      <div className="mt-5 px-0">
        {/* <div
					className="notfound-404"
					style={{ backgroundImage: ` url("/favicon.ico")` }}
				></div> */}
        <h1 className="fw-bold">404</h1>
        <h2>Oops! Page Not Be Found</h2>
        <p>
          Sorry but the page you are looking for does not exist, have been removed. name changed or
          is temporarily unavailable
        </p>
      </div>
    </div>
  );
}
