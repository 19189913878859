import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import AddButton from "@components/Button/AddButton";
import { getAllSensors } from "@modules/Sensors/services/sensorCrud";
import { getTrees } from "@modules/Trees/services/treeCrud";
import {
  InstallationForm,
  InstallationMessage,
} from "@modules/Trees/components/Form/AddForm/steps/Installation";

function InstalledSensors({ values, setFieldValue, setOpenSensorForm, addedSensor }) {
  const { t } = useTranslation();
  const { data: sensors, refetch: refetchSensors } = useQuery("sensors", getAllSensors, {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: trees } = useQuery("trees", getTrees, {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const tree = trees ? trees.filter((tree) => tree.id === values.treeId)[0] : {};
  const [showInstallationForm, setOpenInstallationForm] = useState(false);
  const [installationItem, setInstallationItem] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  useEffect(() => {
    refetchSensors();
  }, [addedSensor]);
  const addFields = () => {
    setOpenInstallationForm(true);
  };

  return (
    <>
      <InstallationMessage
        tree={tree}
        setIsUpdate={setIsUpdate}
        setOpenForm={setOpenInstallationForm}
        setInstallationItem={setInstallationItem}
      />
      <AddButton
        label={t("installation")}
        action={() => {
          addFields();
        }}
      />
      {tree && (
        <InstallationForm
          show={showInstallationForm}
          setOpenForm={setOpenInstallationForm}
          setIsUpdate={setIsUpdate}
          isUpdate={isUpdate}
          tId={tree.id}
          setFieldValue={setFieldValue}
          sensors={sensors}
          installationItem={installationItem}
          setInstallationItem={setInstallationItem}
          setOpenSensorForm={setOpenSensorForm}
          addedSensor={addedSensor}
        />
      )}
    </>
  );
}

export default InstalledSensors;
