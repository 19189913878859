import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Field, Formik, Form } from "formik";
import { Form as Form2 } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Modal, ModalBody } from "react-bootstrap";
import AddButton from "@components/Button/AddButton";
import Select from "react-select";

import { getSensor } from "@modules/Sensors/services/sensorCrud";
import { addInstallation, updateInstallation } from "../../../../../services/treeCrud";

export default function InstallationForm({
  show,
  tId,
  setOpenForm,
  isUpdate,
  setIsUpdate,
  installationItem,
  setInstallationItem,
  sensors,
  setOpenSensorForm,
  addedSensor,
}) {
  const getValueLabel = (items, value) => {
    const item = items.find((item) => item.value === value);
    return item ? item.name : null; // Return the label or null if not found
  };
  const { t } = useTranslation();
  const [defaultSensor, setDefaultSensor] = useState(null);
  const [sensorId, setSensorId] = useState(null);
  useEffect(() => {
    setDefaultSensor(
      installationItem
        ? {
            value: installationItem.sensor.id,
            label: installationItem.sensor.name,
          }
        : addedSensor
        ? {
            value: addedSensor.id,
            label: addedSensor.name,
          }
        : null
    );
  }, [addedSensor, installationItem, sensorId]);
  const queryClient = useQueryClient();
  const depths = [
    {
      name: "30 cm",
      value: 30,
    },
    {
      name: "60 cm",
      value: 60,
    },
    {
      name: "90 cm",
      value: 90,
    },
    {
      name: "Ballen",
      value: 0,
    },
  ];

  // const query = new URLSearchParams(useLocation().search);

  const handleClose = () => {
    setOpenForm(false);
    setIsUpdate(false);
    setInstallationItem(null);
  };
  const { isLoading, mutateAsync } = useMutation(isUpdate ? updateInstallation : addInstallation, {
    onSuccess: () => {
      queryClient.invalidateQueries("tree", { tid: tId });
      queryClient.invalidateQueries("trees");
      handleClose();
    },
  });

  // const [sensorObj, setSensor] = useState(null);
  useEffect(() => {
    if (addedSensor) {
      setSensorId(addedSensor.id);
    }
  }, [addedSensor]);

  const now = new Date();
  var initialValues = isUpdate
    ? installationItem
    : {
        installed_at: now.toISOString().substring(0, 10),
        removed_at: "",
        sensor: {},
        depth: "",
        height: "",
        tree: { type: "Tree", id: tId },
      };
  // const defaultSensor = () => {
  //   return installationItem
  //     ? {
  //         value: installationItem.sensor.id,
  //         label: installationItem.sensor.name,
  //       }
  //     : addedSensor
  //     ? {
  //         value: addedSensor.id,
  //         label: addedSensor.name,
  //       }
  //     : "";
  // };
  const { data: sensorObj } = useQuery(["sensors", { sensorId }], () => getSensor(sensorId), {
    refetchOnWindowFocus: false,
    retry: 3, // number of times to retry the request before giving up
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // delay between retries in milliseconds
    // retry: false,
    enabled: !!sensorId,
  });
  // setSensor(sensor);

  // useEffect(() => {
  //   setSensorFunc(sensorId);
  // }, [sensorId]);
  let sensorType = "pulse";
  if (sensorObj) {
    sensorType = sensorObj.pulse_sensor
      ? "pulse"
      : sensorObj.soil_moisture_sensor
      ? "soil_moisture"
      : sensorObj.soil_water_tension_sensor
      ? "soil_water_tension"
      : "dendrometer";
  }
  // const SelectItem = ({ setFieldValue, defaultSensor }) => {
  //   return (
  //   );
  // };
  return (
    <>
      <Modal data-testid="maintenance-form-modal" onHide={handleClose} show={show} centered>
        <ModalHeader closeButton>
          <h5 className="mb-0">
            {isUpdate ? `${t("edit")} ${t("installation")}` : `${t("add_installation")}`}
          </h5>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              const formdata = {
                sensor: {
                  type: "Sensor",
                  id: values.sensor.id ? values.sensor.id : addedSensor.id,
                },
                tree: { type: "Tree", id: tId },
              };
              values.installed_at ? (formdata.installed_at = values.installed_at) : null;
              values.removed_at
                ? (formdata.removed_at = values.removed_at)
                : (formdata.removed_at = null);
              let data = { formdata: formdata };
              isUpdate ? (data.id = values.id) : null;
              values.height ? (formdata.height = values.height) : null;
              values.depth ? (formdata.depth = values.depth.id) : null;
              console.log("DATA", data);
              console.log("val", values);
              console.log("addedSensor", addedSensor);
              await mutateAsync(data);
            }}
          >
            {({ setFieldValue, values }) => (
              // const [selectItem, setSelectItem] = useState();
              // useEffect(() => {
              //   setSelectItem(SelectItem({ setFieldValue, defaultSensor }));
              // }, [defaultSensor, addedSensor, values]);
              // return (
              <Form>
                <div style={{ display: "flex" }}>
                  {!addedSensor && setOpenSensorForm && (
                    <AddButton
                      label={"Sensor"}
                      action={() => {
                        setOpenSensorForm(true);
                      }}
                    />
                  )}
                  <div>
                    <label htmlFor="">{t("sensor")}</label>
                    <Select
                      id={`sensor-installation-form`}
                      type={"text"}
                      defaultValue={defaultSensor}
                      value={
                        values.sensor.id
                          ? {
                              value: values.sensor.id,
                              label: values.sensor.name,
                            }
                          : defaultSensor
                      }
                      placeholder={t("select_sensor")}
                      onChange={(option) => {
                        const id = parseInt(option.value);
                        setSensorId(id);
                        return setFieldValue("sensor", { id: id, name: option.label });
                      }}
                      isMulti={false}
                      options={sensors?.map((sensor) => ({
                        value: sensor.id,
                        label: sensor.name,
                      }))}
                    />
                  </div>
                </div>
                <label htmlFor="">{t("installed_at")}</label>
                <Field className="form-control" type="date" name="installed_at" />
                <label htmlFor="">{t("removed_at")}</label>
                <Field className="form-control" type="date" name="removed_at" />
                {((sensorObj && sensorType == "pulse") ||
                  (installationItem && installationItem.height)) && (
                  <div>
                    <Form2.Group className="flex-fill">
                      <Form2.Label>{t("height")}</Form2.Label>
                      <Field
                        type="number"
                        name={"height"}
                        className="form-control mb-3"
                        placeholder={`${t("height")} in m`}
                        min="0"
                      />
                    </Form2.Group>
                  </div>
                )}
                {((sensorObj && sensorType == "soil_water_tension") ||
                  (sensorObj && sensorType == "soil_moisture") ||
                  (installationItem && installationItem.depth)) && (
                  <div>
                    <label htmlFor="">{t("depth")}</label>
                    <Select
                      id={`sensor-installation-depth`}
                      type={"text"}
                      placeholder={t("select_depth")}
                      onChange={(option) => {
                        return setFieldValue("depth", { id: parseInt(option.value) });
                      }}
                      options={depths?.map((depth) => ({
                        value: depth.value,
                        label: depth.name,
                      }))}
                      defaultValue={
                        installationItem
                          ? {
                              value: installationItem.depth,
                              label: getValueLabel(depths, installationItem.depth)
                                ? getValueLabel(depths, installationItem.depth)
                                : null,
                            }
                          : null
                      }
                    />
                  </div>
                )}
                <button type="submit" className="btn btn-outline-primary">
                  {isLoading ? t("saving") : t("save")}
                </button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}
