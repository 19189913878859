import { useTranslation } from "react-i18next";

const EmptyArea = ({ tid, id, serial, dragItem }) => {
  const { t } = useTranslation();
  const getError = () => {
    if (!id) {
      return "looks_like_you_dont_have_selected_area_please_select_the_area_to_get_data";
    } else if (!tid) {
      return "looks_like_you_dont_have_selected_tree_please_select_a_tree_to_get_data";
    } else if (!serial) {
      return "looks_like_you_dont_have_selected_sensor_please_select_a_sensor_to_get_data";
    } else if (!dragItem) {
      return "please_drag_the_variable_to_display_data";
    }
  };
  return (
    <div className="p-3">
      <span>{t(getError())}</span>
    </div>
  );
};

export default EmptyArea;
