import React from "react";
// import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { DropdownButton } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import StatusButton from "@modules/Trees/components/Button/StatusButton";
import { Button } from "react-bootstrap";
import TreeImageForm from "@modules/Trees/components/Form/AddForm/TreeImageForm";
import useChoices from "@hooks/useChoices";
import ReactTable from "@components/ReactTable";
import { AngleDownIcon, ImageAddIconSmall } from "@components/icons";

export default function TreeList({ trees, setAid, setFormValue, setOpenImageForm, openImageForm }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [filterTree, setFilterTree] = React.useState(trees);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [openCT, setOpenCT] = React.useState("");
  const tid = query.get("selectedTree");
  const { pid } = useParams();

  const filt = () => {
    let b = trees.filter((tree) => selectedStatus.includes(tree.health_state));
    setFilterTree(b);
  };
  React.useEffect(() => {
    let trees_ = [];
    for (const tree of trees) {
      trees_.push(tree);
      const openPT = localStorage.getItem("openParentTree");
      if (tree.id == openPT) {
        for (const child of tree.represented_trees) {
          trees_.push(child);
        }
      }
    }
    setFilterTree(trees_);
  }, [openCT]);

  React.useEffect(() => {
    if (selectedStatus.length > 0) {
      filt();
    } else {
      setFilterTree(trees);
    }
  }, [selectedStatus]);

  const filterTreesByStatus = (status) => {
    const findIdx = selectedStatus.indexOf(status);
    if (findIdx > -1) {
      let a = selectedStatus.filter((findIdx) => findIdx !== status);
      setSelectedStatus(a);
    } else {
      setSelectedStatus((prev) => [...prev, status]);
    }
  };

  let statusList = [
    { id: 1, label: "health_states.healthy", status: 0 },
    { id: 2, label: "health_states.drying_out", status: 1 },
    { id: 3, label: "health_states.dry", status: 2 },
    { id: 4, label: "health_states.unknown", status: 3 },
    { id: 5, label: "health_states.frost", status: 4 },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: `${t("tree")}`,
        accessor: "name",
        Cell: (props) => {
          let name = `${props.row.original.name}`;
          const represented_trees = props.row.original.represented_trees;
          const class_name = "mb-0";
          let html;
          if (represented_trees) {
            html = <h5 className={`${class_name}`}>{name}</h5>;
          } else {
            html = <h8 className={`${class_name}`}>{name}</h8>;
          }
          return (
            <>
              <div className="d-flex align-items-center gap-3 w-100">
                <div>{html}</div>
              </div>
            </>
          );
        },
      },
      {
        Header: `${t("area")}`,
        accessor: "area",
      },
      {
        Header: `${t("child_trees")}`,
        accessor: "represented_trees",

        Cell: (props) => {
          const treeId = props.row.original.id;
          if (props.row.original.represented_trees) {
            const childTrees = props.row.original.represented_trees.length;
            if (childTrees > 0) {
              return (
                <>
                  {`${childTrees}  `}
                  <Button
                    status={props.row.original.health_state}
                    width="78px"
                    padding="1px 10px"
                    onClick={() => {
                      const openPT = localStorage.getItem("openParentTree");
                      const { id, areaId } = props.row.original;
                      const tid = id;
                      localStorage.setItem("areaId", areaId);
                      navigate(`/project/${pid}/list?selectedTree=${tid}`);
                      setAid(areaId);
                      onRowClicked(props.row);
                      if (treeId != openPT) {
                        setOpenCT(treeId);
                        localStorage.setItem("openParentTree", treeId);
                      } else {
                        setOpenCT(-1);
                        localStorage.setItem("openParentTree", -1);
                      }
                    }}
                  >
                    {`${t("open")}`}
                  </Button>
                </>
              );
            }
            return <>{`${childTrees}`}</>;
          }
          return <></>;
        },
      },
      {
        Header: `${t("status")}`,
        accessor: "health_state",

        Cell: (props) => {
          const [healthState] = useChoices("dry_state");

          const health_state = healthState?.filter(
            (item) => item.key == props.row.original.health_state
          );
          let treeHealth = health_state && health_state[0] && health_state[0].value;
          let childTree = false;
          if (props.row.original.represented_trees) {
            childTree = true;
          }
          return (
            <>
              <StatusButton
                status={props.row.original.health_state}
                childTree={childTree}
                width="78px"
                padding="1px 10px"
              >
                {treeHealth ? `${t(treeHealth.replace(/\s/g, ""))}` : ""}
              </StatusButton>
            </>
          );
        },
      },
      {
        Header: `${t("actions")}`,
        accessor: "actions",
        style: { width: "80px", textAlign: "center" },
        disableSortBy: true,
        Cell: (props) => {
          return (
            <div className="text-center">
              {
                <span title="Upload Image">
                  <ImageAddIconSmall
                    onClick={() => {
                      setOpenImageForm(true);
                      setFormValue(props.row.original);
                    }}
                  />
                </span>
              }
            </div>
          );
        },
      },
    ],
    []
  );

  const onRowClicked = (row) => {
    const { id, areaId } = row.original;
    const tid = id;
    localStorage.setItem("areaId", areaId);
    navigate(`/project/${pid}/list?selectedTree=${tid}`);
    setAid(areaId);
  };

  return (
    <>
      <ReactTable
        columns={columns}
        data={filterTree}
        onRowClicked={onRowClicked}
        activeId={tid}
        pagesize={10}
        size={[10, 20, 30, 40, 50]}
        moreFilter={
          <>
            <DropdownButton
              title={
                <div className="d-flex gap-2">
                  <span>{t("status")}</span>
                  <AngleDownIcon />
                </div>
              }
              variant="btn-transparent"
            >
              {statusList.map((status, i) => (
                <li key={i} className="d-flex align-items-center gap-2 px-2 py-1">
                  <input
                    type="checkbox"
                    onChange={() => filterTreesByStatus(status.status)}
                    selected={selectedStatus.includes(status.status)}
                  />
                  <StatusButton status={status.status} width="12px" height="12px"></StatusButton>{" "}
                  {t(status.label)}
                </li>
              ))}
            </DropdownButton>
          </>
        }
      />
      {openImageForm && <TreeImageForm show={openImageForm} setOpenForm={setOpenImageForm} />}
    </>
  );
}
