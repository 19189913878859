import jwt_decode from "jwt-decode";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  refreshToken: "[Load refreshToken] Auth API",
  refreshExp: "[Load refreshExp] Auth API",
  SetUser: "[Set User] Action",
};

const initialAuthState = {
  authToken: undefined,
  refreshExp: undefined,
  // refreshToken: undefined,
  user: undefined,
};

export const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const { authToken } = action.payload;
      const user = jwt_decode(authToken);
      return { authToken, user: user };
    }
    case actionTypes.refreshToken: {
      const { refreshToken } = action.payload;
      return { ...state, refreshToken };
    }
    case actionTypes.refreshExp: {
      const { refreshExp } = action.payload;
      return { ...state, refreshExp: refreshExp };
    }
    case actionTypes.UserLoaded: {
      const users = {
        user_id: action.payload.user.user_id,
        username: action.payload.user.username,
        account_type: action.payload.user.account_type,
        email: action.payload.user.email,
        language: action.payload.user.language,
        is_superuser: action.payload.user.is_superuser,
      };
      return { ...state, user: users };
    }
    default:
      return state;
  }
};
export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  refreshToken: (refreshToken) => ({ type: actionTypes.refreshToken, payload: { refreshToken } }),
  refreshExp: (refreshExp) => ({ type: actionTypes.refreshExp, payload: { refreshExp } }),
  logout: () => ({ type: actionTypes.logout }),
  userLoaded: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};
