import React from "react";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { addProjectImage } from "@modules/Projects/services/projectCrud";
import ImageDropzone from "@components/ImageDropzone";
import { ImageAddIcon } from "@components/icons";

function ProjectImage({ isUpdate, selectedProject, newId, setOpenForm, setActiveStep }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation("addProjectImage", addProjectImage, {
    onSuccess: () => {
      queryClient.invalidateQueries("projects");
      setOpenForm(false);
    },
  });

  const saveImage = async (values) => {
    const pId = isUpdate ? selectedProject.id : newId;
    // eslint-disable-next-line no-undef
    const form = new FormData();
    form.append("image", values.image);
    await mutateAsync({ form, pId });
  };
  return (
    <div>
      <Formik
        initialValues={
          isUpdate
            ? {
                image: "",
              }
            : {
                image: "",
              }
        }
        onSubmit={async (values) => saveImage(values)}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <label className="mb-2" htmlFor="">
              {t("Change") + " " + t("image")}
            </label>

            <ImageDropzone
              img={isUpdate ? selectedProject.image : <ImageAddIcon />}
              setFieldValue={setFieldValue}
            />
            <div className="d-flex justify-content-between">
              <Button
                type="button"
                className=" mt-4 me-3"
                onClick={() => setActiveStep((step) => step - 1)}
              >
                {`${t("button.back")}`}
              </Button>
              <Button type="submit" className="  mt-4" variant=" btn-outline-primary">
                {isUpdate
                  ? !isLoading
                    ? `${t("save")}`
                    : "Saving...."
                  : !isLoading
                  ? `${t("save")}`
                  : "Saving..."}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProjectImage;
