import { useTranslation } from "react-i18next";
import { ErrorIcon } from "@components/icons";

export default function FromError({ title }) {
  const { t } = useTranslation();
  return (
    <span className="text-danger form-error">
      <ErrorIcon />
      {t(title)}
    </span>
  );
}
