import "./billing.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { Card, Container, Row, Col, Button } from "react-bootstrap";

export default function Billing() {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const hanldleCheckoutBasic = () => {
    axios
      .post("http://localhost:4242/create-checkout-session", {
        price: 29,
        package: "basic",
        userId: 1,
      })
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
          setMessage("Payment Successful");
        }
      })
      .catch((err) => console.log(err));
  };
  const hanldleCheckoutProfessional = () => {
    axios
      .post("http://localhost:4242/create-checkout-session", {
        price: 50,
        package: "professional",
        userId: 1,
      })
      .then((res) => {
        if (res.data.url) {
          navigate(res.data.url);
          setMessage("Payment Successful");
        }
      })
      .catch((err) => console.log(err));
  };
  const options = [
    { name: "Cloud Demo", price: 21.22, users: 1, sensors: 2 },
    { name: "Cloud S", price: 66.33, users: 2, sensors: 10 },
    { name: "Cloud M", price: 154.04, users: 3, sensors: 25 },
    { name: "Cloud L", price: 503.55, users: 5, sensors: 100 },
  ];
  return (
    <div>
      {message && (
        <section>
          <p>
            {message} <Link to="/login"></Link>
          </p>
        </section>
      )}
      <div style={{ display: "grid", placeItems: "center" }}>
        <Container>
          <Row>
            <Col lg={7} className="m-auto">
              <h2 className="text-center m-5 fw-bold">Choose Package</h2>
              <Row>
                {options.map((option) => {
                  return (
                    <Col lg={6} key={option.name}>
                      <Card role="billing-card">
                        <div className="billing-logo">
                          <img src={process.env.PUBLIC_URL + "/basic.png"} alt="" />
                        </div>
                        <h2 className="text-center pt-3"> {option.name} </h2>
                        <p className="text-center">Discover our solution</p>
                        <div className="plan-price">
                          <p className="price">
                            <span>€</span> {option.price} <span>/ M</span>
                          </p>
                        </div>
                        <ul className="mt-3 ps-3">
                          <li className="py-2">&#10004; Up to {option.sensors} sensors</li>
                          <li className="py-2">&#10004; Up to {option.users} user accounts</li>
                          <li className="py-2">&#10004; Irrigation suggestions</li>
                          <li className="py-2">&#10004; Map view</li>
                          {/* <li className="py-2">&#10004; 1x free data export per month</li> */}
                          <li className="py-4"> </li>
                        </ul>
                        <Button
                          className="py-2 text-uppercase"
                          variant="primary"
                          onClick={() => hanldleCheckoutBasic()}
                        >
                          Select
                        </Button>
                      </Card>
                    </Col>
                  );
                })}
                {/* <Col lg={6}> */}
                {/*   <Card role="billing-card"> */}
                {/*     <div className="billing-logo"> */}
                {/*       <img src={process.env.PUBLIC_URL + "/premium.png"} alt="" /> */}
                {/*     </div> */}
                {/*     <h2 className="text-center pt-3">Cloud S </h2> */}
                {/*     <p className="text-center"> Manage your own IoT for trees </p> */}
                {/**/}
                {/*     <div className="plan-price"> */}
                {/*       <p className="price"> */}
                {/*         <span>€</span>39<span>/ M</span> */}
                {/*       </p> */}
                {/*     </div> */}
                {/*     <ul className="mt-3 ps-3"> */}
                {/*       <li className="py-2"> */}
                {/*         &#10004; Professional data dashboard incl. machine learning predictions */}
                {/*       </li> */}
                {/*       <li className="py-2"> &#10004; Up to 10 user accounts</li> */}
                {/*       <li className="py-2"> */}
                {/*         &#10004; Tree import from tree register: up to 5,000 trees */}
                {/*       </li> */}
                {/*       <li className="py-2"> &#10004; Weather dashboard</li> */}
                {/*       <li className="py-2"> &#10004; Map view</li> */}
                {/*       <li className="py-2"> &#10004; Free data export (unlimited)</li> */}
                {/*     </ul> */}
                {/**/}
                {/*     <Button */}
                {/*       type="submit" */}
                {/*       className="py-2 text-uppercase" */}
                {/*       variant="primary" */}
                {/*       onClick={() => hanldleCheckoutProfessional()} */}
                {/*     > */}
                {/*       Buy now */}
                {/*     </Button> */}
                {/*   </Card> */}
                {/*</Col>*/}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
