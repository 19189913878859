import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { ImageAddIcon } from "@components/icons";

export default function ImageDropzone(props) {
  const [files, setFiles] = useState([]);
  const img_name = props.name ? props.name : "image";

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const pathname = acceptedFiles[0];
      props.setFieldValue(img_name, pathname);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const filePrev = files.map((file) => (
    <div key={file.name}>
      <div>
        <img name="img" alt="" src={file.preview} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Dropzone {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      {files.length === 0 ? (
        <div className="dropzone-default-image">
          <ImageAddIcon />
        </div>
      ) : (
        <>{filePrev}</>
      )}
    </Dropzone>
  );
}

const Dropzone = styled.div`
  border: 1px dashed #ced4d9;
  border-radius: 5px;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  dropzone-default-image {
    height: 50px;
  }
  img {
    height: 240px;
    width: 100%;
  }
`;
