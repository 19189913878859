// import NavItem from "./PublicNavItem";
import { useQuery } from "react-query";
import React, { useState } from "react";
import Topbarglobal from "./Topbarglobal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Nav, Navbar } from "react-bootstrap";
import axiosInstance, { baseURL } from "@helper/interceptor";

function PublicHeader() {
  const { mobile } = useSelector((state) => state.mobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const res = await axiosInstance.get(`${baseURL}logout/`);
      if (res.status === 200) {
        dispatch({ type: "[Logout] Action" });
        navigate("/login");
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <>
      <header className="header">
        <Navbar bg="primary" variant="dark" expand="lg" className="fixed-top p-0 shadow-sm ">
          <Container>
            <div className="col-md-4 col-sm-3"></div>
            <div className="col-md-4 col-sm-6">
              <div className="header__middle">
                <Navbar.Brand aria-label="logo" className="m-0">
                  <img
                    src={process.env.PUBLIC_URL + "/img/treesense-logo.webp"}
                    className="align-middle"
                    height="41"
                    width="90"
                    alt="Treesense Logo"
                  />
                </Navbar.Brand>
              </div>
            </div>
            <div className="col-md-4 col-sm-3"></div>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default React.memo(PublicHeader);
