import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { List } from "@styled-icons/evaicons-solid/List";
import { Stats } from "@styled-icons/boxicons-regular/Stats";
import { MapIcon } from "@components/icons";

export default function ViewTypeButton({ id }) {
  return (
    <div className="text-end">
      <NavLink
        to={`/project/${id}/list`}
        // activeClassName="text-primary"
        className="btn rounded "
        aria-label="list"
        title="List View"
      >
        <MenuIcon view="list" />
      </NavLink>
      <NavLink
        to={`/project/${id}/map`}
        // activeClassName="text-primary"
        className="btn rounded"
        aria-label="map"
        title="Map View"
      >
        <MapIcon view="map" />
      </NavLink>
      <NavLink
        to={`/project/${id}/datascience`}
        // activeClassName="text-primary"
        className="btn rounded  "
        aria-label="datascience"
        title="Datascience View"
      >
        <DataScatterIcon view="datascience" />
      </NavLink>
    </div>
  );
}

const MenuIcon = styled(List)`
  width: 1.5em;
`;
const DataScatterIcon = styled(Stats)`
  width: 1.5em;
`;
