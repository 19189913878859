import React, { useState } from "react";
import styled from "styled-components";
import FormData from "form-data";
import { CSVLink } from "react-csv";
// import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Modal, ModalBody, Spinner, Row, Col } from "react-bootstrap";
import { CloudUploadFill } from "@styled-icons/bootstrap/CloudUploadFill";
import { AnglesRight } from "@styled-icons/fa-solid/AnglesRight";
import { uploadTree, fetchTreeSpecies } from "../../../services/treeCrud";
const templateHeader = [
  { label: "Baumkatasternummer" },
  { label: "Name" },
  { label: "Laengengrad" },
  { label: "Breitengrad" },
  { label: "Baumart" },
  { label: "Hoehe" },
  { label: "Kronendurchmesser" },
  { label: "Phase" },
  { label: "Stammumfang" },
];

export default function ImportForm({ show, handleImportFormClose }) {
  const [selectedFile, setSelectedFile] = useState();
  const [speciesData, setSpeciesData] = useState([]);
  const { t } = useTranslation();
  // const { pid } = useParams();
  const [success, setSuccess] = useState();
  const [fileInfo, setFileInfo] = useState({ name: "", size: "", type: "" });
  const queryClient = useQueryClient();
  const { isLoading, isError, mutateAsync } = useMutation(uploadTree, {
    onSuccess: () => {
      setSuccess("file_upload_successfully");
      queryClient.invalidateQueries();
      handleImportFormClose();
    },
  });

  const handleChange = (e) => {
    const { name, size, type } = e.target.files[0];
    setSelectedFile(e.target.files[0]);
    setFileInfo({ name: name, size: size, type: type });
  };

  const uploadTreeFile = () => {
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    mutateAsync({ formdata });
  };

  useQuery("treeSpecies", fetchTreeSpecies, {
    staleTime: 1000 * 60 * 60,
    onSuccess: (data) => {
      let species = data?.map((speci) => speci.species);
      setSpeciesData(species);
    },
  });

  return (
    <Modal onHide={handleImportFormClose} show={show} centered>
      <ModalHeader closeButton>
        <h5 className="mb-0">{t("import_trees")}</h5>
      </ModalHeader>
      <ModalBody className="">
        <AngleDoubleRightIcon />
        <CSVLink headers={templateHeader} data={[]} filename="template.csv" target="_blank">
          Click to download template.
        </CSVLink>
        <div>
          <AngleDoubleRightIcon />
          <CSVLink data={[speciesData]} filename="species.csv" target="_blank">
            Click to download tree species.
          </CSVLink>
        </div>

        <form>
          {success && <span className="text-success">{t(success)}</span>}
          {isError && <span className="text-danger">{t("unable_to_upload_file")}</span>}
          <br></br>
          <br />
          <Row>
            <Col lg={3}>
              <img width="100px" src={process.env.PUBLIC_URL + "/csv.svg"} />
            </Col>
            <Col lg={9}>
              <label htmlFor="csvfile" className="btn btn-sm  btn-outline-primary ">
                {t("choose_file")}
              </label>
              <input
                id="csvfile"
                type="file"
                onChange={(e) => handleChange(e)}
                style={{ display: "none" }}
              />
              <ul className="p-0 mt-3	">
                <li>
                  <span style={{ fontWeight: "500", marginRight: "4px" }}>{t("name")}:</span>
                  {fileInfo.name ? fileInfo.name : "--"}
                </li>
                <li>
                  <span style={{ fontWeight: "500", marginRight: "4px" }}>{t("size")}:</span>
                  {fileInfo.size ? fileInfo.size : "--"}
                </li>
                <li>
                  <span style={{ fontWeight: "500", marginRight: "4px" }}>{t("type")}:</span>
                  {fileInfo.type ? fileInfo.type : "--"}
                </li>
              </ul>
            </Col>
          </Row>
          <div className="d-flex gap-2 mt-3">
            <button
              type="button"
              className="btn btn-primary d-flex align-items-center "
              onClick={() => uploadTreeFile()}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  <CloudUploadFillIcon />
                  {t("upload")}
                </>
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

const CloudUploadFillIcon = styled(CloudUploadFill)`
  width: 14px;
  margin-right: 5px;
`;
const AngleDoubleRightIcon = styled(AnglesRight)`
  width: 12px;
  margin-right: 5px;
`;
