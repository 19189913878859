import { useState } from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import useChoices from "@hooks/useChoices";
import { useTranslation } from "react-i18next";

const currentYear = new Date().getFullYear();
const generateYearOptions = () => {
  const years = [];
  for (let year = currentYear; year >= 1900; year--) {
    years.push(year);
  }
  return years;
};

export default function MoreInfo({ formField, species }) {
  const { phase, height, specie, trunkCircumference, crownDiameter } = formField;
  const { t } = useTranslation();
  const [phases] = useChoices("growth_phase");
  const [selectedYear, setSelectedYear] = useState("");
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Form.Group className="flex-fill w-25">
          <Form.Label>{t(phase.label)}</Form.Label>
          <Field as="select" className="form-control mb-3" name={phase.name}>
            {phases?.map((phase) => (
              <option key={phase.key} value={phase.key}>
                {t(phase.value)}
              </option>
            ))}
          </Field>
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t("planting_year")}</Form.Label>
          {/* <Form.Control as="select" value={selectedYear} onChange={handleYearChange}>
            <option value="">{t("select_year")}.</option>
            {generateYearOptions().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Form.Control> */}
          <Field as="select" className="form-control mb-3" name="planting_year">
            <option value="" disabled>
              {t("select_year")}
            </option>
            {generateYearOptions().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Field>
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(height.label)}</Form.Label>
          <Field
            type="number"
            name={height.name}
            className="form-control mb-3"
            placeholder={`${t("height")} in m`}
            min="0"
          />
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(specie.label)}</Form.Label>
          <Field as="select" className="form-control  form-select mb-3" name="species">
            <option value="" disabled>
              {t("latin_name")} | {t("german_name")}
            </option>
            {species?.map((speciesItem) => {
              return (
                <option key={speciesItem.name} value={speciesItem.name}>
                  {`${speciesItem.name} | ${speciesItem.de_name}`}
                </option>
              );
            })}
          </Field>
        </Form.Group>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Form.Group className="flex-fill">
          <Form.Label htmlFor="">{t(trunkCircumference.label)}</Form.Label>
          <Field
            type="number"
            name={trunkCircumference.name}
            className="form-control mb-3"
            placeholder={`${t("trunk_circumference")} in cm`}
          />
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(crownDiameter.label)}</Form.Label>
          <Field
            type="number"
            name={crownDiameter.name}
            className="form-control mb-3"
            placeholder={`${t("crown_diameter")} in m`}
          />
        </Form.Group>
      </div>
    </>
  );
}
