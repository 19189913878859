import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QrScanIcon } from "@components/icons";
import { useSelector } from "react-redux";
import { Html5QrcodeScanner } from "html5-qrcode";

export default function QRForm({ data, setData }) {
  const { t } = useTranslation();
  const { mobile } = useSelector((state) => state.mobile);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setData("");
    setShow(false);
  };

  const videoConstraints = {
    facingMode: { ideal: "environment" },
  };

  useEffect(() => {
    if (show) {
      const isMobile = mobile || window.innerWidth <= 767;
      const scanner = new Html5QrcodeScanner("qr-reader", {
        fps: 10,
        qrbox: isMobile ? { width: 150, height: 150 } : { width: 250, height: 250 },
        videoConstraints,
      });

      scanner.render(
        (result) => {
          setData(result);
          scanner.clear();
          setShow(false);
        },
        (error) => {
          console.error(`QR code scan error: ${error}`);
        }
      );

      return () => {
        scanner.clear();
      };
    }
  }, [show, setData, mobile]);

  return (
    <>
      <Button
        data-testid="qr-button"
        className="btn btn-primary"
        style={{ marginTop: "5px" }}
        onClick={() => setShow(true)}
      >
        <QrScanIcon title="QR Scanner" />
      </Button>
      <Modal show={show} onHide={handleClose} className="m-0">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0 m-0 ">
          <div className="qr-text-overlay">
            <span>{t("align_qr_code_within_the_frame")}</span>
          </div>

          <div id="qr-reader" className="qr-reader-container" />
        </Modal.Body>
      </Modal>
    </>
  );
}
