import React from "react";
import { Link } from "react-router-dom";
import "./Error.css";

export default function PageNotFound() {
  return (
    <div data-testid="notfound-404" className="notfound">
      <div className="notfound">
        <div className="notfound-404" style={{ backgroundImage: ` url("/favicon.ico")` }}></div>
        <h1>404</h1>
        <h2>Oops! Page Not Be Found</h2>
        <p>
          Sorry but the page you are looking for does not exist, have been removed. name changed or
          is temporarily unavailable
        </p>
        <Link to="/dashboard">Back to homepage</Link>
      </div>
    </div>
  );
}
