import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Installation({ formField, setFieldValue, values, data, sensorType = "pulse" }) {
  const { t } = useTranslation();
  const sensorTypes = [
    {
      name: "pulse_sensor",
    },
    {
      name: "soil_moisture_sensor",
    },
    {
      name: "dendrometer_sensor",
    },
  ];
  var defaultValue = "";
  if (data.soil_moisture_sensor != undefined) {
    defaultValue = "soil_moisture_sensor";
  } else if (data.dendrometer_sensor != undefined) {
    defaultValue = "dendrometer_sensor";
  } else {
    defaultValue = "pulse_sensor";
  }

  return (
    <>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>{t("sensor_type")}</Form.Label>
        {/* <Field type="text" name="name" className={"form-control mb-3"} /> */}
        <Select
          id={"sensorType"}
          type={"text"}
          defaultValue={{
            value: defaultValue,
            label: t(`${defaultValue}`),
          }}
          onChange={(option) => {
            setFieldValue(formField, option.value);
          }}
          options={sensorTypes.map((sensorType) => ({
            value: sensorType.name,
            label: t(sensorType.name),
          }))}
        />
      </Form.Group>
    </>
  );
}

export default Installation;
