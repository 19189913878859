import axiosInstance, { onRequestError } from "@helper/interceptor";
import { store } from "../../../redux/store";

export const getAllProjects = async () => {
  let response = await axiosInstance.get("projects-general/");
  return response.data;
};

export const getProject = async (pid) => {
  let response = await axiosInstance.get(`projects/${pid}/`);
  if (response.status === 200) return response.data;
};

export const getPublicProject = async (pid) => {
  let response = await axiosInstance.get(`public/projects/${pid}/`);
  if (response.status === 200) return response.data;
};

export const createProject = async (formData) => {
  let response = await axiosInstance.post("projects/", formData);
  return response.data;
};

export const updateProject = async (form) => {
  let data = form.formData;
  let response = await axiosInstance.patch(`projects/${form.id}/`, data);
  return response.data;
};

export const deleteProject = async (id) => {
  let response = await axiosInstance.delete(`projects/${id}/`);
  return response.data;
};

export const onRequest = (config) => {
  const states = store.getState();

  const accessToken = states.auth.authToken;
  config.headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "JWT " + accessToken,
  };
  return config;
};

export const addProjectImage = async ({ form, pId }) => {
  const onRequestOriginal = axiosInstance.interceptors.request.handlers[0].fulfilled;
  const onRequestErrorOriginal = axiosInstance.interceptors.request.handlers[0].rejected;
  axiosInstance.interceptors.request.handlers = [];
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  let response = await axiosInstance.post(`projects/${pId}/upload-image`, form);
  axiosInstance.interceptors.request.handlers = [];
  axiosInstance.interceptors.request.use(onRequestOriginal, onRequestErrorOriginal);
  return response.data;
};

export const removeProjectTree = async ({ data, pid }) => {
  let response = await axiosInstance.patch(`remove-tree-project/${pid}/`, data);
  return response.data;
};
