import { useDrop } from "react-dnd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DragDropIcon } from "@components/icons";
import { DataScienceContext } from "@modules/DataScience/context/DataScienceContext";

function Dustbin({ dust }) {
  const {
    setDragItemLeft,
    setDragItemRight,
    setPositionLeft,
    setPositionRight,
    draggableClicked,
    setDraggableClicked,
  } = useContext(DataScienceContext);
  const { t } = useTranslation();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "list",
    drop: (item) => dropSensor(item.name, drop),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  if (isOver || canDrop) {
    setDraggableClicked(false);
  }
  const dropSensor = (name) => {
    if (dust.name == "left") {
      setPositionLeft(true);
      setDragItemLeft(name);
    }
    if (dust.name == "right") {
      setPositionRight(true);
      setDragItemRight(name);
    }
  };

  return (
    <>
      {(canDrop || draggableClicked) && (
        <div className="position-relative" ref={drop} style={{ zIndex: 9 }} value={dust.name}>
          {(isOver || draggableClicked) && (
            <div className={` drop `}>
              <div className="text-center mb-3 ">
                <DragDropIcon size={"30px"} />
                <br />
                <br />
                <h3>{t(`drop_${dust.name}`)}</h3>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Dustbin;
