import Skeleton from "./Skeleton";
import { useQuery } from "react-query";
import useFilter from "@hooks/useFilter";
import { Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { constants } from "../context/constants";
import { TriangleUpIcon } from "@components/icons";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { getProject } from "@modules/Projects/services/projectCrud";
import { DataScienceContext } from "../context/DataScienceContext";
import { styles } from "@components/styles";

function AreaList() {
  const { pid, filterState, filterDispatch } = useContext(DataScienceContext);
  const { areasId, areas, treesId, sensorId, tree_sensor_pairs } = filterState;
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const [filterTerm, setFilterTerm] = useState("");
  const [open, setOpen] = useState(true);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { t } = useTranslation();
  const id = query.get("areas");

  const filteredAreas = useMemo(
    () => useFilter({ filterTerm, filterItem: areas }),
    [filterTerm, areasId, areas]
  );

  //1. fetch the area related to particular project
  const { isLoading } = useQuery(["ds_project", pid], () => getProject(pid), {
    refetchOnWindowFocus: false,
    enabled: !!pid,
    staleTime: 1000 * 60 * 60,
    onSuccess: (data) => {
      filterDispatch({ type: constants.SET_PROJECT_AREAS, payload: data.areas });
    },
  });

  /*2. load the areaid from url and set to areasIds.This function runs once the page reload. 
    This will persist the selected or previously checked tree.
  */
  useEffect(() => {
    if (id) {
      const areasIds = id.split(",").map(Number);
      filterDispatch({ type: constants.SET_CHECKED_AREAS_ID, payload: areasIds });
    }
  }, []);

  //3. set url based on area checked
  useEffect(() => {
    if (areasId.length == 0) {
      navigate(`/project/${pid}/datascience`);
    } else navigate(`/project/${pid}/datascience?areas=` + areasId.join(","));
  }, [areasId]);

  // checks whether all areas are selected whenever areasId changes
  useEffect(() => {
    const allSelected = areas.length > 0 && areas.every((area) => areasId.includes(area.id));
    setSelectAllChecked(allSelected);
  }, [areasId, areas]);

  //4. This function used to set the value for tree search on key change to input field.
  function updateFilterHandler(event) {
    setFilterTerm(event.target.value);
  }

  //5. Check and uncheck functionalities to set and filter sensor from sensor list
  const handleChange = (props) => {
    const { e, area } = props;
    if (e.target.checked) {
      filterDispatch({ type: constants.SET_AREAS_ID, payload: [area.id] });
    }
    if (e.target.checked === false) {
      //1.filter  area on area unchecked
      let filteredArea = areasId.filter((id) => id !== area.id);
      filterDispatch({ type: constants.SET_CHECKED_AREAS_ID, payload: filteredArea });

      //2. filter tree on area unchecked
      let filteredTree = treesId.filter((id) => {
        let arr = area.tree_set.map((tree) => tree.id);
        return !arr.includes(id);
      });
      filterDispatch({ type: constants.SET_CHECKED_TREES_ID, payload: filteredTree });

      //3.filter particular area->tree sensors on area un checked
      let filteredSensor = area.tree_set
        .map((tree) =>
          tree.installations.map((installation) => installation.sensor.hardware_serial)
        )
        .flat();
      let tree_sensors = area.tree_set
        .map((tree) =>
          tree.installations.map((installation) => {
            const sensor = installation.sensor;
            return {
              tree: tree.id,
              sensor: sensor.hardware_serial,
              sensor_name: sensor.name,
              tree_name: tree.name,
            };
          })
        )
        .flat();
      let filteredS = sensorId.filter((serial) => !filteredSensor.includes(serial));
      const filtered_tree_sensors_pairs = tree_sensor_pairs.filter((item) => item !== tree_sensors);
      filterDispatch({
        type: constants.SET_FILTERED_HARDWARE_SERIAL,
        payload: [filteredS, filtered_tree_sensors_pairs],
      });
    }
  };

  // 6.
  const handleSelectAll = ({ e, filteredAreas }) => {
    if (e.target.checked) {
      const ids = filteredAreas.map((area) => area.id);
      filterDispatch({ type: constants.SET_AREAS_ID, payload: ids });
      setSelectAllChecked(true);
    } else {
      filterDispatch({ type: constants.SET_CHECKED_AREAS_ID, payload: [] });
      filterDispatch({ type: constants.SET_CHECKED_TREES_ID, payload: [] });
      filterDispatch({ type: constants.SET_FILTERED_HARDWARE_SERIAL, payload: [[], []] });
      setSelectAllChecked(false);
    }
  };

  return (
    <div className="me-3">
      <div className="d-flex pe-2 justify-content-between">
        <div
          className={styles.dsFilterTitle}
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <TriangleUpIcon open={open} /> <span className=" ds-filter-title">{t("areas")}</span>
        </div>
        <div>
          <input
            type="checkbox"
            checked={selectAllChecked}
            onChange={(e) => handleSelectAll({ e, filteredAreas })}
          />
        </div>
      </div>
      <Collapse in={open}>
        <div>
          <div className="py-2 pe-2">
            <input
              type="text"
              className="form-control filter-search"
              placeholder="Search tree"
              onChange={updateFilterHandler}
            />
          </div>
          {isLoading ? (
            <>
              {[1, 2, 3, 4, 5].map((a, i) => (
                <Skeleton key={i} />
              ))}
            </>
          ) : (
            <ul className="p-0 pe-2 filter__list" id="example-collapse-text">
              {filteredAreas?.map((area) => (
                <li
                  key={area.id}
                  className={`filter__items  gap-1 d-flex justify-content-between ps-1`}
                >
                  {area.name}
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      handleChange({ e, area });
                    }}
                    checked={areasId.includes(area.id)}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default React.memo(AreaList);
