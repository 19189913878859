import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { baseURL } from "@helper/interceptor";

async function getChoices() {
  const response = axios.get(`${baseURL}static/choices.json`);
  return response;
}

export default function useChoices(x) {
  const [choiceType, setChoiceType] = useState(x);
  const [choicedata, setChoiceData] = useState();

  const setChoice = (val) => {
    setChoiceType(val);
  };

  useQuery("choice", getChoices, {
    refetchOnWindowFocus: false,
    enabled: !!choiceType,
    onSuccess: async (data) => {
      let choices = data.data;

      let filtered = Object.values(
        Object.fromEntries(Object.entries(choices).filter((key) => key[0] === choiceType))
      );
      if (filtered.length > 0) {
        let val =
          filtered &&
          Object.keys(filtered[0]).map((key) => {
            return { key: key, value: filtered[0][key] };
          });
        setChoiceData(val);
      }
    },
  });

  return [choicedata, setChoice];
}
