import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useMutation, useQueryClient } from "react-query";
import { Button, Modal, ModalBody } from "react-bootstrap";
import userFormModel from "./FormModel/userFormModel";
import { validation } from "./FormModel/validationSchema";
import { BasicInfo, UserAddress, UserProfile } from "./steps";
import { createUser, updateUser } from "../../services/userCrud";
import { formInitialValues, formUpdateValues } from "./FormModel/initialValues";
import LoginAccount from "./steps/LoginAccount";
import { AlertCircleOutlineIcon } from "@components/icons";
import { styles } from "@components/styles";

const { formField } = userFormModel;

const steps = [
  "user_form.basic_info",
  "user_form.login_credentials",
  "user_form.profile",
  "user_form.address",
];

const renderSteps = (steps, errors, isUpdate, setFieldValue, values) => {
  switch (steps) {
    case 0:
      return <BasicInfo formField={formField} />;
    case 1:
      return (
        <LoginAccount
          formField={formField}
          validation={validation}
          errors={errors}
          isUpdate={isUpdate}
        />
      );
    case 2:
      return <UserProfile formField={formField} setFieldValue={setFieldValue} values={values} />;
    case 3:
      return <UserAddress formField={formField} />;
    default:
      return <>NotFound</>;
  }
};

export default function UserForm({ isUpdate, typeDetail, show, handleClose }) {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isAddressError, setIsAddressError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;

  const { isLoading, isError, error, mutateAsync } = useMutation(
    isUpdate ? "updateUser" : "addUser",
    isUpdate ? updateUser : createUser,
    {
      onSuccess: () => {
        handleClose();
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries(["user", id]);
      },
      onError: (error) => {
        if (error.response.data) {
          setIsAddressError(false);
        }
      },
    }
  );

  // add User
  const addUser = async (user) => {
    const data = {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      password: user.password,
      profile: {
        phoneNumber: user.phoneNumber,
        accountType: user.accountType,
        areas: user.areas,
      },
    };
    if (
      user.streetName.length === 0 &&
      user.houseNumber.length === 0 &&
      user.zipCode.length === 0 &&
      user.city.length === 0 &&
      user.country.length === 0
    ) {
      setIsAddressError(false);
      await mutateAsync(data);
    } else if (
      user.streetName.length !== 0 &&
      user.houseNumber.length !== 0 &&
      user.zipCode.length !== 0 &&
      user.city.length !== 0 &&
      user.country.length !== 0
    ) {
      setIsAddressError(false);
      data.profile["address"] = {
        streetName: user.streetName,
        houseNumber: user.houseNumber,
        zipCode: user.zipCode,
        city: user.city,
        country: user.country,
        areas: user.areas,
      };
      await mutateAsync(data);
    } else {
      setIsAddressError(true);
    }
  };

  //user update
  const updateExistUser = async (values) => {
    const areas = values.areas;
    let modifiedAreas = areas.map((area) =>
      Object.keys(area)
        .filter((key) => key.includes("id"))
        .reduce((obj, key) => {
          obj[key] = area[key];
          return obj;
        }, {})
    );

    let formData = {
      id: typeDetail.id,
      profile: {
        address: {},
        areas: modifiedAreas ? modifiedAreas : [],
      },
    };

    // add password key on form submission only when password input change
    if (values.password.length > 0) {
      formData["password"] = values.password;
    }

    const keys = Object.keys(formInitialValues);
    // eslint-disable-next-line array-callback-return
    keys.map((key) => {
      if (formUpdateValues[key] !== values[key]) {
        if (key === "phoneNumber" || key === "accountType") {
          formData.profile[key] = values[key];
        } else if (key === "first_name" || key === "last_name" || key === "email") {
          formData[key] = values[key];
        } else if (
          key == "streetName" ||
          key == "houseNumber" ||
          key == "zipCode" ||
          key == "city" ||
          key == "country"
        ) {
          if (
            values.streetName.length === 0 &&
            values.houseNumber.length === 0 &&
            values.zipCode.length === 0 &&
            values.city.length === 0 &&
            values.country.length === 0
          ) {
            setIsAddressError(false);
            mutateAsync(formData);
          } else if (
            values.streetName.length !== 0 &&
            values.houseNumber.length !== 0 &&
            values.zipCode.length !== 0 &&
            values.city.length !== 0 &&
            values.country.length !== 0
          ) {
            setIsAddressError(false);
            formData.profile.address[key] = values[key];
            mutateAsync(formData);
          } else {
            setIsAddressError(true);
          }
        }
      }
    });
  };

  const handleSubmit = (values) => {
    if (isLastStep) {
      isUpdate ? updateExistUser(values) : addUser(values);
    }
    !isLastStep && setActiveStep(activeStep + 1);
  };

  const getErrorMessage = (error) => {
    if (error.response.data) {
      // Adjust this based on your actual error response structure
      if (Array.isArray(error.response.data.non_field_errors)) {
        return error.response.data.non_field_errors.join(" ");
      }
      if (error.response.data.detail) {
        return error.response.data.detail;
      }
      return Object.values(error.response.data).flat().join(" ");
    }
    return null;
  };

  return (
    <>
      <Modal onHide={handleClose} show={show} centered>
        <ModalHeader closeButton>
          <h5 className="mb-0">{isUpdate ? `${t("edit_user")} ` : `${t("add_user")}`}</h5>
        </ModalHeader>
        <ModalBody>
          <div className={styles.wizardList}>
            {steps.map((step, i) => (
              <span key={step} className={activeStep == i ? "wizard-active" : ""}>
                {t(step)}
              </span>
            ))}
          </div>
          <Formik
            initialValues={isUpdate ? formUpdateValues(typeDetail) : formInitialValues}
            enableReinitialize
            onSubmit={async (values) => {
              handleSubmit(values);
            }}
          >
            {({ handleSubmit, errors, setFieldValue, values }) => {
              return (
                <>
                  <p className={styles.validationError}>
                    {Object.keys(errors).length > 0 && (
                      <>
                        <AlertCircleOutlineIcon />
                        {t(errors.email) || t(errors.password)}
                      </>
                    )}
                    {isError && error.response.data && error.response.data.profile && (
                      <>
                        <AlertCircleOutlineIcon />
                        {t("validation.invalid_house_number") + " " + "(< 32767)"}
                      </>
                    )}
                    {isAddressError && (
                      <>
                        <AlertCircleOutlineIcon />
                        {t("validation.all_address_field_are_required")}
                      </>
                    )}
                    {isError && error.response.data && error.response.data.email && (
                      <>
                        <AlertCircleOutlineIcon />
                        {t("validation.user_with_this_email_already_exist")}
                      </>
                    )}
                    {isError &&
                      error.response.data &&
                      getErrorMessage(error) === "User limit exceeded for your package" && (
                        <>
                          <AlertCircleOutlineIcon />
                          {t("validation.user_limit_exceeded_for_your_package")}
                        </>
                      )}
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <div className="form-container">
                      {renderSteps(activeStep, errors, isUpdate, setFieldValue, values)}
                    </div>
                    <div className={styles.formBody}>
                      {activeStep !== 0 && (
                        <Button
                          type="button"
                          variant="outline-primary"
                          onClick={() => setActiveStep((step) => step - 1)}
                        >
                          {t("button.back")}
                        </Button>
                      )}
                      <Button type="submit" className="btn  ms-auto" variant="outline-primary">
                        {isLastStep
                          ? isUpdate
                            ? isLoading
                              ? t("button.updating")
                              : t("button.update")
                            : isLoading
                            ? t("button.saving")
                            : t("button.save")
                          : t("button.next")}
                      </Button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}
