/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import * as FileSaver from "file-saver";
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "@components/icons";
import { fetchDownloadData } from "@modules/Trees/services/treeCrud";
// import { forEach } from "lodash";

const usefetchCheckState = ({ parameters }) => {
  const checkedParameters = parameters.filter((parameter) => parameter.checked);
  const checkedNames = checkedParameters.map((parameter) => parameter.name);
  const parameterQuery = checkedNames.join(",");
  return { parameterQuery: parameterQuery, checkedNames: checkedNames };
};
export const ExportData = ({ sensors, fileName, setSuccessMessage, parameters }) => {
  const [plotData, setPlotData] = useState([]);
  const [getData, setGetData] = useState(false);
  const { parameterQuery, checkedNames } = usefetchCheckState({ parameters });
  //fetch the sensor data from api once the button is clicked
  const sensor_ids = sensors.map((sensor) => sensor.id);
  const { isLoading, isRefetching } = useQuery(
    parameterQuery,
    () => fetchDownloadData({ sensors: sensor_ids, parameterQuery }),
    {
      refetchOnWindowFocus: false,
      enabled: !!getData,
      onSuccess: (res) => {
        const { data, mean } = res;
        sensors.map((sensor) => {
          const key = sensor.id;
          const newData = { name: sensor.name, data: [] };
          if (data[key] !== undefined) {
            data[key].time.map((tim, index) => {
              let dataObj = {
                time: tim,
              };
              checkedNames.forEach((parameter) => {
                if (data[key][parameter] !== undefined) {
                  dataObj[parameter] = data[key][parameter][index];
                }
              });
              dataObj["time_mean"] = mean[key].time[index];
              checkedNames.forEach((parameter) => {
                if (mean[key][parameter] !== undefined) {
                  dataObj[parameter + "_mean"] = mean[key][parameter][index];
                }
              });
              newData.data.push(dataObj);
            });
            setPlotData((prev) => [...prev, newData]);
          }
        });
        setSuccessMessage("File has been downloaded successfully!");
        setGetData(false);
      },
    }
  );

  fileName = fileName ? fileName : "Unknown";
  const { t } = useTranslation();

  //ready to export data once we do have data inside plotDataState
  useEffect(() => {
    if (plotData.length > 0) {
      if (!isLoading) {
        exportToExcel({ plotData, fileName, setPlotData });
      }
    }
  }, [plotData]);

  return (
    <div className="d-flex align-items-center gap-2">
      <Button variant="outline-primary d-flex align-items-center" onClick={() => setGetData(true)}>
        <>
          <DownloadIcon />
          {t("Download")} &nbsp;
          {(isLoading || isRefetching) && <Spinner animation="border" size="sm" />}
        </>
      </Button>
    </div>
  );
};

const exportToExcel = ({ plotData, fileName, setPlotData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  var wb = XLSX.utils.book_new();
  plotData?.map((data) => {
    var wsPersonDetails = XLSX.utils.json_to_sheet(data.data);
    XLSX.utils.book_append_sheet(wb, wsPersonDetails, `${data.name}`);
  });
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  // // eslint-disable-next-line no-undef, no-undef
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
  setPlotData([]);
};

// export const ExportResistanceByDate = ({
//   hardwareSerial,
//   fileName,
//   startDate,
//   endDate,
//   setSuccessMessage,
// }) => {
//   const { t } = useTranslation();
//   const [plotData, setPlotData] = useState([]);
//   const [getData, setGetData] = useState(false);

//   fileName = fileName ? fileName : hardwareSerial;
//   const { isLoading } = useQuery(
//     "resistance_download",
//     () => fetchResistanceByDateDownload({ hardwareSerial, startDate, endDate, setPlotData }),
//     {
//       refetchOnWindowFocus: false,
//       enabled: !!getData,
//       onSuccess: (res) => {
//         const { data, mean } = res;
//         const newData = { name: hardwareSerial, data: [] };
//         data[hardwareSerial].time.map((tim, index) => {
//           newData.data.push({
//             time: tim,
//             resistance: data[hardwareSerial].resistance[index],
//             time_mean: mean[hardwareSerial].time[index],
//             resistance_mean: mean[hardwareSerial].resistance[index],
//           });

//           setPlotData((prev) => [...prev, newData]);
//         });
//         setSuccessMessage("File has been downloaded successfully!");
//         setGetData(false);
//       },
//       onError: () => {
//         setGetData(false);
//       },
//     }
//   );
//   useEffect(() => {
//     if (plotData.length > 0) {
//       if (!isLoading) {
//         exportResistance({ plotData, fileName, setGetData, isLoading });
//       }
//     }
//   }, [plotData]);
//   return (
//     <div className="d-flex align-items-center gap-2">
//       <Button variant="outline-primary d-flex align-items-center" onClick={() => setGetData(true)}>
//         <>
//           <DownloadIcon />
//           {t("Download")} &nbsp;
//           {isLoading && <Spinner animation="border" size="sm" />}
//         </>
//       </Button>
//     </div>
//   );
// };
// const exportResistance = ({ plotData, fileName }) => {
//   const fileType =
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".csv";
//   const ws = XLSX.utils.json_to_sheet(plotData[0].data);
//   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//   const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
//   // eslint-disable-next-line no-undef
//   const data = new Blob([excelBuffer], { type: fileType });
//   FileSaver.saveAs(data, fileName + fileExtension);
// };
