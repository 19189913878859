import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { validation } from "./FormModel/validationSchema";
import { useMutation, useQueryClient } from "react-query";
import { ProjectImage, ProjectProfile } from "./steps";
import { Button, Form, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { createProject, updateProject } from "../../services/projectCrud";
import { styles } from "@components/styles";

const steps = ["projectname", "image"];

const renderSteps = (activeStep) => {
  switch (activeStep) {
    case 0:
      return <ProjectProfile validation={validation} />;
    case 1:
      return <>Not Found</>;
  }
};

function ProjectForm({ show, isUpdate, setIsUpdate, setOpenForm, selectedProject }) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [newId, setNewId] = useState();
  const isLastStep = activeStep === steps.length - 1;
  const isSecondLastStep = activeStep === steps.length - 2;
  const queryClient = useQueryClient();

  const handleClose = () => {
    setIsUpdate && setIsUpdate(false);
    setOpenForm(false);
  };

  const { isLoading, mutateAsync: projectMutate } = useMutation(
    isUpdate ? "updateProject" : "createproject",
    isUpdate ? updateProject : createProject,
    {
      onSuccess: (data) => {
        setNewId(data.id);
        !isLastStep && setActiveStep(activeStep + 1);
        queryClient.invalidateQueries("projects");
      },
    }
  );

  const handleSubmit = (values) => {
    if (isSecondLastStep) {
      isUpdate
        ? projectMutate({ formData: values, id: selectedProject.id })
        : projectMutate(values);
    }
  };
  return (
    <Modal data-testid="project-modal" onHide={handleClose} show={show} centered>
      <ModalHeader closeButton>
        <h5 className="mb-0">
          {isUpdate ? `Update ${selectedProject.name}` : `${t("newproject")} `}
        </h5>
      </ModalHeader>
      <ModalBody>
        <div className={styles.wizardList}>
          {steps.map((step, i) => (
            <span key={step} className={activeStep == i ? "wizard-active" : ""}>
              {t(step)}
            </span>
          ))}
        </div>
        {!isLastStep ? (
          <Formik
            initialValues={
              isUpdate
                ? {
                    name: selectedProject.name,
                    start: selectedProject.start,
                    duration_month: selectedProject.duration_month,
                  }
                : { name: "" }
            }
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, errors, setFieldValue, handleSubmit }) => {
              return (
                <>
                  <span className="text-danger">{errors && errors.name}</span>
                  <Form onSubmit={handleSubmit}>
                    <div className="form-container">
                      {renderSteps(activeStep, values, setFieldValue, isUpdate, selectedProject)}
                    </div>
                    <div className="d-flex align-items-center justify-content-between pt-3">
                      {activeStep !== 0 && (
                        <Button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => setActiveStep((step) => step - 1)}
                        >
                          {t("button.back")}
                        </Button>
                      )}
                      <button type="submit" className="btn btn-outline-primary ms-auto">
                        {isSecondLastStep
                          ? isUpdate
                            ? isLoading
                              ? t("button.updating")
                              : t("button.update")
                            : isLoading
                            ? t("button.saving")
                            : t("button.save")
                          : t("button.next")}
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        ) : (
          <ProjectImage
            selectedProject={selectedProject}
            isUpdate={isUpdate}
            newId={newId}
            setOpenForm={setOpenForm}
            setActiveStep={setActiveStep}
          />
        )}
      </ModalBody>
    </Modal>
  );
}

export default ProjectForm;
