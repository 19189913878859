import { useState } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import ImportForm from "../Form/ImportForm";
import { useTranslation } from "react-i18next";
import { FileExcel } from "@styled-icons/icomoon/FileExcel";

export const ImportTreeButton = () => {
  const [showImportform, setShowImportForm] = useState(false);
  const { t } = useTranslation();
  const handleImportFormClose = () => {
    setShowImportForm(false);
  };

  const handleImportFormShow = () => setShowImportForm(true);
  return (
    <>
      <Button
        className="col-auto btn d-flex align-items-center"
        onClick={handleImportFormShow}
        variant={"outline-primary"}
      >
        <FileExcelIcon />
        {t("import_trees")}
      </Button>
      <ImportForm handleImportFormClose={handleImportFormClose} show={showImportform} />
    </>
  );
};

const FileExcelIcon = styled(FileExcel)`
  width: 0.75rem;
  margin-right: 5px;
`;
