import axiosInstance from "../../../helper/interceptor";

// export const getTree = async ({tree_id}) => {
//     const response = await axiosInstance.get(`/api/trees/${tree_id}` );
//     return response.data
// };

export const getTreesSpecies = async () => {
  let response = await axiosInstance.get("tree-species/");
  return response.data;
};
export const createTreeSpecies = async (formData) => {
  let response = await axiosInstance.post("tree-species/", formData);
  return response.data;
};
export const updateTreeSpecies = async ({ formData, typeDetail }) => {
  let response = await axiosInstance.put(`tree-species/${typeDetail.species}/`, formData);
  return response.data;
};
export const deleteTreeSpecies = async (id) => {
  let response = await axiosInstance.delete(`tree-species/${id}`);
  return response.data;
};
