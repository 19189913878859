import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import IrrigationAreaList from "./IrrigationAreaList";
import IrrigationAreaDetail from "./IrrigationAreaDetail";
import IrrigationAreaForm from "./Form/IrrigationAreaForm";
import { getAllIrrigationAreas } from "../services/areaCrud";
import NoSelected from "@components/NoSelected";
import Spinner from "@components/Spinner/Spinner";
import AddButton from "@components/Button/AddButton";
import ServerError from "@components/Error/ServerError";
import { Breadcrumb, MobileBreadcrumb } from "@components/Breadcrumb";

function IrrigationAreaPage() {
  const { mobile } = useSelector((state) => state.mobile);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [detail, setDetail] = useState();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("selectedArea");
  //retrieving all sensors list
  const {
    data: areas,
    isError,
    isLoading,
  } = useQuery("areas", getAllIrrigationAreas, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  //delete sensor function
  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError) return <ServerError />;
  return (
    <>
      {mobile ? (
        <MobileBreadcrumb
          title={`${t("irrigationArea")}`}
          addButton={
            <AddButton label={"button.add_irrigation_area"} action={() => setOpenForm(true)} />
          }
        />
      ) : (
        <Breadcrumb
          title={`${t("irrigationArea")}`}
          addButton={
            <AddButton label={"button.add_irrigation_area"} action={() => setOpenForm(true)} />
          }
        />
      )}
      <Container>
        <Row>
          <Col lg="7">
            <IrrigationAreaList
              areas={areas}
              setIsUpdate={setIsUpdate}
              setDetail={setDetail}
              setOpenForm={setOpenForm}
            />
          </Col>
          <Col lg="5">
            {!id ? (
              <NoSelected text="sensor_details.no_sensor_selected" />
            ) : (
              <IrrigationAreaDetail
                setIsUpdate={setIsUpdate}
                setDetail={setDetail}
                setOpenForm={setOpenForm}
              />
            )}
          </Col>
        </Row>
      </Container>
      <IrrigationAreaForm
        isUpdate={isUpdate}
        detail={detail}
        setIsUpdate={setIsUpdate}
        show={openForm}
        setOpenForm={setOpenForm}
        selectedArea={detail}
      />
    </>
  );
}

export default IrrigationAreaPage;
