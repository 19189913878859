import axiosInstance, { onRequestError } from "@helper/interceptor";
import { onRequest } from "../../Projects/services/projectCrud";

export const getAllCustomers = async () => {
  let response = await axiosInstance.get("customers");
  return response.data;
};
export const getCustomerBasicInfo = async () => {
  let response = await axiosInstance.get("customer-basic-info");
  return response.data;
};
export const getCustomer = async (id) => {
  let response = await axiosInstance.get(`customers/${id}`);
  return response.data;
};
export const createCustomer = async ({ values }) => {
  const res = await axiosInstance.post("customers/", values);
  return res.data;
};
export const addCustomerImage = async ({ form, pId }) => {
  const onRequestOriginal = axiosInstance.interceptors.request.handlers[0].fulfilled;
  const onRequestErrorOriginal = axiosInstance.interceptors.request.handlers[0].rejected;
  axiosInstance.interceptors.request.handlers = [];
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  // let response = await axiosInstance.post(`projects/${pId}/upload-image`, form);
  let response = await axiosInstance.post(`upload-image`, form);
  axiosInstance.interceptors.request.handlers = [];
  axiosInstance.interceptors.request.use(onRequestOriginal, onRequestErrorOriginal);
  // let response = await axiosInstance.post(`customers/${pId}/upload-image`, form);
  return response.data;
};
