import { MouseEventHandler } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AddIcon } from "@components/icons";

function AddButton({
  label,
  action,
  disabled = false,
}: {
  label: string;
  action: MouseEventHandler;
  disabled: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Button variant="outline-primary" className="col-auto " onClick={action} disabled={disabled}>
      <AddIcon /> {t(label)}
    </Button>
  );
}

export default AddButton;
