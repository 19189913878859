import { useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useChoices from "@hooks/useChoices";
import { useTranslation } from "react-i18next";
import { getPublicTree } from "../services/treeCrud";
import Spinner from "@components/Spinner/Spinner";
import { Col, Row, Button, Card } from "react-bootstrap";
import {
  MapMarkerAltIcon,
  MenuAlt1Icon,
  LineChartIcon,
  CloseIcon,
  AngleLeftIcon,
  AngleRightIcon,
} from "@components/icons";
import MoistureGauge, { Gauge } from "./MoistureGauge";
import { colors, colorsBiodiversity, RED, BLUE, GREEN, YELLOW, GREY } from "@helper/colors";

import React from "react";
import "./TreeInformation.css";
// import "./TreeProfile.css";

type TreeInformationProps = {
  // Define your props here, e.g. tree data
};

type AccordionItemProps = {
  title: string;
  children: React.ReactNode;
};
const AccordionItem: React.FC<AccordionItemProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <button type="button" className="title" onClick={toggle}>
        {title}
      </button>
      {isOpen && <div className="content">{children}</div>}
    </div>
  );
};

export default function TreeProfileDetail(): React.FC<TreeInformationProps> {
  const { id: tid } = useParams();
  const [activeTab, setActiveTab] = useState("general");
  const { t } = useTranslation();
  const [healthState] = useChoices("dry_state");
  console.log(healthState);
  // const { mobile } = useSelector((state) => state.mobile);
  const {
    data: tree,
    // isLoading,
    // isError,
    // error,
  } = useQuery(["tree", tid], () => getPublicTree(tid), {
    refetchOnWindowFocus: false,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // delay between retries in milliseconds
    staleTime: 1000 * 60 * 60,
  });
  const backgroundColor =
    tree?.health_state === 0
      ? GREEN
      : tree?.health_state === 1
      ? YELLOW
      : tree?.health_state === 2
      ? RED
      : tree?.health_state === 4
      ? BLUE
      : GREY;
  const health_state = healthState?.filter((item) => item.key == tree?.health_state);
  const treeHealth = health_state && health_state[0] && t(health_state[0].value);
  // if (isLoading) return <Spinner height={"73vh"} />;
  // if (isError && error.response && error.response.status === 404) return <PageNotFound />;
  // if (isError) return <ServerError />;
  return (
    <div className="tree-information">
      <Card role="tree-species-img">
        <Card.Img
          // onError={(e) => {
          //   e.target.onerror = null;
          //   e.target.src = process.env.PUBLIC_URL + "/img/project-placeholder.webp";
          // }}
          variant="top"
          src={
            !tree?.species.image
              ? process.env.PUBLIC_URL + "/img/project-placeholder.webp"
              : tree?.species?.image?.image
          }
          alt=""
          className="treeImage"
        />
      </Card>
      <div className="tree-details-container">
        <div className="tree-info">
          <div className="tree-name">{tree?.species?.de_name}</div>
          <div className="latin-name">{tree?.species?.name}</div>
          <p>
            Lebensdauer: {tree?.species?.lifespan}, Höhe: {tree?.species?.min_height}-
            {tree?.species?.max_height}m, Breite: {tree?.species?.min_width}-
            {tree?.species?.max_width}m
          </p>
        </div>
        <div className="moisture-level-container">
          <div className="header">Biodiversitätsfaktor</div>
          <Gauge
            value={tree?.species?.biodiversity_factor}
            min={0}
            max={10}
            COLORS={colorsBiodiversity}
          />
        </div>
      </div>
      <div className="tabs">
        <div
          className={`tab ${activeTab === "general" ? "active" : ""}`}
          onClick={() => setActiveTab("general")}
        >
          {t("tree_profile.tree_species")}
        </div>
        <div
          className={`tab ${activeTab === "facts" ? "active" : ""}`}
          onClick={() => setActiveTab("facts")}
        >
          {t("tree_profile.tree_species_knowledge")}
        </div>
        <div
          className={`tab ${activeTab === "tree" ? "active" : ""}`}
          onClick={() => setActiveTab("tree")}
        >
          {t("tree_profile.tree_info")}
        </div>
      </div>
      <div className="tab-content">
        {activeTab === "general" && (
          <div>
            <div className="content">
              <p>
                <b>Familie:</b> {tree?.species?.genus?.family}
              </p>
              <p>
                <b>Herkunft:</b> {tree?.species?.origin}
              </p>
              <p>
                <b>Verbreitung:</b> {tree?.species?.spreading}
              </p>
              <p>
                <b>Blattform:</b> {tree?.species?.leaf_shape}
              </p>
              <p>
                <b>Blütezeit:</b> {tree?.species?.heyday}
              </p>
              <p>
                <b>Früchte:</b> {tree?.species?.fruits}
              </p>
              <p>
                <b>Wurzelsystem:</b> {t("root_system." + tree?.species?.root_system)}
              </p>
            </div>
          </div>
        )}
        {activeTab === "facts" && (
          <div>
            <div className="content">
              <p>
                <b>Standort:</b> {tree?.species?.habitat}
              </p>
              <p>
                <b>Wasserbedarf:</b>{" "}
                {tree?.species?.water_requirement_per_day
                  ? `${tree?.species?.water_requirement_per_day} Liter pro Tag`
                  : tree.species.water_requirement_general}
              </p>
              <p>
                <b>Bodenansprüche:</b> {tree?.species?.soil_requirements}
              </p>
              <p>
                <b>Wuchsform:</b> {tree?.species?.growth_habit}
              </p>
              <p>
                <b>Tierische Bewohner:</b> {tree?.species?.animals}
              </p>
              <p>
                <b>Krankheiten:</b> {tree?.species?.diseases}
              </p>
              <p>
                <b>Schädlinge:</b> {tree?.species?.pests}
              </p>
            </div>
          </div>
        )}
        {activeTab === "tree" && (
          <div>
            {/* <div className="tree-health-indicator"> */}
            {/*   {healthState ? t(healthState[tree?.health_state]) : tree?.health_state} */}
            {/* </div> */}
            <div className="moisture-level-container">
              <div className="tree-info-head">
                <div className="tree-health-indicator" style={{ background: backgroundColor }}>
                  {treeHealth ? `${t(treeHealth.replace(/\s/g, ""))}` : t("Unkown")}
                </div>
              </div>
              <MoistureGauge tree={tree} t={t} />
            </div>
            <div className="content">
              <p>
                <b>Name:</b> {tree?.name}
              </p>
              <p>
                <b>Höhe:</b> {tree?.height}
              </p>
              <p>
                <b>Stammumfang:</b> {tree?.trunk_circumference}
              </p>
              <p>
                <b>Kronendurchmesser:</b> {tree?.crown_diameter}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="call-to-action bg-primary">
        {/* <p>{t("tree_profile.want_to_know_more")}</p> */}
        <button
          className="learn-more bg-secondary"
          onClick={() => window.open("https://www.treesense.net")}
        >
          {t("tree_profile.learn_more")}
        </button>
      </div>
    </div>
  );
}
