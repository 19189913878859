import React, { useEffect, useState } from "react";
import QRForm from "../QRForm";
import { Field, useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Hardware({ validation, values }) {
  const { t } = useTranslation();
  const useIMEI = values.network_type == "NB" && values.sensor_type != "dendrometer_sensor";
  const [data, setData] = useState(useIMEI ? values.imei : values.hardware_serial);
  const { setFieldValue } = useFormikContext();

  const IMEI_LIMIT = 15;
  const SERIAL_LIMIT = 16;

  useEffect(() => {
    setFieldValue(useIMEI ? "imei" : "hardware_serial", data);
  }, [data, setFieldValue]);

  return (
    <div className="d-flex align-items-center gap-2">
      {!useIMEI && (
        <Form.Group className="flex-fill" controlId="formBasicEmail">
          <Form.Label>{t("hardware_serial_no")}</Form.Label>
          <Field
            type="text"
            name="hardware_serial"
            className={"form-control mb-3"}
            validate={validation.hardwareValidation}
            maxLength={SERIAL_LIMIT}
            placeholder={t("maximum_of") + ` ${SERIAL_LIMIT} ` + t("characters")}
          />
        </Form.Group>
      )}
      {useIMEI && (
        <Form.Group className="flex-fill" controlId="formBasicEmail">
          <Form.Label>{t("imei")}</Form.Label>
          <Field
            type="text"
            name="imei"
            className={"form-control mb-3"}
            placeholder={t("maximum_of") + ` ${IMEI_LIMIT} ` + t("characters")}
            maxLength={IMEI_LIMIT}
          />
        </Form.Group>
      )}
      {<QRForm setData={setData} data={data} />}
    </div>
  );
}

export default Hardware;
