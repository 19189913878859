import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../icons";

export default function MobileBreadcrumb(props) {
  const navigate = useNavigate();
  const goToPreviousPath = () => {
    navigate(-1);
  };
  const { pathname } = navigate;
  return (
    <div data-testid="mobile-breadcrumb" className="breadcrumb py-2 my-0">
      <Container>
        <Row>
          <Col>
            <h5 className=" page-title me-4 my-2 font-lg">
              {pathname !== "/projects" && <ArrowLeftIcon onClick={goToPreviousPath} />}
              {props.title}
            </h5>
            {props.weather && props.weather}
          </Col>
          <Col className="d-flex justify-content-end gap-2">
            {props.qrButton && props.qrButton}
            {props.addButton && props.addButton}
            {props.editButton && props.editButton}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
