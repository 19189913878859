export const formInitialValues = {
  tree_cadastre_no: "",
  name: "",
  longitude: "",
  latitude: "",
  altitude: "",
  // sensors: [],
  trunk_circumference: "",
  height: "",
  crown_diameter: "",
  species: "",
  phase: 2,
  planting_year: "",
  // notes: "",
};

export const formUpdateValues = (formValue) => {
  return {
    tree_cadastre_no: formValue && formValue.tree_cadastre_no ? formValue.tree_cadastre_no : "",
    name: formValue && formValue.name ? formValue.name : "",
    species: formValue && formValue.species ? formValue.species.name : "",
    longitude:
      formValue && formValue.position ? (formValue.position.lng ? formValue.position.lng : "") : "",
    latitude:
      formValue && formValue?.position
        ? formValue.position.lat
          ? formValue.position.lat
          : ""
        : "",
    altitude:
      formValue && formValue.position ? (formValue.position.alt ? formValue.position.alt : "") : "",

    // sensors: formValue && formValue.sensors ? formValue.sensors : "",
    installations: formValue && formValue.installations ? formValue.installations : "",
    represented_trees: formValue && formValue.represented_trees ? formValue.represented_trees : "",
    trunk_circumference:
      formValue && formValue.trunk_circumference ? formValue.trunk_circumference : "",
    crown_diameter: formValue && formValue?.crown_diameter ? formValue?.crown_diameter : "",
    height: formValue && formValue.height ? formValue.height : "",
    phase: formValue && formValue.phase ? formValue.phase : "",
    planting_year: formValue && formValue.planting_year ? formValue.planting_year : "",
  };
};
