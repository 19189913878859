import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function NetworkType({ formField, setFieldValue, values, data }) {
  const { t } = useTranslation();
  const networkTypes = [
    {
      name: "LW",
      label: "lora_wan",
    },
    {
      name: "NB",
      label: "nb_iot",
    },
  ];
  var defaultValue = "LW";
  data ? (data.network_type ? (defaultValue = data.network_type) : "") : "";
  const defaultLabel = networkTypes.find((obj) => obj["name"] == defaultValue)["label"];

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t("network_type")}</Form.Label>
        {/* <Field type="text" name="name" className={"form-control mb-3"} /> */}
        <Select
          id={"sensorType"}
          type={"text"}
          defaultValue={{
            value: defaultValue,
            label: t(`${defaultLabel}`),
          }}
          onChange={(option) => {
            setFieldValue(formField, option.value);
          }}
          options={networkTypes.map((networkType) => ({
            value: networkType.name,
            label: t(networkType.label),
          }))}
        />
      </Form.Group>
    </>
  );
}

export default NetworkType;
