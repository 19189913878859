import NavItem from "./NavItem";
import { useQuery } from "react-query";
import React, { useState } from "react";
import Topbarglobal from "./Topbarglobal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosInstance, { baseURL } from "@helper/interceptor";
import { getCustomerBasicInfo } from "@modules/Customer/services/customerCrud";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

function AlertDismissibleCustomer() {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  if (show) {
    return (
      <Alert className="alert-wrapper" variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{t("demo_project_warning.title")}</Alert.Heading>
        <p>{t("demo_project_warning.description")}</p>
      </Alert>
    );
  }
  return <Button onClick={() => setShow(true)}>Show Alert</Button>;
}

function InfoImageUpload() {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  if (show) {
    return (
      <Alert
        className="alert-wrapper-info"
        variant="dark"
        onClose={() => setShow(false)}
        dismissible
      >
        <Alert.Heading>{t("image_upload_info.title")}</Alert.Heading>
        <p>{t("image_upload_info.description")}</p>
      </Alert>
    );
  }
  return <Button onClick={() => setShow(true)}>Show Alert</Button>;
}

function Header() {
  const [customerImage, setCustomerImage] = useState("");
  const [customerName, setCustomerName] = useState("");
  const { mobile } = useSelector((state) => state.mobile);
  // const [paid, setPaid] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const res = await axiosInstance.get(`${baseURL}logout/`);
      if (res.status === 200) {
        dispatch({ type: "[Logout] Action" });
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useQuery("customer-basic-info", getCustomerBasicInfo, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setCustomerImage(data[0].image?.image);
      setCustomerName(data[0].name);
      // setPaid(data[0].paid);
    },
  });

  return (
    <>
      <header className="header">
        <Navbar bg="primary" variant="dark" expand="lg" className="fixed-top p-0 shadow-sm ">
          <Container>
            <div className="col-md-4 col-sm-3">
              <Nav className="header__start">
                <NavItem mobileView={mobile} handleLogout={handleLogout} />
              </Nav>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="header__middle">
                <Navbar.Brand aria-label="logo" className="m-0">
                  <img
                    src={process.env.PUBLIC_URL + "/img/treesense-logo.webp"}
                    className="align-middle"
                    height="41"
                    width="90"
                    alt="Treesense Logo"
                  />
                </Navbar.Brand>
                <Navbar.Brand href="/" aria-label="logo-org" className="m-0">
                  <img src={customerImage} className="align-end mt-2" height="35" alt="" />
                </Navbar.Brand>
              </div>
            </div>
            <div className="col-md-4 col-sm-3">
              <div className="header__end">
                <Topbarglobal logout={handleLogout} />
              </div>
            </div>
          </Container>
        </Navbar>
      </header>
      {customerName == "Demo" && <AlertDismissibleCustomer />}
      {/* {customerName != "Demo" && <InfoImageUpload />} */}
    </>
  );
}

export default React.memo(Header);
