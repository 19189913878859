import Dustbin from "../graph/Dustbin";
const dustbin = [
  { name: "left", type: "list" },
  { name: "right", type: "list" },
];
const DropBox = () => {
  return (
    <div className="position-absolute w-100 h-100">
      <div className="graph-grid">
        {dustbin.map((dust, i) => (
          <Dustbin key={i} dust={dust} />
        ))}
      </div>
    </div>
  );
};

export default DropBox;
