import React, { useState, useMemo } from "react";

import "./Project.css";
// import { useTour } from "@reactour/tour";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Card, Col, Container, Row, Button, Dropdown } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ProjectForm from "./Form";
import getDate from "@helper/getDate";
import { MoreIcon } from "@components/icons";
import Spinner from "@components/Spinner/Spinner";
import AddButton from "@components/Button/AddButton";
import { AddProject, ViewTypeButton } from "./Button";
import ServerError from "@components/Error/ServerError";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import { CaretRightFillIcon1 } from "@components/icons";
import { deleteProject, getAllProjects } from "../services/projectCrud";
import { WeatherDayAfterTommorow, WeatherToday, WeatherTommorow } from "@modules/Weather/Weather";

export default function ProjectGridView() {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const {
    data: projects,
    isLoading,
    isError,
  } = useQuery("projects", getAllProjects, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });

  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError) return <ServerError />;
  return (
    <>
      <Breadcrumb
        title={`${t("projects")}`}
        addButton={<AddButton label="newproject" action={() => setOpenForm(true)} />}
        // viewType={<ViewTypeButton />}
      />
      <Container>
        <Row>
          <Col>
            <p>{t("start_with_adding_new_project")}</p>
          </Col>
        </Row>
        <Row className="gy-4 mb-4">
          <ProjectListItem
            setSelectedProject={setSelectedProject}
            setOpenForm={setOpenForm}
            setIsUpdate={setIsUpdate}
            openForm={openForm}
            projects={projects}
          />
          <Col xl={3} lg={4} md={6}>
            <AddProject label="newproject" action={() => setOpenForm(true)} />
          </Col>
        </Row>
      </Container>
      {openForm && (
        <ProjectForm
          show={openForm}
          isUpdate={isUpdate}
          setOpenForm={setOpenForm}
          setIsUpdate={setIsUpdate}
          selectedProject={selectedProject}
        />
      )}
    </>
  );
}

const ProjectListItem = ({ projects, setOpenForm, setIsUpdate, setSelectedProject }) => {
  const queryClient = useQueryClient();
  const { account_type } = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const day = getDate();

  const showProjectDetail = (project) => {
    navigate(`/project/${project.id}/map`);
  };

  const handleEdit = (project) => {
    setIsUpdate(true);
    setOpenForm(true);
    setSelectedProject(project);
  };

  const { mutateAsync } = useMutation(deleteProject, {
    onSuccess: () => {
      queryClient.invalidateQueries("projects");
    },
  });

  const handleDelete = (project) => {
    Swal.fire({
      html:
        '<p className="text-center"> ' +
        "Are you sure you want to delete project named" +
        ` <b>${project.name} </b>` +
        "?" +
        "</p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#388090",
      cancelButtonColor: "#EF3600",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        mutateAsync(project.id);
      }
    });
  };

  return (
    <>
      {projects?.map((project) => {
        return (
          <Col xl={3} lg={4} md={6} key={project.id} data-tour="step-1">
            <Card role="project">
              <Card.Body className="d-flex justify-content-between py-2" style={{ height: "55px" }}>
                <Card.Title>
                  <span className="project-name">{project.name}</span>
                </Card.Title>
                <div className="projectWeather d-flex">
                  {project.weather && (
                    <>
                      <div className="projectStatus statusCloud me-3">
                        <WeatherToday weather={project.weather} iconWidth={"1.2rem"} />
                        <p className="mb-0 text-center ">{day.today}</p>
                        <p className="text-center">{project.weather.temperature.today}&#8451;</p>
                      </div>
                      <div className="projectStatus statusRain me-3">
                        <WeatherTommorow weather={project.weather} iconWidth={"1.2rem"} />
                        <p className="mb-0 text-center">{day.tomorrow}</p>
                        <p className="text-center">{project.weather.temperature.tomorrow}&#8451;</p>
                      </div>
                      <div className="projectStatus statusSun">
                        <WeatherDayAfterTommorow weather={project.weather} iconWidth={"1.2rem"} />
                        <p className="mb-0 text-center">{day.dayAfterTomorrow}</p>
                        <p className="text-center">
                          {project.weather.temperature.day_after_tomorrow}
                          &#8451;
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </Card.Body>
              <Card.Img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = process.env.PUBLIC_URL + "/img/project-placeholder.webp";
                }}
                variant="top"
                src={
                  !project.image
                    ? process.env.PUBLIC_URL + "/img/project-placeholder.webp"
                    : project?.image?.image
                }
                alt=""
                className="projectImage"
              />
              <Card.Body className="text-end ">
                <div className=" d-flex align-items-center gap-1">
                  <Button
                    data-testid="project-detail-btn"
                    className="btn  btn-outline-primary"
                    variant="outline-primary"
                    onClick={() => showProjectDetail(project)}
                  >
                    {t("viewproject")}
                    <CaretRightFillIcon1 />
                  </Button>
                  {account_type !== 2 && (
                    <>
                      <Dropdown>
                        <Dropdown.Toggle
                          data-testid="dropdown-basic"
                          className="px-2"
                          variant="outline-primary"
                          id="dropdown-basic"
                        >
                          <MoreIcon width="20px" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            data-testid="dropdown-edit"
                            onClick={() => handleEdit(project)}
                          >
                            {t("edit")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            data-testid="dropdown-delete"
                            onClick={() => handleDelete(project)}
                          >
                            <span>{t("delete")}</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </>
  );
};
