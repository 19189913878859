import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useMutation, useQueryClient } from "react-query";
import { Button, Form, Row, Col, Modal, ModalBody } from "react-bootstrap";

import { InstalledSensors, Tree, Area } from "./steps";
import FormError from "@components/Form/FormError";
import { updateTree } from "@modules/Trees/services/treeCrud";
import { updateSingleIrrigationArea as updateArea } from "@modules/IrrigationArea/services/areaCrud";
import { styles } from "@components/styles";
import { formInitialValues } from "../../../Sensors/components/Form/FormModel/initialValues";
import SensorForm from "../../../Sensors/components/Form/SensorForm";
import TreeForm from "../../../Trees/components/Form/AddForm/TreeForm";
import IrrigationAreaForm from "@modules/IrrigationArea/components/Form/IrrigationAreaForm";
// import Spinner from "@components/Spinner/Spinner";

const steps = ["add_all_form.tree.title", "add_all_form.sensor.title", "add_all_form.area.title"];

//form steps for creating new tree with sensors and area
const renderSteps = (
  activeStep,
  setOpenSensorForm,
  setOpenTreeForm,
  setOpenAreaForm,
  addedSensor,
  addedTree,
  addedArea,
  values,
  setFieldValue
) => {
  const { t } = useTranslation();
  switch (activeStep) {
    case 0:
      return (
        <Tree
          formfield={"tree"}
          values={values}
          setFieldValue={setFieldValue}
          setOpenTreeForm={setOpenTreeForm}
          addedTree={addedTree}
        />
      );
    case 1:
      return (
        <InstalledSensors
          formField={"sensor"}
          values={values}
          setFieldValue={setFieldValue}
          setOpenSensorForm={setOpenSensorForm}
          addedSensor={addedSensor}
        />
      );
    case 2:
      return (
        <Area
          formfield={"area"}
          values={values}
          setFieldValue={setFieldValue}
          setOpenAreaForm={setOpenAreaForm}
          addedArea={addedArea}
        />
      );

    default:
      return <>{t("not_found")}</>;
  }
};

export default function AddAllForm({ isUpdate, setIsUpdate, setOpenForm, show, pid }) {
  const { t } = useTranslation();
  const [addedSensor, setSensorValues] = useState("");
  const [addedTree, setTreeValues] = useState("");
  const [addedArea, setAreaValues] = useState("");

  const [error, setError] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [openSensorForm, setOpenSensorForm] = useState(false);
  const [isUpdateSensor, setIsUpdateSensor] = useState(false);

  const [openTreeForm, setOpenTreeForm] = useState(false);
  const [isUpdateTree, setIsUpdateTree] = useState(false);

  const [openAreaForm, setOpenAreaForm] = useState(false);
  const isLastStep = activeStep === steps.length - 1;
  const isSensorStep = activeStep === steps.length - 2;

  const handleClose = () => {
    setOpenForm(false);
    setError();
    setIsUpdate && setIsUpdate(false);
    setActiveStep(0);
  };

  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    mutateAsync: mutateAsyncTree,
  } = useMutation(
    "updateTree",
    updateTree, // TODO: function to create Tree with Area and Sensors
    {
      onSuccess: () => {
        queryClient.invalidateQueries("trees");
        queryClient.invalidateQueries("areas");
        // queryClient.invalidateQueries(["trees", treeId]);
      },

      onError: (error) => {
        if (error.response) {
          setError(error.response.data.hardware_serial);
        }
      },
    }
  );

  const {
    // isLoading: isLoadingArea,
    // isError: isErrorArea,
    mutateAsync: mutateAsyncArea,
  } = useMutation(
    updateArea, // TODO: function to create Tree with Area and Sensors
    {
      onSuccess: () => {
        queryClient.invalidateQueries("areas");
        // queryClient.invalidateQueries(["areas", areaId]);
        handleClose();
      },

      onError: (error) => {
        if (error.response) {
          setError(error.response.data.hardware_serial);
        }
      },
    }
  );

  const handleSubmit = async (values) => {
    const treeFormData = {
      // sensors: values.sensors,
      area: {
        id: values.areaId,
        type: "Area",
      },
    };
    // const areaFormData = {
    //   id: values.areaId,
    //   trees: [{ id: values.treeId }],
    // };
    !isLastStep && setActiveStep(activeStep + 1);

    // if (isSensorStep) {
    //   await mutateAsyncTree({ formData: treeFormData, id: values.treeId });
    // }

    if (isLastStep) {
      // await mutateAsyncArea({ formData: areaFormData, id: values.areaId });
      await mutateAsyncTree({ formData: treeFormData, id: values.treeId });
      handleClose();
    }
  };
  // if (isLoadingArea || isLoadingTree) return <Spinner height={"80vh"} />;

  return (
    <Row>
      <SensorForm
        isUpdate={isUpdateSensor}
        setIsUpdate={setIsUpdateSensor}
        show={openSensorForm}
        setOpenForm={setOpenSensorForm}
        setSensorValues={setSensorValues}
      />
      <TreeForm
        isUpdate={isUpdateTree}
        setIsUpdate={setIsUpdateTree}
        show={openTreeForm}
        setOpenForm={setOpenTreeForm}
        serial={addedSensor}
        setTreeValues={setTreeValues}
      />
      <IrrigationAreaForm
        show={openAreaForm}
        setOpenForm={setOpenAreaForm}
        pid={pid}
        setAreaValues={setAreaValues}
      />
      ;
      <Col lg={12}>
        <Modal onHide={handleClose} show={show} centered>
          <ModalHeader closeButton>
            <h5 className="mb-0">{`${t("add_all_form.title")}`}</h5>
          </ModalHeader>
          <ModalBody>
            <div className={styles.wizardList}>
              <>
                {steps.map((step, i) => (
                  <span key={step} className={activeStep == i ? "wizard-active" : ""}>
                    {t(step)}
                  </span>
                ))}
              </>
            </div>
            <Formik
              initialValues={formInitialValues({})}
              enableReinitialize
              onSubmit={async (values) => handleSubmit(values)}
            >
              {({ handleSubmit, setFieldValue, values, errors }) => (
                <>
                  <p className={styles.validationError}>
                    {Object.keys(errors).length > 0 ? (
                      <FormError title={errors.hardware_serial} />
                    ) : (
                      <>{isError && error && <FormError title={error} />}</>
                    )}
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <div className="form-container">
                      {renderSteps(
                        activeStep,
                        setOpenSensorForm,
                        setOpenTreeForm,
                        setOpenAreaForm,
                        addedSensor,
                        addedTree,
                        addedArea,
                        values,
                        setFieldValue
                      )}
                    </div>
                    <div className={styles.formBody}>
                      {activeStep !== 0 && (
                        <Button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => setActiveStep((step) => step - 1)}
                        >
                          {t("button.back")}
                        </Button>
                      )}
                      <button
                        type="submit"
                        className="btn btn-outline-primary ms-auto"
                        disabled={
                          activeStep == 0 && (values.treeId == "" || values.treeId == undefined)
                        }
                      >
                        {isLastStep
                          ? isUpdate
                            ? isLoading
                              ? t("button.updating")
                              : t("button.update")
                            : isLoading
                            ? t("button.saving")
                            : t("button.save")
                          : t("button.next")}
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  );
}
