import { useEffect, useRef, useState } from "react";
import { Accordion, Dropdown, Modal, Form, Row, Col, Button } from "react-bootstrap";
import Avatar from "react-avatar";
import Select from "react-select";
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import styled from "styled-components";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { Tree } from "@styled-icons/entypo/Tree";
import { styles } from "@components/styles";
import { CaretRightFillIcon, AddIcon, AngleDownIcon } from "@components/icons";
import { getAllCustomers } from "@modules/Customer/services/customerCrud";
import { updateSingleIrrigationArea } from "@modules/IrrigationArea/services/areaCrud";
import SearchControl from "../../SearchControl";
import { noSensorTreeIcon, parentTreeIcon } from "@helper/marker";
import { GREEN, YELLOW, RED, GREY, BLUE } from "@helper/colors";

export const handleTreeSelect = (
  tree,
  areaId,
  pid,
  navigate,
  selectedTree,
  setSelectedTree,
  setOpenTreePopup,
  setSelectedParentTree,
  map
) => {
  if (selectedTree == tree) {
    setSelectedParentTree(null);
    setSelectedTree(null);
    navigate(`/project/${pid}/map`);
    setOpenTreePopup(null);
  } else {
    setSelectedParentTree(tree);
    if (areaId != undefined && tree.position != undefined) {
      navigate(`/project/${pid}/map?area=${areaId}&selectedTree=${tree.id}`);
    }
    setSelectedTree(tree);
    setOpenTreePopup(tree);
  }
  if (tree.position && map) map.flyTo(tree.position, map.getZoom("18"));
};

export default function MapViewTreeList({
  trees,
  areas,
  setSelectedArea,
  selectedArea,
  setSelectedParentTree,
  selectedParentTree,
  setOpenChildModal,
  openChildModal,
  setSelectedTree,
  selectedTree,
  tid,
  aId,
  setOpenTreePopup,
  setOpenList,
  centroid,
  map,
}) {
  const { pid } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mobile } = useSelector((state) => state.mobile);
  const [childOption, setChildOption] = useState([]);

  useQuery("customers", getAllCustomers, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setChildOption(data[0].possible_child_trees);
    },
  });

  const { isLoading, mutateAsync } = useMutation("updateSingleArea", updateSingleIrrigationArea, {
    onSuccess: () => {
      setOpenChildModal(false);
      queryClient.invalidateQueries("trees");
      queryClient.invalidateQueries("project", `${pid}`);
    },
  });

  return (
    <>
      {mobile ? (
        <DropdownWrapper>
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              className="btn btn-primary text-white"
            >
              <TreeIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ zIndex: "9999" }}>
              <ListMenu trees={trees} />
            </Dropdown.Menu>
          </Dropdown>
        </DropdownWrapper>
      ) : (
        <>
          <div>
            <h5>
              <Button
                variant="outline-primary d-flex align-items-center"
                onClick={() => {
                  setOpenList(false);
                  if (centroid) map.flyTo([centroid.lat, centroid.lng]);
                }}
              >
                {t("areas")}
                <AngleDownIcon
                  onClick={() => {
                    setOpenList(false);
                    if (centroid) map.flyTo([centroid.lat, centroid.lng]);
                  }}
                />
              </Button>
            </h5>
            <ul className="p-0 m-0 projectMapViewList dropdown" style={{ maxHeight: "77vh" }}>
              <AreaList
                areas={areas}
                setOpenChildModal={setOpenChildModal}
                setSelectedArea={setSelectedArea}
                selectedArea={selectedArea}
                tid={tid}
                aId={aId}
                pid={pid}
                selectedTree={selectedTree}
                setSelectedTree={setSelectedTree}
                setOpenTreePopup={setOpenTreePopup}
                setSelectedParentTree={setSelectedParentTree}
                map={map}
              />
            </ul>
          </div>
        </>
      )}
      <Modal
        show={openChildModal}
        onHide={() => setOpenChildModal(false)}
        centered
        className="$modal-fade-transform: scale(.8)"
      >
        <Modal.Header closeButton>
          {t("add_child_tree")} ({selectedParentTree?.name})
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ project: {}, trees: [] }}
            onSubmit={(values) => {
              let createData = {
                project: { type: "Project", id: pid },
                trees: [
                  {
                    id: selectedParentTree.id,
                    represented_trees: values.represented_trees,
                  },
                ],
              };
              mutateAsync({ formData: createData, id: selectedArea });
            }}
          >
            {({ setFieldValue, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="form-container">
                    <h6>{t("Represent Tree")}</h6>
                    <Select
                      id={"trees"}
                      type={"text"}
                      defaultValue={selectedParentTree?.represented_trees?.map(
                        (representedTree) => ({
                          value: representedTree.id,
                          label: representedTree.name,
                        })
                      )}
                      onChange={(option) => {
                        setFieldValue(
                          "represented_trees",
                          option?.map((tree) => ({
                            id: parseInt(tree.value),
                            type: "Tree",
                          }))
                        );
                      }}
                      options={childOption?.map((tree) => ({
                        value: tree.id,
                        label: tree.name,
                      }))}
                      isMulti
                    />
                  </div>
                  <div className={styles.formBody}>
                    <button type="submit" className="btn btn-outline-primary ms-auto">
                      {isLoading ? t("button.saving") : t("button.save")}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

const AreaList = ({
  areas,
  selectedArea,
  setSelectedArea,
  setOpenChildModal,
  tid,
  aId,
  pid,
  selectedTree,
  setSelectedTree,
  setOpenTreePopup,
  setSelectedParentTree,
  map,
}) => {
  const [addTreeMap, setMap] = useState();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [childOption, setChildOption] = useState([]);
  const [parentOption, setParentOption] = useState([]);
  const [selectedParent, setSelectedParent] = useState();
  const [selectedChild, setSelectedChild] = useState([]);
  const [selectedTreeNoSensor, setSelectedTreeNoSensor] = useState([]);
  const queryClient = useQueryClient();

  const handleFormClose = () => {
    setShow(false);
    setSelectedParent();
    setSelectedChild([]);
    setSelectedTreeNoSensor([]);
    setSelectedArea();
  };

  const [noSensor, setNoSensor] = useState(false);
  const toggleNoSensor = () => {
    if (noSensor) {
      setSelectedTreeNoSensor([]);
    } else {
      unselectParent();
      setSelectedChild([]);
    }
    setNoSensor(!noSensor);
  };

  const handleAddParent = (e, area) => {
    e.stopPropagation();
    setShow(true);
    setSelectedArea(area);
  };

  //select parent on click to parent marker
  const selectParent = (parent) => {
    setSelectedParent(parent);
  };

  //unselect the parent marker on click to marker
  const unselectParent = () => {
    setSelectedParent();
    setSelectedChild([]);
  };

  //select child tree on click to parent marker
  const selectChild = (childTree) => {
    if (!noSensor) {
      setSelectedChild((selectedChild) => {
        let arr = new Set([...selectedChild, childTree]);
        return Array.from(arr);
      });
    } else {
      setSelectedTreeNoSensor((selectedTreeNoSensor) => {
        let arr = new Set([...selectedTreeNoSensor, childTree]);
        return Array.from(arr);
      });
    }
  };

  useQuery("customers", getAllCustomers, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setChildOption(data[0].possible_child_trees);
      setParentOption(data[0].possible_parent_trees);
    },
  });

  const { isLoading, mutateAsync } = useMutation(updateSingleIrrigationArea, {
    onSuccess: () => {
      queryClient.invalidateQueries("project", pid);
      setShow(false);
    },
  });

  //default to open selected area
  const aIndex = areas.findIndex((area) => {
    return area.id == aId;
  });

  return (
    <>
      {areas?.map((area, index) => {
        return (
          <Accordion key={index} className="mb-2" defaultActiveKey={[`${aIndex}`]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div
                  className="d-flex justify-content-between  align-items-center"
                  style={{ flex: 1 }}
                >
                  <div className="flex-column">
                    <div className="d-flex align-items-center">
                      <CaretRightFillIcon className="expand-caret" />
                      <h6 className="my-2">{area.name} </h6>
                    </div>
                    <span className="ms-2 text-muted tree-count" style={{ fontSize: "12px" }}>
                      {area.tree_set.length} {t("trees")}
                    </span>
                  </div>
                  <span
                    className=" btn btn-sm btn-outline-primary"
                    title={t("button.add_tree")}
                    style={{ height: "fit-content", borderRadius: "2px" }}
                    onClick={(e) => handleAddParent(e, area)}
                  >
                    <AddIcon />
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ListMenu
                  trees={area.tree_set}
                  setOpenChildModal={setOpenChildModal}
                  areaId={area.id}
                  setSelectedArea={setSelectedArea}
                  tid={tid}
                  pid={pid}
                  selectedTree={selectedTree}
                  setSelectedTree={setSelectedTree}
                  setOpenTreePopup={setOpenTreePopup}
                  setSelectedParentTree={setSelectedParentTree}
                  map={map}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
      <Modal show={show} onHide={handleFormClose} size="lg" centered>
        <Modal.Body className="p-0">
          <Formik
            initialValues={{ name: selectedArea?.name, trees: [] }}
            onSubmit={() => {
              let createData = {
                project: { type: "Project", id: pid },
                trees: noSensor
                  ? selectedTreeNoSensor
                  : [
                      {
                        id: selectedParent.id,
                        represented_trees: selectedChild,
                      },
                    ],
              };
              mutateAsync({ formData: createData, id: selectedArea.id });
            }}
          >
            {({ handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="step1">
                    <Row className="g-0">
                      <Button
                        variant="outline-primary d-flex align-items-center"
                        onClick={() => toggleNoSensor()}
                      >
                        Toggle Tree Modus
                      </Button>
                      {noSensor && (
                        <Col lg={3} className="p-3">
                          <h5>{selectedArea?.name}</h5>
                          <p className="text-muted" style={{ fontSize: "11px" }}>
                            {t("click_on_marker_to_select_tree")}
                          </p>
                          <div className="mb-3">
                            <h6>{t("treeNoSensor")}</h6>
                            <Select
                              type={"text"}
                              value={selectedTreeNoSensor?.map((tree) => ({
                                value: tree.id,
                                label: tree.name,
                              }))}
                              onChange={(option) => {
                                setSelectedTreeNoSensor(
                                  option?.map((tree) => ({
                                    id: tree.value,
                                    name: tree.label,
                                  }))
                                );
                              }}
                              options={childOption?.map((tree) => ({
                                value: tree.id,
                                label: tree.name,
                              }))}
                              isMulti
                            />
                          </div>
                          <div className="">
                            <Button
                              type={"subbmit"}
                              className="btn btn-outline-primary ms-auto"
                              variant="btn-outline-primary"
                            >
                              {isLoading ? t("loading") : t("save")}
                            </Button>
                          </div>
                        </Col>
                      )}

                      {!noSensor && (
                        <Col lg={3} className="p-3">
                          <h5>{selectedArea?.name}</h5>
                          <p className="text-muted" style={{ fontSize: "11px" }}>
                            {t("click_on_marker_to_select_tree")}
                          </p>
                          <div className="mb-3">
                            <h6>{t("parentTree")}</h6>
                            <Field
                              name="id"
                              className="form-control"
                              style={{ height: "38px" }}
                              value={selectedParent ? selectedParent?.name : ""}
                            />
                          </div>
                          <div className="mb-3">
                            <h6>{t("childTree")}</h6>
                            <Select
                              type={"text"}
                              isDisabled={selectedParent ? false : true}
                              value={selectedChild?.map((tree) => ({
                                value: tree.id,
                                label: tree.name,
                              }))}
                              onChange={(option) => {
                                setSelectedChild(
                                  option?.map((tree) => ({
                                    id: tree.value,
                                    name: tree.label,
                                  }))
                                );
                              }}
                              options={childOption?.map((tree) => ({
                                value: tree.id,
                                label: tree.name,
                              }))}
                              isMulti
                            />
                          </div>

                          <div className="">
                            <Button
                              type={"subbmit"}
                              className="btn btn-outline-primary ms-auto"
                              variant="btn-outline-primary"
                            >
                              {isLoading ? t("loading") : t("save")}
                            </Button>
                          </div>
                        </Col>
                      )}
                      <Col lg={9}>
                        <MapContainer
                          center={[48.1351, 11.582]}
                          zoom={10}
                          scrollWheelZoom={true}
                          zoomControl={false}
                          whenCreated={setMap}
                          maxZoom={30}
                          style={{
                            height: "50vh",
                            borderRadius: "3px",
                          }}
                        >
                          <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            preferCanvas={true}
                          />
                          <SearchControl
                            showMarker={true}
                            keepResult={true}
                            animateZoom={false}
                            showPopup={true}
                            autoClose={true}
                            searchLabel={t("type_an_adress")}
                            position="topleft"
                          />
                          {parentOption?.map((parent) => {
                            const health = parent.health_state;
                            return (
                              <>
                                {!noSensor &&
                                  (selectedParent ? (
                                    <Marker
                                      key={selectedParent.id}
                                      position={[
                                        selectedParent?.position?.lat,
                                        selectedParent?.position?.lng,
                                      ]}
                                      icon={parentTreeIcon(selectedParent.health_state)}
                                      eventHandlers={{
                                        click: () => {
                                          unselectParent();
                                        },
                                      }}
                                    ></Marker>
                                  ) : (
                                    parent.position && (
                                      <Marker
                                        key={parent.id}
                                        position={[parent?.position?.lat, parent?.position?.lng]}
                                        eventHandlers={{
                                          click: () => {
                                            selectParent(parent);
                                          },
                                        }}
                                        icon={parentTreeIcon(health)}
                                      ></Marker>
                                    )
                                  ))}
                              </>
                            );
                          })}
                          {childOption?.map((childTree) => {
                            const health = childTree.health_state;
                            return (
                              <>
                                {(selectedParent || noSensor) && (
                                  <>
                                    {childTree.position && (
                                      <CustomMarker
                                        data={childTree}
                                        selectChild={selectChild}
                                        selectedChild={
                                          noSensor ? selectedTreeNoSensor : selectedChild
                                        }
                                        setSelectedChild={
                                          noSensor ? setSelectedTreeNoSensor : setSelectedChild
                                        }
                                        health={health}
                                        map={addTreeMap}
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </MapContainer>
                      </Col>
                    </Row>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const CustomMarker = ({ data, selectChild, selectedChild, setSelectedChild, health, isActive }) => {
  const map = useMapEvents();
  let popupRef = useRef();

  useEffect(() => {
    if (isActive) {
      map.openPopup(popupRef);
    }
  }, [isActive, map]);

  //unselect specific child on click to marker popup close
  const unSelectChild = () => {
    let filteredSelectedChildTree = selectedChild.filter((child) => data.id !== child.id);
    setSelectedChild(filteredSelectedChildTree);
  };

  return (
    <Marker
      position={[data?.position?.lat, data?.position?.lng]}
      eventHandlers={{
        click: () => {
          selectChild({ id: data.id, name: data.name });
        },
      }}
      icon={data.sensors.length != 0 ? parentTreeIcon(health) : noSensorTreeIcon(health)}
    >
      <Popup autoClose={false} closeOnClick={false} onClose={() => unSelectChild()} ref={popupRef}>
        {data?.name}
      </Popup>
    </Marker>
  );
};

const ListMenu = ({
  trees,
  setOpenChildModal,
  areaId,
  setSelectedArea,
  // tid,
  pid,
  selectedTree,
  setSelectedTree,
  setOpenTreePopup,
  setSelectedParentTree,
  map,
}) => {
  const navigate = useNavigate();
  const handleClickAdd = (areaId) => {
    setOpenChildModal(true);
    setSelectedArea(areaId);
  };
  const [toggle, setToggle] = useState(false);

  return (
    <>
      {trees?.map((tree) => (
        <div key={tree.id}>
          {tree.installations?.length === 0 ? (
            <ListNST
              key={"child" + tree.id}
              className={
                // "accord"
                selectedTree === tree.id
                  ? "d-flex align-items-center "
                  : "d-flex align-items-center clickable"
              }
              style={{ background: selectedTree === tree.id ? "#deebff" : "" }}
              onClick={() => {
                handleTreeSelect(
                  tree,
                  areaId,
                  pid,
                  navigate,
                  selectedTree,
                  setSelectedTree,
                  setOpenTreePopup,
                  setSelectedParentTree,
                  map
                );
              }}
              status={tree.health_state}
            >
              <Avatar
                name={tree.name}
                textMarginRatio={0.25}
                color={
                  tree.health_state === 0
                    ? GREEN
                    : tree.health_state === 1
                    ? YELLOW
                    : tree.health_state === 2
                    ? RED
                    : tree.health_state === 4
                    ? BLUE
                    : GREY
                }
                size="32"
                round="100px"
              />
              <div className="ms-2">
                <h6 className="mb-0">{tree.name} </h6>
                <span className={selectedTree === tree.id ? " small" : "text-muted small"}>
                  {tree.species ? tree.species.name : ""}
                </span>
              </div>
            </ListNST>
          ) : (
            <ListPT
              className={
                selectedTree === tree.id
                  ? "d-flex align-items-center justify-content-between parent-tree"
                  : "d-flex align-items-center justify-content-between clickable parent-tree"
              }
              style={{ background: selectedTree === tree.id ? "#deebff" : "" }}
              onClick={() => {
                handleTreeSelect(
                  tree,
                  areaId,
                  pid,
                  navigate,
                  selectedTree,
                  setSelectedTree,
                  setOpenTreePopup,
                  setSelectedParentTree,
                  map
                );
              }}
              status={tree.health_state}
            >
              <div className="d-flex align-items-center">
                <Avatar
                  name={tree.name}
                  textMarginRatio={0.25}
                  color={
                    tree.health_state === 0
                      ? GREEN
                      : tree.health_state === 1
                      ? YELLOW
                      : tree.health_state === 2
                      ? RED
                      : tree.health_state === 4
                      ? BLUE
                      : GREY
                  }
                  size="32"
                  round="100px"
                />
                <div className="ms-2">
                  <h6 className="mb-0">{tree.name} </h6>
                  <span className={selectedTree === tree.id ? " small" : "text-muted small"}>
                    {tree.species ? tree.species.name : ""}
                  </span>
                </div>
              </div>
              {tree.represented_trees.length > 0 && (
                <Button onClick={() => setToggle(!toggle)}>
                  <FontAwesomeIcon
                    icon={(!toggle && faChevronDown) || (toggle && faChevronUp)}
                    style={{ color: "#deddda" }}
                  />
                </Button>
              )}
              <AddIcon onClick={() => handleClickAdd(areaId)} />
            </ListPT>
          )}
          {toggle &&
            tree.represented_trees.map((tree) => (
              <ListCT
                key={"child" + tree.id}
                className={
                  // "accord"
                  selectedTree === tree.id
                    ? "d-flex align-items-center "
                    : "d-flex align-items-center clickable"
                }
                style={{ background: selectedTree === tree.id ? "#deebff" : "" }}
                onClick={() =>
                  handleTreeSelect(
                    tree,
                    areaId,
                    pid,
                    navigate,
                    selectedTree,
                    setSelectedTree,
                    setOpenTreePopup,
                    setSelectedParentTree,
                    map
                  )
                }
                status={tree.health_state}
              >
                <Avatar
                  name={tree.name}
                  textMarginRatio={0.25}
                  color={
                    tree.health_state === 0
                      ? GREEN
                      : tree.health_state === 1
                      ? YELLOW
                      : tree.health_state === 2
                      ? RED
                      : tree.health_state === 4
                      ? BLUE
                      : GREY
                  }
                  size="32"
                  round="100px"
                />
                <div className="ms-2">
                  <h6 className="mb-0">{tree.name} </h6>
                  <span className={selectedTree === tree.id ? " small" : "text-muted small"}>
                    {tree.species ? tree.species.name : ""}
                  </span>
                </div>
              </ListCT>
            ))}
        </div>
      ))}
    </>
  );
};

export const ListPT = styled.li`
  list-style: none;
  padding: 15px 20px 15px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #eee;
  ${(props) => {
    if (props.status === 0) {
      return `
     	 border-left:3px solid ${GREEN}; 
        `;
    }
    if (props.status === 1) {
      return `
     border-left:3px solid ${YELLOW};
        `;
    }
    if (props.status === 2) {
      return `
      border-left:3px solid ${RED};
      `;
    }
    if (props.status === 3) {
      return `
      border-left:3px solid ${GREY};
      `;
    }
    if (props.status === 4) {
      return `
      border-left:3px solid ${BLUE};
      `;
    }
  }}
`;
export const ListCT = styled.li`
  list-style: none;
  padding: 15px 20px 15px 15px;
  margin-bottom: 10px;
  border-radius: 50px;
  border: 1px solid #ee1;
  ${(props) => {
    if (props.status === 0) {
      return `
     	 border-left:3px solid ${GREEN}; 
        `;
    }
    if (props.status === 1) {
      return `
     border-left:3px solid ${YELLOW};
        `;
    }
    if (props.status === 2) {
      return `
      border-left:3px solid ${RED};
      `;
    }
    if (props.status === 3) {
      return `
      border-left:3px solid ${GREY};
      `;
    }
    if (props.status === 4) {
      return `
      border-left:3px solid ${BLUE};
      `;
    }
  }}
`;
export const ListNST = styled.li`
  list-style: none;
  padding: 15px 20px 15px 15px;
  margin-bottom: 10px;
  border-radius: 50px;
  border: 1px solid #eee;
  ${(props) => {
    if (props.status === 0) {
      return `
     	 border-left:3px solid ${GREEN}; 
        `;
    }
    if (props.status === 1) {
      return `
     border-left:3px solid ${YELLOW};
        `;
    }
    if (props.status === 2) {
      return `
      border-left:3px solid ${RED};
      `;
    }
    if (props.status === 3) {
      return `
      border-left:3px solid ${GREY};
      `;
    }
    if (props.status === 4) {
      return `
      border-left:3px solid ${BLUE};
      `;
    }
  }}
`;
export const TreeIcon = styled(Tree)`
  width: 14px;
`;
export const DropdownWrapper = styled.div`
    position: absolute;
    z-index: 999;
    right: 30px;
    top: 16px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
`;
