const positionValidation = (value, t) => {
  let error;
  if (!value) {
    error = t("validation.position_is_required");
  }
  return error;
};

export const validation = (t) => ({
  positionValidation: (value) => positionValidation(value, t),
});
