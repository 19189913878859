import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";

export default function AddProject({ label, action }) {
  const { t } = useTranslation();
  return (
    <>
      <Card data-testid="newproject-button" className="h-100" role="button" onClick={action}>
        <Card.Body
          className="d-flex flex-column gap-3 justify-content-center"
          style={{ minHeight: "250px" }}
        >
          <div className="text-center">
            <img src={process.env.PUBLIC_URL + "/img/add-button.png"} alt="" width="100%" />
          </div>
          <div className="text-center">
            <span className="text-uppercase">{t(label)}</span>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
