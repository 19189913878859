export const formInitialValues = (data) => {
  return {
    hardware_serial: data ? data : "",
    name: "",
    sensor_type: "pulse_sensor",
    network_type: "LW",
    amount_nodes: 1,
  };
};

export const formUpdateValues = (formValues) => {
  return {
    id: formValues && formValues.id ? formValues.id : "",
    hardware_serial: formValues && formValues.hardware_serial ? formValues.hardware_serial : "",
    imei: formValues && formValues.imei ? formValues.imei : "",
    name: formValues && formValues.name ? formValues.name : "",
    threshold: formValues && formValues.threshold ? formValues.threshold : "",
    sensor_type:
      formValues && formValues.soil_moisture_sensor
        ? "soil_moisture_sensor"
        : formValues && formValues.dendrometer_sensor
        ? "dendrometer_sensor"
        : formValues && formValues.soil_water_tension_sensor
        ? "soil_water_tension_sensor"
        : "pulse_sensor",
    network_type: formValues && formValues.network_type ? formValues.network_type : "LW",
    amount_nodes: formValues && formValues.amount_nodes ? formValues.network_type : 1,
  };
};
