import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../FormModel/userFormModel";

export default function BasicInfo({ formField }) {
  const { firstName, lastName } = formField;
  const { t } = useTranslation();

  const NAME_LIMIT = 50;

  return (
    <>
      <div className="d-flex align-item-center w-100 gap-2">
        <Form.Group className="w-50">
          <Form.Label> {t(firstName.label)}</Form.Label>
          <Field
            type="text"
            name={firstName.name}
            className="form-control "
            maxLength={NAME_LIMIT}
            placeholder={t("maximum_of") + ` ${NAME_LIMIT} ` + t("characters")}
          />
        </Form.Group>
        <Form.Group className="w-50">
          <Form.Label>{t(lastName.label)}</Form.Label>
          <Field
            type="text"
            name={lastName.name}
            className="form-control"
            maxLength={NAME_LIMIT}
            placeholder={t("maximum_of") + ` ${NAME_LIMIT} ` + t("characters")}
          />
        </Form.Group>
      </div>
    </>
  );
}
