import styled from "styled-components";
import { Bell } from "@styled-icons/fa-solid/Bell";
import { Check } from "@styled-icons/fa-solid/Check";
import { Book } from "@styled-icons/fa-solid/Book";
import { Trees } from "@styled-icons/foundation/Trees";
import { Trash } from "@styled-icons/bootstrap/Trash";
import { SortUp } from "@styled-icons/bootstrap/SortUp";
import { Area } from "@styled-icons/boxicons-solid/Area";
import { PlusLg } from "@styled-icons/bootstrap/PlusLg";
import { Edit } from "@styled-icons/boxicons-solid/Edit";
import { AngleUp } from "@styled-icons/fa-solid/AngleUp";
import { QuestionMark } from "@styled-icons/boxicons-regular/QuestionMark";
// import { Battery } from "@styled-icons/bootstrap/Battery";
import { Project } from "@styled-icons/open-iconic/Project";
import { WifiOff } from "@styled-icons/bootstrap/WifiOff";
import { Menu } from "@styled-icons/boxicons-regular/Menu";
import { Battery } from "@styled-icons/bootstrap/Battery";
import { FileExcel } from "@styled-icons/icomoon/FileExcel";
import { PageCsv } from "@styled-icons/foundation/PageCsv";
import { AreaGraph } from "@styled-icons/entypo/AreaGraph";
import { SortDown } from "@styled-icons/bootstrap/SortDown";
import { Reset } from "@styled-icons/boxicons-regular/Reset";
import { CalendarWeek } from "@styled-icons/bootstrap/CalendarWeek";
import { Pulse } from "@styled-icons/boxicons-regular/Pulse";
import { TriangleUp } from "@styled-icons/entypo/TriangleUp";
import { DragDrop } from "@styled-icons/remix-fill/DragDrop";
import { AngleDown } from "@styled-icons/fa-solid/AngleDown";
import { AngleRight } from "@styled-icons/fa-solid/AngleRight";
import { AngleLeft } from "@styled-icons/fa-solid/AngleLeft";
import { QrScan } from "@styled-icons/boxicons-regular/QrScan";
import { ImageAlt } from "@styled-icons/boxicons-solid/ImageAlt";
import { LogOut } from "@styled-icons/ionicons-outline/LogOut";
import { Settings } from "@styled-icons/ionicons-solid/Settings";
import { Dashboard3 } from "@styled-icons/remix-line/Dashboard3";
import { BatteryFull } from "@styled-icons/bootstrap/BatteryFull";
import { BatteryHalf } from "@styled-icons/bootstrap/BatteryHalf";
import { Download } from "@styled-icons/evaicons-solid/Download";
import { Location } from "@styled-icons/ionicons-outline/Location";
import { Crosshair } from "@styled-icons/boxicons-regular/Crosshair";
import { Clock } from "@styled-icons/fluentui-system-filled/Clock";
import { SearchAlt } from "@styled-icons/boxicons-regular/SearchAlt";
import { FileExport } from "@styled-icons/boxicons-solid/FileExport";
import { EdgesensorHigh } from "@styled-icons/material/EdgesensorHigh";
import { ArrowLeftShort } from "@styled-icons/bootstrap/ArrowLeftShort";
import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";
import { TemperatureHigh } from "@styled-icons/fa-solid/TemperatureHigh";
import { LockAlt } from "@styled-icons/boxicons-regular/LockAlt";
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import { GridAlt } from "@styled-icons/boxicons-solid/GridAlt";
import { AlertCircleOutline } from "@styled-icons/evaicons-outline/AlertCircleOutline";
import { LineHorizontal1 } from "@styled-icons/fluentui-system-filled/LineHorizontal1";
import { FullScreenMaximize } from "@styled-icons/fluentui-system-filled/FullScreenMaximize";
// import { AngleDown } from "@styled-icons/fa-solid/AngleDown";
import { Map } from "@styled-icons/fluentui-system-regular/Map";
import { PlugDisconnected } from "@styled-icons/fluentui-system-filled/PlugDisconnected";
import { BatteryLow } from "@styled-icons/boxicons-solid/BatteryLow";
import { ArrowIosBack } from "@styled-icons/evaicons-solid/ArrowIosBack";
import { CaretRightFill } from "@styled-icons/bootstrap/CaretRightFill";
import { LocationDot } from "@styled-icons/fa-solid/LocationDot";
import { MoreHorizontalOutline } from "@styled-icons/evaicons-outline/MoreHorizontalOutline";
import { ArrowUpShort } from "@styled-icons/bootstrap/ArrowUpShort";
import { ArrowDownShort } from "@styled-icons/bootstrap/ArrowDownShort";
import { SensorsOff } from "@styled-icons/material-rounded/SensorsOff";
import { BatteryAlert } from "@styled-icons/material/BatteryAlert";
import { Health } from "@styled-icons/boxicons-regular/Health";
import { Moisture } from "@styled-icons/bootstrap/Moisture";
import { User } from "@styled-icons/boxicons-regular/User";
import { ThreeDotsVertical } from "@styled-icons/bootstrap/ThreeDotsVertical";
import { UpRightFromSquare } from "@styled-icons/fa-solid/UpRightFromSquare";
import { UserCircle } from "@styled-icons/boxicons-solid/UserCircle";
import { DarkTheme } from "@styled-icons/fluentui-system-regular/DarkTheme";
import { EyeFill } from "@styled-icons/bootstrap/EyeFill";
import { EyeSlashFill } from "@styled-icons/bootstrap/EyeSlashFill";
import { CloudRainHeavyFill } from "@styled-icons/bootstrap/CloudRainHeavyFill";
import { PlusMedical } from "@styled-icons/boxicons-regular/PlusMedical";
import { Error } from "@styled-icons/boxicons-solid/Error";
import { LineChart } from "@styled-icons/boxicons-regular/LineChart";
import { WbSunny } from "@styled-icons/material-sharp/WbSunny";
import { Cloud } from "@styled-icons/bootstrap/Cloud";
import { CloudRain } from "@styled-icons/bootstrap/CloudRain";
import { CaretRightFill as CaretRightFill1 } from "@styled-icons/bootstrap/CaretRightFill";
import { ImageAdd } from "@styled-icons/boxicons-solid/ImageAdd";
import { NotificationsCircle } from "@styled-icons/ionicons-solid/NotificationsCircle";
import { ClipboardData } from "@styled-icons/bootstrap/ClipboardData";
// import { LogOut } from "@styled-icons/ionicons-outline/LogOut";
// import { ArrowLeft } from "@styled-icons/bootstrap/ArrowLeft";
// import { Download as Download } from "@styled-icons/evaicons-solid/Download";downloa
// import { LineHorizontal1 } from "@styled-icons/fluentui-system-filled/LineHorizontal1";
// export const DownloadIcon = styled(Download)`
// 	font-size: ${(props) => props.size};
// 	color: ${(props) => props.color};
// `;
// const LogoutIcon = styled(LogOut)`
// 	width: 20px;
// 	margin-left: 0px !important;
// 	color: #fff;
// 	margin-right: 5px;
// `;

export const CaretRightFillIcon1 = styled(CaretRightFill1)`
  width: 1.2em;
  margin-left: 5px;
`;
export const ImageAddIcon = styled(ImageAdd)`
  width: 5.2em;
  margin-left: 5px;
`;

export const ImageAddIconSmall = styled(ImageAdd)`
  width: 1.8em;
  margin-left: 5px;
`;
//weather icons

export const CloudIcon = styled(Cloud)`
  color: #6c6b6b;
  width: ${(props) => props.width};
`;
export const CloudRainIcon = styled(CloudRain)`
  color: #6c6b6b;
  width: ${(props) => props.width};
`;
export const SunIcon = styled(WbSunny)`
  color: #fbc450;
  width: ${(props) => props.width};
`;

export const MoreIcon = styled(ThreeDotsVertical)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  padding: 4px;
  transition: 0.2s;
  border-radius: 2px;
  transform: rotate(90deg);
  cursor: pointer;
  &:hover {
    background: #ccc;
    padding: 4px;
    border-radius: 50%;
  }
`;
export const ExternalLinkAltIcon = styled(UpRightFromSquare)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  padding: 4px;
  transition: 0.2s;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background: #ccc;
    padding: 4px;
    border-radius: 50%;
  }
`;
export const EyeFillIcon = styled(EyeFill)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
  transition: 0.3s;
`;
export const ErrorIcon = styled(Error)`
  width: 12px;
  color: rgb(205, 61, 100);
  margin-right: 2px;
`;
// export const ArrowLeftIcon = styled(ArrowLeft)`
// 	width: 12px;
// 	color: rgb(205, 61, 100);
// 	margin-right: 2px;
// `;
export const EyeSlashFillIcon = styled(EyeSlashFill)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
  transition: 0.3s;
`;
export const BatteryFullIcon = styled(BatteryFull)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const RainIcon = styled(CloudRainHeavyFill)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const PlusMedicalIcon = styled(PlusMedical)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const HealthIcon = styled(Health)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  background: #457ba3;
  padding: 5px;
  border-radius: 2px;
`;
export const SensorsOffIcon = styled(SensorsOff)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;

export const MapMarkerAltIcon = styled(LocationDot)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  padding: 5px;
  transition: 0.2s;
  color: rgb(66, 82, 110);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background: #eee;
    padding: 4px;
  }
`;
export const LineChartIcon = styled(LineChart)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  padding: 5px;
  transition: 0.2s;
  color: rgb(66, 82, 110);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background: #eee;
    padding: 4px;
  }
`;
export const ArrowUpShortIcon = styled(ArrowUpShort)`
  width: 20px;
  color: #a3cf6c;
`;
export const ArrowDownShortIcon = styled(ArrowDownShort)`
  width: 20px;
  color: #ef5c5c;
`;
export const MoreHorizontalOutlineIcon = styled(MoreHorizontalOutline)`
  width: 16px;
  color: #fff;
  background: ${(props) => (props.showHideRightData ? "#3880908f" : "#388090")};
  border-radius: 50%;
  padding: 2px;
  font-weight: 600;
  margin-right: 4px;
`;

export const AngleLeftIcon = styled(AngleLeft)`
  width: 10px;
  margin-left: 3px;
`;
export const AngleRightIcon = styled(AngleRight)`
  width: 10px;
  margin-left: 3px;
`;
export const AngleDownIcon = styled(AngleDown)`
  width: 10px;
  margin-left: 3px;
`;
export const ArrowIosBackIcon = styled(ArrowIosBack)`
  width: 16px;
  margin-right: 3px;
`;
export const CaretRightFillIcon = styled(CaretRightFill)`
  width: 12px;
  margin-right: 5px;
`;
export const EmailOutlineIcon = styled(EmailOutline)`
  width: 16px;
  margin-left: 3px;
`;
export const LockOutlineIcon = styled(LockAlt)`
  width: 16px;
  margin-left: 3px;
`;
export const UserIcon = styled(User)`
  width: 16px;
  margin-left: 3px;
`;
export const AreaGraphIcon = styled(AreaGraph)`
  width: 28px;
  margin-left: 3px;
  color: linear-gradient(
    90deg,
    #1f005c,
    #5b0060,
    #870160,
    #ac255e,
    #ca485c,
    #e16b5c,
    #f39060,
    #ffb56b
  );
`;
export const ClockIcon = styled(Clock)`
  width: 15px;
  position: absolute;
  top: 6px;
  left: 10px;
  color: #555;
`;
export const CloseOutlineIcon = styled(CloseOutline)`
  width: 14px;
  // margin-left: 10px;
  cursor: pointer;
`;
export const CloseIcon = styled(CloseOutline)`
  width: 24px;
  // margin-left: 380px;
  margin-right: 0px;
  cursor: pointer;
`;

export const AlertCircleOutlineIcon = styled(AlertCircleOutline)`
  width: 16px;
  margin-left: 3px;
`;
export const DragDropIcon = styled(DragDrop)`
	width: ${(props) => props.size}
	margin-left: 3px;
`;
export const LineHorizontal1Icon = styled(LineHorizontal1)`
  width: 16px;
  color: #fff;
  background: ${(props) => (props.showHideLeftData ? "#3880908f" : "#388090")};
  border-radius: 50%;
  padding: 2px;
  font-weight: 600;
  margin-right: 4px;
`;
export const AngleUpIcon = styled(AngleUp)`
  width: 10px;
  margin-left: 3px;
`;
export const BatteryHalfIcon = styled(BatteryHalf)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const BatteryIcon = styled(Battery)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const QuestionMarkIcon = styled(QuestionMark)`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const FileExcelIcon = styled(FileExcel)`
  width: 14px;
  margin-right: 5px;
`;
export const FileCsvIcon = styled(PageCsv)`
  width: 15px;
  margin-right: 5px;
`;
export const FileExportIcon = styled(FileExport)`
  width: 14px;
  margin-left: 5px;
`;
export const AddIcon = styled(PlusLg)`
  width: 0.75rem;
  margin-right: ${(props) => props.marginRight};
`;
export const AddTreeButton = styled.button`
  border: 1px solid #388090;
  color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  margin-bottom: 5px;
  color: #388090;
  background: #fff;
  padding: 0px 6px 0px 2px;
  &:hover {
    background: #388090;
    color: #fff;
    transition: 0.2s;
  }
`;
export const SearchIcon = styled(SearchAlt)`
  position: absolute;
  width: ${(props) => props.width};
  height: 100%;
  margin-left: ${(props) => props.ml};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  color: #888;
  padding: 6px;
`;
export const DownloadIcon = styled(Download)`
  width: 16px;
  cursor: pointer;
  // color: #666;
  &:hover {
    color: #fff;
  }
`;
export const FullScreenButton = styled(FullScreenMaximize)`
  width: 16px;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #000;
  }
`;
export const ArrowLeftIcon = styled(ArrowLeftShort)`
  width: 24px;
  margin-right: 5px;
  cursor: pointer;
  color: #338090;
`;
export const ArrowRightIcon = styled(ArrowRightShort)`
  width: 24px;
  margin-right: 5px;
  cursor: pointer;
  color: #338090;
`;
export const ArrowLeftIconWhite = styled(ArrowLeftShort)`
  width: 24px;
  position: absolute;
  z-index: 99;
  color: #fff;
  top: 10px;
  left: 10px;
`;

export const QrScanIcon = styled(QrScan)`
  width: 20px;
  cursor: pointer;
`;
export const ImageAltIcon = styled(ImageAlt)`
  width: 20px;
`;
export const DashboardIcon = styled(Dashboard3)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const TreesIcon = styled(Trees)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const SensorsIcon = styled(EdgesensorHigh)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const BatteryLowIcon = styled(BatteryLow)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const PlugDisconnectedIcon = styled(PlugDisconnected)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
`;

export const ManualIcon = styled(Book)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
`;
export const SettingIcon = styled(Settings)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
`;

export const BellIcon = styled(Bell)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  // width: 18px;
  // margin-right: 5px;
  margin-left: ${(props) => props.left};
  // color: #fff;
`;
export const NotificationsCircleIcon = styled(NotificationsCircle)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  margin-left: ${(props) => props.left};
`;
export const UserCircleIcon = styled(UserCircle)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  // width: 18px;
  margin-right: 5px;
  // color: #fff;
`;
export const DarkThemeIcon = styled(DarkTheme)`
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  // width: 18px;
  margin-right: 5px;
  // color: #fff;
`;

export const NoWifi = styled(WifiOff)`
  color: ${(props) => props.width};
  margin-right: ${(props) => props.right};
  color: ${(props) => props.color};
`;
export const BatteryWarningIcon = styled(BatteryAlert)`
  color: ${(props) => props.width};
  margin-right: ${(props) => props.right};
  color: ${(props) => props.color};
`;

export const LogoutIcon = styled(LogOut)`
  width: ${(props) => props.size};
  margin-right: 3px;
`;

export const MenuAlt1Icon = styled(Menu)`
  width: 24px;
  margin-left: -10px;
`;

export const ProjectIcon = styled(Project)`
  width: 14px;
  margin-right: 3px;
`;
export const AreaIcon = styled(Area)`
  width: 14px;
  margin-right: 3px;
`;

/*=====table-icons=========*/
export const EditIcon = styled(Edit)`
  width: 1.3rem;
  color: #717171;
  cursor: pointer;
  padding: 0.2em;
  margin-right: 0.4em;
  &:hover {
    background: #6461613b;
    border-radius: 0.1875rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;
export const TrashIcon = styled(Trash)`
  width: 1.3rem;
  color: #717171;
  cursor: pointer;
  padding: 0.2em;
  &:hover {
    background: #6461613b;
    border-radius: 0.1875rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const LocationIcon = styled(Location)`
  width: 1.3rem;
  cursor: pointer;
`;
export const CrosshairIcon = styled(Crosshair)`
  width: 1.3rem;
  cursor: pointer;
`;
export const ClipboardDataIcon = styled(ClipboardData)`
  width: 1.3rem;
  cursor: pointer;
`;
export const MapIcon = styled(Map)`
  width: 1.3rem;
  cursor: pointer;
`;
export const SortDownIcon = styled(SortDown)`
  width: 18px;
`;
export const SortUpIcon = styled(SortUp)`
  width: 18px;
`;
export const GridIcon = styled(GridAlt)`
  width: 1.5em;
`;
export const Check2Icon = styled(Check)`
  width: 14px;
`;
export const ReloadIcon = styled(Reset)`
  width: 18px;
`;
export const CalendarWeekIcon = styled(CalendarWeek)`
  width: 18px;
  position: absolute;
  top: 0px;
  right: 9px;
`;
export const PulseIcon = styled(Pulse)`
  width: 18px;
`;
export const MoistureIcon = styled(Moisture)`
  width: 16px;
  margin-right: 3px;
`;
export const TemperatureIcon = styled(TemperatureHigh)`
  width: 14px;
`;

export const TriangleUpIcon = styled(TriangleUp)`
  width: 16px;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(90deg)")};
`;

export const AgerageIcon = () => {
  return `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="30px"
      version="1.1"
      viewBox="0 0 700 700"
    >
      <g>
        <path d="m455 245h87.5c9.6641 0 17.5 9.6641 17.5 17.5v210c0 9.6641-7.8359 17.5-17.5 17.5h-87.5c-9.6641 0-17.5-9.6641-17.5-17.5v-210c0-9.6641 7.8359-17.5 17.5-17.5z" />
        <path d="m315 245h87.5c9.6641 0 17.5 9.6641 17.5 17.5v210c0 9.6641-7.8359 17.5-17.5 17.5h-87.5c-9.6641 0-17.5-9.6641-17.5-17.5v-210c0-9.6641 7.8359-17.5 17.5-17.5z" />
        <path d="m175 245h87.5c9.6641 0 17.5 9.6641 17.5 17.5v210c0 9.6641-7.8359 17.5-17.5 17.5h-87.5c-9.6641 0-17.5-9.6641-17.5-17.5v-210c0-9.6641 7.8359-17.5 17.5-17.5z" />
        <path d="m227.5 157.5h245l-27.301 20.824c-3.9805 2.8789-6.5664 7.2969-7.1289 12.176s0.94922 9.7695 4.168 13.48c3.2227 3.707 7.8516 5.8906 12.762 6.0195 3.7852 0 7.4727-1.2266 10.5-3.5l70-52.5c4.4062-3.3047 7-8.4922 7-14-0.042969-5.5391-2.7031-10.73-7.1758-14l-70-52.5c-5.0312-3.7188-11.668-4.4727-17.406-1.9727-5.7383 2.4961-9.707 7.8672-10.41 14.086-0.70703 6.2188 1.9609 12.34 6.9922 16.062l28 20.824h-245c-6.2539 0-12.031 3.3359-15.156 8.75s-3.125 12.086 0 17.5 8.9023 8.75 15.156 8.75z" />
      </g>
    </svg>`;
};
