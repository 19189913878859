import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_DE } from "./de/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_IT } from "./it/translations";

i18n.use(initReactI18next).init({
  debug: true,
  initImmediate: false,
  lng: "en",
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    de: {
      translation: TRANSLATIONS_DE,
    },
    it: {
      translation: TRANSLATIONS_IT,
    },
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
  },
});
export default i18n;
