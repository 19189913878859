export const styles = {
  validationError: "text-danger mb-1 d-flex align-items-center gap-1",
  formBody: "d-flex align-items-center justify-content-between pt-3",
  wizardList: "d-flex align-items-center gap-3 mb-3 wizard-list border-bottom",
  setting: { section: "" },
  // setting: { section: "d-flex justify-content-center mx-auto mb-4" },
  login: {
    left: "col-sm-4 bg-primary  position-relative d-flex justify-content-center align-items-center  p-5 ",
    formlabel: "d-flex align-items-center mb-1 gap-1",
  },
  dsFilterTitle: "d-flex  align-items-center py-1 clickable",
  notificationItem: "d-flex  flex-fill justify-content-between align-items-center py-1",
};
