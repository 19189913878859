import React from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DataScienceContext } from "../context/DataScienceContext";
import { CalendarWeekIcon } from "@components/icons";

function DateFilter({ setDateType }) {
  const { setStartDate, setEndDate, setInitialStartDate, setInitialEndDate, setActiveDate } =
    React.useContext(DataScienceContext);
  //get datewise sensor graph data

  const handleEvent = (_, picker) => {
    const startDate = moment(picker.startDate).format("YYYY-MM-DD");
    const endDate = moment(picker.endDate).format("YYYY-MM-DD");
    setStartDate(startDate);
    setEndDate(endDate);
    setInitialStartDate(startDate);
    setInitialEndDate(endDate);
    setActiveDate(startDate + endDate);
    setDateType(5);
  };

  return (
    <div className="position-relative">
      <DateRangePicker
        initialSettings={{
          timePicker: true,
          opens: "left",
          linkedCalendars: false,
          applyButtonClasses: "btn-primary",
          drops: "auto",
          startDate: moment().subtract(3, "weeks").format("DD MM YYYY "),
          endDate: moment().format("DD MM YYYY "),
          locale: {
            format: "DD.MM.YYYY  ",
          },
        }}
        onApply={handleEvent}
      >
        <button className="bg-transparent border-0">
          <CalendarWeekIcon />
        </button>
      </DateRangePicker>
    </div>
  );
}

export default DateFilter;
