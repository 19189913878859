import axios from "axios";
import { store } from "../redux/store";

export const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstances = axios.create({
  baseURL: baseURL,
});

const onRequest = (config) => {
  const states = store.getState();

  const accessToken = states.auth.authToken;
  config.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "JWT " + accessToken,
  };
  return config;
};

export const onRequestError = (error) => {
  const customNavigate = useCustomNavigate();
  if (error && error.request && error.request.status === 401) {
    const requestUrl = error.request.responseURL;
    const refreshExp = localStorage.getItem("refreshExp");
    if (refreshExp) {
      if (new Date(refreshExp * 1000).getTime() < new Date().getTime()) {
        customNavigate("/login");
      }
    }
  }

  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

function useCustomNavigate() {
  const customNavigate = (path) => {
    localStorage.setItem("previousPath", window.location.pathname);
    localStorage.setItem("previousSearch", window.location.search);
    window.location.href = path;
  };
  return customNavigate;
}

function onResponseError(error) {
  const customNavigate = useCustomNavigate();
  // const navigate = useNavigate();
  // const { isLoading: loadingRefresh, mutateAsync: refreshMutate } = useMutation(refresh, {
  //   onSuccess: async (data) => {
  //     const accessToken = data.access;
  //     // const decoded = jwt_decode(accessToken);
  //     // await dispatch(actions.login(accessToken));
  //     // console.log("TOKEN", accessToken);
  //     const decoded = jwt_decode(accessToken);
  //     await dispatch(actions.login(accessToken));
  //     await dispatch(actions.refreshToken(auth.refreshToken));
  //     await dispatch(actions.userLoaded(decoded));
  //     // console.log("local", localStorage);
  //     // localStorage.setItem(
  //     //   "persist:root",
  //     //   JSON.stringify({
  //     //     auth: { authToken: accessToken, refreshToken: auth.refreshToken, user: auth.user },
  //     //   })
  //     // );
  //     // console.log("local", localStorage);
  //     // await dispatch(actions.userLoaded(decode));
  //   },
  // });
  // handling the response of 401 unauthorized or signature expired
  // const { auth } = useSelector((state) => state);
  // console.log(auth);
  // TODO: if request URL was not /token/refresh, get new token and refetch URL
  if (error && error.response && error.response.status === 401) {
    const backendUrl = error.request.responseURL;
    const pathname =
      backendUrl.split("//")[1].split("/")[1] + "/" + backendUrl.split("//")[1].split("/")[2];
    if (pathname == "token/refresh") {
      customNavigate("/login");
    } else {
      // TODO: check whether refresh token is expired by only passing the expiry date from the refresh token to the frontend
      // console.log(localStorage.getItem("loadingRefresh"));
      const refreshExp = localStorage.getItem("refreshExp");
      if (refreshExp) {
        if (new Date(refreshExp * 1000).getTime() < new Date().getTime()) {
          customNavigate("/login");
        }
      }
      console.log("ERROR for ", pathname);
    }
  }
  return Promise.reject(error);
}

function setupInterceptorsTo(axiosInstances) {
  axiosInstances.interceptors.request.use(onRequest, onRequestError);
  axiosInstances.interceptors.response.use(onResponse, onResponseError);
  return axiosInstances;
}
const axiosInstance = setupInterceptorsTo(axiosInstances);
export default axiosInstance;
