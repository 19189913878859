import React from "react";
// import { ArrowLeftIcon } from "../icons";
import { Col, Container, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

function Breadcrumb(props) {
  // let navigate = useNavigate();
  // const goToPreviousPath = () => {
  // 	history.goBack();
  // };
  // const { pathname } = useNavigate();
  return (
    <div className="breadcrumb my-4">
      <Container>
        <Row>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-between justify-content-sm-start mb-2 mb-md-0 "
          >
            <div className="d-flex align-items-center">
              <div>
                <h4
                  className=" page-title me-4 mb-0 font-lg d-flex align-items-center"
                  style={{ fontWeight: "600" }}
                >
                  {/* {pathname !== "/dashboard" && (
									<ArrowLeftIcon onClick={goToPreviousPath} />
								)} */}
                  {props.icon}
                  {props.title}
                </h4>
              </div>
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-between mt-auto  justify-content-md-end gap-2 flex-wrap"
          >
            {/* <div className="row "> */}
            {props.weather && props.weather}
            {props.addButton && <div className="col-auto p-lg-0">{props.addButton}</div>}
            {props.addIrrigationButton && (
              <div className="col-auto p-lg-0">{props.addIrrigationButton}</div>
            )}
            {props.addTreeButton && <div className="col-auto p-lg-0">{props.addTreeButton}</div>}
            {props.viewType && <div className="col-auto ms-lg-4"> {props.viewType} </div>}
            {props.importButton && <div className="col-auto p-lg-0">{props.importButton}</div>}

            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(Breadcrumb);
