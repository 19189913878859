import { useQuery } from "react-query";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTrees } from "../services/treeCrud";
import NoSelected from "@components/NoSelected";
import Spinner from "@components/Spinner/Spinner";
import AddButton from "@components/Button/AddButton";
import TreeListMobileView from "./TreeListMobileView";
import { Col, Container, Row } from "react-bootstrap";
import ServerError from "@components/Error/ServerError";
import { MobileBreadcrumb } from "@components/Breadcrumb";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import { ImportTreeButton } from "./Button/ImportTreeButton";
import TreeList from "./TreeList";
import EmptyTree from "./EmptyTree";
import TreeForm from "./Form/AddForm";
import TreeImageForm from "./Form/AddForm/TreeImageForm";
import TreeDetail from "./TreeDetail";

export default function TreePage() {
  const [formValue, setFormValue] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [openImageForm, setOpenImageForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { mobile } = useSelector((state) => state.mobile);
  const query = new URLSearchParams(useLocation().search);
  const tid = query.get("selectedTree");
  const { t } = useTranslation();

  // fetch tree
  const {
    data,
    isLoading,
    isError,
    refetch: refetchTrees,
  } = useQuery("trees", getTrees, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });
  const [trees, setTrees] = useState(data);
  useEffect(() => {
    if (!openForm) {
      refetchTrees().then((response) => setTrees(response.data));
    }
  }, [openForm]);
  const [treeList, setTreeList] = useState(
    trees ? (
      <TreeList
        trees={trees}
        setIsUpdate={setIsUpdate}
        setOpenForm={setOpenForm}
        setOpenImageForm={setOpenImageForm}
        setFormValue={setFormValue}
      />
    ) : (
      []
    )
  );
  useEffect(() => {
    setTreeList(
      trees ? (
        <TreeList
          trees={trees}
          setIsUpdate={setIsUpdate}
          setOpenForm={setOpenForm}
          setOpenImageForm={setOpenImageForm}
          setFormValue={setFormValue}
        />
      ) : (
        []
      )
    );
  }, [trees]);

  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError) return <ServerError />;

  return (
    <>
      {mobile ? (
        <MobileBreadcrumb
          title={t("trees")}
          addButton={<AddButton label={"button.add_tree"} action={() => setOpenForm(true)} />}
        />
      ) : (
        <Breadcrumb
          title={t("trees")}
          addButton={<AddButton label={"button.add_tree"} action={() => setOpenForm(true)} />}
          importButton={<ImportTreeButton />}
        />
      )}
      <Container>
        {trees.length === 0 ? (
          <>{<EmptyTree width={100} title={"project_details.empty_trees"} />}</>
        ) : (
          <>
            {mobile ? (
              <TreeListMobileView trees={trees} />
            ) : (
              <Row className="g-3">
                <Col lg={7}>{treeList}</Col>
                <Col lg={5}>
                  {!tid ? <NoSelected text={"project_details.no_tree_selected"} /> : <TreeDetail />}
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
      {openForm && (
        <TreeForm
          show={openForm}
          isUpdate={isUpdate}
          formValue={formValue}
          setOpenForm={setOpenForm}
          setIsUpdate={setIsUpdate}
        />
      )}
      {openImageForm && (
        <TreeImageForm show={openImageForm} formValue={formValue} setOpenForm={setOpenImageForm} />
      )}
    </>
  );
}
