import axios from "axios";
import axiosInstance, { baseURL } from "@helper/interceptor";

export const login = async ({ email, password }) => {
  try {
    const { data } = await axios.post(
      `${baseURL}token/`,
      {
        email,
        password,
      },
      { withCredentials: true }
    );
    return data;
  } catch (error) {
    if (error.message === "Network Error") {
      throw Error("Internet connection failed");
    } else {
      throw error;
    }
  }
};
export const refresh = async ({ refresh }) => {
  // try {
  const { data } = await axiosInstance.post(
    `${baseURL}token/refresh/`,
    {
      refresh,
    },
    { withCredentials: true }
  );
  return data;
  // } catch (error) {
  //   if (error.message === "Network Error") {
  //     throw Error("Internet connection failed");
  //   } else {
  //     throw error;
  //   }
  // }
};
export const signup = async (formdata) => {
  const { data } = await axios.post(`${baseURL}signup/`, formdata);
  return data;
};
export const createCustomer = async (formdata) => {
  const { data } = await axios.post(`${baseURL}create-customer/`, formdata);
  return data;
};
export const logout = async () => {
  await axiosInstance.get(`${baseURL}logout/`);
  return;
};
export const forgotPassword = async (email) => {
  const { data } = await axios.post(`${baseURL}forgot-password/`, email);
  return data;
};
export const resetPassword = async ({ formData, type, id }) => {
  const { data } = await axios.patch(`${baseURL}password-reset/${type}/${id}`, formData);
  return data;
};
